<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Update Loan Type</p>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  }
  <div class="my-4">
    <form class="row g-3" [formGroup]="addRecordForm" (submit)="submit()">
      <div class="col-md-6">
        <label for="name" class="form-label"
          >Loan Name <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="name"
          id="name"
          placeholder="e.g. Emergency Loan"
        />
      </div>
      <div class="col-md-6">
        <label for="term" class="form-label"
          >Maximum repayment period <span class="text-danger">*</span></label
        >
        <div class="row">
          <div class="col-md-6">
            <input
              type="number"
              class="form-control"
              formControlName="term"
              min="1"
              id="term"
              placeholder="e.g. 5"
            />
          </div>
          <div class="col-md-6">
            <select
              class="form-select"
              aria-label="Select termDesc"
              formControlName="termDesc"
            >
              @for (termDescription of termDescriptions; track $index) {
              <option [value]="termDescription.id">
                {{ termDescription.name }}
              </option>
              }
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <label for="interestRate" class="form-label"
          >Interest Rate (1-100) <span class="text-danger">*</span></label
        >
        <div class="row">
          <div class="col-md-6">
            <input
              type="number"
              class="form-control"
              formControlName="interestRate"
              id="interestRate"
              placeholder="e.g. 12"
            />
          </div>
          <div class="col-md-6">
            <select
              class="form-select"
              aria-label="Select periodicity "
              formControlName="periodicityId"
            >
              @for (periodicity of periodicities; track $index) {
              <option [value]="periodicity.id">
                {{ periodicity.name }}
              </option>
              }
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <label for="maxAmountMultiplier" class="form-label"
          >Maximum Amount Multiplier <span class="text-danger">*</span></label
        >
        <input
          type="number"
          class="form-control"
          formControlName="maxAmountMultiplier"
          id="maxAmountMultiplier"
          placeholder="e.g. 3"
        />
      </div>

      <div class="col-12">
        <label for="description" class="form-label"
          >Description <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="description"
          id="description"
          placeholder="e.g. Short-term loans provided to members facing urgent or unexpected financial needs...."
        />
      </div>

      <div class="col-12 mt-4">
        <a
          [routerLink]="['/settings/loan-types/details', recordId]"
          type="button"
          class="btn btn-sm btn-outline-dark me-2"
          >Cancel</a
        >
        <button type="submit" class="btn btn-sm btn-primary me-2">
          Update Loan Type
        </button>
      </div>
    </form>
  </div>
</div>

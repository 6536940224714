import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppService } from '../services/app.service';
import { map } from 'rxjs';

export const loggedInGuard: CanActivateFn = (route, state) => {
  const appService = inject(AppService);
  const router = inject(Router);
  return appService.user$.pipe(
    map((user) => {
      if (user) {
        return true;
      } else {
        return router.createUrlTree(['/auth', 'login']);
      }
    })
  );
};

export const authGuard: CanActivateFn = (route, state) => {
  const appService = inject(AppService);
  const router = inject(Router);
  return appService.user$.pipe(
    map((user) => {
      if (user) {
        return router.createUrlTree(['/', 'dashboard']);
      } else {
        return true;
      }
    })
  );
};

<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Add Members</p>

    <button
      [routerLink]="['/members']"
      [routerLinkActive]="['active']"
      class="btn btn-sm btn-link"
    >
      <fa-icon [icon]="faList" class="me-1"></fa-icon> All Members
    </button>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  }
  <div class="my-4">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#home-tab-pane"
          type="button"
          role="tab"
          aria-controls="home-tab-pane"
          aria-selected="true"
        >
          <fa-icon [icon]="faUserPlus" class="me-1"></fa-icon> Add Member
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#profile-tab-pane"
          type="button"
          role="tab"
          aria-controls="profile-tab-pane"
          aria-selected="false"
        >
          <fa-icon [icon]="faFileExcel" class="me-1"></fa-icon> Upload Members
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="home-tab-pane"
        role="tabpanel"
        aria-labelledby="home-tab"
        tabindex="0"
      >
        <div class="m-3">
          <form class="row g-3" [formGroup]="addRecordForm" (submit)="submit()">
            <div class="col-md-4">
              <label for="memberNumber" class="form-label"
                >Member Number <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="memberNumber"
                id="memberNumber"
                placeholder="e.g. CSG004"
              />
              <span
                class="d-block text-danger fw-lighter fst-italic"
                *ngIf="
            addRecordFormControl['memberNumber'].touched &&
            addRecordFormControl['memberNumber'].errors?.['required']
          "
              >
                Member Number is required
              </span>
            </div>
            <div class="col-md-4">
              <label for="name" class="form-label"
                >Name <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="name"
                id="name"
                placeholder="e.g. Jane Doe"
              />
              <span
                class="d-block text-danger fw-lighter fst-italic"
                *ngIf="
            addRecordFormControl['name'].touched &&
            addRecordFormControl['name'].errors?.['required']
          "
              >
                Name is required
              </span>
            </div>

            <div class="col-md-4">
              <label for="email" class="form-label"
                >Email <span class="text-danger">*</span></label
              >
              <input
                type="email"
                class="form-control"
                formControlName="email"
                id="email"
                placeholder="e.g. jane@gmail.com"
              />
              <span
                class="d-block text-danger fw-lighter fst-italic"
                *ngIf="
            addRecordFormControl['email'].touched &&
            addRecordFormControl['email'].errors?.['required']
          "
              >
                Email is required
              </span>
            </div>

            <div class="col-md-4">
              <label for="phone" class="form-label"
                >Phone <span class="text-danger">*</span></label
              >
              <input
                type="tel"
                class="form-control"
                formControlName="phone"
                id="phone"
                placeholder="e.g. +254712345678"
              />
              <span
                class="d-block text-danger fw-lighter fst-italic"
                *ngIf="
            addRecordFormControl['phone'].touched &&
            addRecordFormControl['phone'].errors?.['required']
          "
              >
                Phone is required
              </span>
            </div>
            <div class="col-md-4">
              <label for="idNumber" class="form-label"
                >ID Number <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="idNumber"
                id="idNumber"
                placeholder="e.g. 12345678"
              />
              <span
                class="d-block text-danger fw-lighter fst-italic"
                *ngIf="
            addRecordFormControl['idNumber'].touched &&
            addRecordFormControl['idNumber'].errors?.['required']
          "
              >
                ID Number is required
              </span>
            </div>
            <div class="col-md-4">
              <label for="taxPin" class="form-label"
                >Tax PIN <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="taxPin"
                id="taxPin"
                placeholder="e.g. A001234567C"
              />
              <span
                class="d-block text-danger fw-lighter fst-italic"
                *ngIf="
            addRecordFormControl['idNumber'].touched &&
            addRecordFormControl['idNumber'].errors?.['required']
          "
              >
                Tax PIN is required
              </span>
            </div>
            <div class="col-md-4">
              <label for="gender" class="form-label"
                >Gender <span class="text-danger">*</span></label
              >
              <select
                class="form-select"
                aria-label="Select Gender"
                formControlName="gender"
              >
                @for (gender of genders; track $index) {
                <option [value]="gender.name">{{ gender.name }}</option>
                }
              </select>
            </div>
            <div class="col-md-4">
              <label for="dob" class="form-label"
                >Date of Birth<span class="text-danger">*</span></label
              >
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  formControlName="dob"
                  id="dob"
                  readonly
                  [max]="maxDateOfBirth"
                  [matDatepicker]="dobPicker"
                />
                <mat-datepicker #dobPicker></mat-datepicker>

                <button
                  class="btn btn-outline-dark"
                  type="button"
                  id="button-addon2"
                  (click)="dobPicker.open()"
                >
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </button>
              </div>
            </div>

            <div class="col-12 mt-4">
              <a
                routerLink="/members"
                type="button"
                class="btn btn-sm btn-outline-dark me-2"
                >Cancel</a
              >
              <button type="submit" class="btn btn-sm btn-primary me-2">
                Add Member
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="profile-tab-pane"
        role="tabpanel"
        aria-labelledby="profile-tab"
        tabindex="0"
      >
        <div class="m-3">
          <ol class="list-group list-group-flush">
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
            >
              <div class="ms-2 me-auto">
                <div class="fw-bold">Step 1</div>
                Download the template file here -
                <a [href]="templateUrl">Member upload template.xlsx</a>
              </div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
            >
              <div class="ms-2 me-auto">
                <div class="fw-bold">Step 2</div>
                Fill out the template file with member details.
              </div>
            </li>
            <li class="list-group-item">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Step 3</div>
                Upload the filled template file
              </div>
              <form
                class="row"
                [formGroup]="uploadMembersFormGroup"
                (submit)="upload()"
              >
                <div class="col-md-4 m-1">
                  <div class="input-group">
                    <input
                      type="file"
                      class="form-control"
                      id="file"
                      accept=".xls,.xlsx"
                      formControlName="file"
                      (change)="onFileSelected($event.target)"
                    />
                  </div>
                  @if(aFile) {
                  <div class="col-md-4 mt-3">
                    <div class="d-grid gap-2">
                      <button type="submit" class="btn btn-sm btn-primary">
                        Upload
                      </button>
                    </div>
                  </div>
                  }
                </div>
              </form>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Members</p>

    <div>
      <button
        [routerLink]="['./add']"
        [routerLinkActive]="['active']"
        class="btn btn-sm btn-link"
      >
        <fa-icon [icon]="faUserPlus" class="me-1"></fa-icon> Add Members
      </button>
      <button
        class="btn btn-sm btn-link"
        data-bs-toggle="modal"
        data-bs-target="#downloadReportModal"
      >
        <fa-icon [icon]="faCloudDownload" class="me-1"></fa-icon> Download
        Report
      </button>
    </div>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  }
  <div class="my-4">
    <div
      class="modal fade"
      id="downloadReportModal"
      tabindex="-1"
      aria-labelledby="downloadReportModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="downloadReportModalLabel">
              Download Report
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            @if(isLoading){
            <div class="my-2 d-block">
              <app-loader></app-loader>
            </div>
            }

            <form class="" [formGroup]="filterFormGroup" (submit)="download()">
              <div class="row">
                <div class="col-md-6">
                  <label for="startDate" class="form-label"
                    >Created From<span class="text-danger">*</span></label
                  >
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="startDate"
                      id="startDate"
                      readonly
                      [matDatepicker]="startDatePicker"
                    />
                    <mat-datepicker #startDatePicker></mat-datepicker>

                    <button
                      class="btn btn-outline-dark"
                      type="button"
                      id="button-addon2"
                      (click)="startDatePicker.open()"
                    >
                      <fa-icon [icon]="faCalendar"></fa-icon>
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="endDate" class="form-label"
                    >Created To <span class="text-danger">*</span></label
                  >
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="endDate"
                      readonly
                      id="endDate"
                      [matDatepicker]="endDatePicker"
                    />
                    <mat-datepicker #endDatePicker></mat-datepicker>
                    <button
                      class="btn btn-outline-dark"
                      type="button"
                      id="button-addon2"
                      (click)="endDatePicker.open()"
                    >
                      <fa-icon [icon]="faCalendar"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="mb-2">
                    <label for="type" class="form-label"
                      >Tag <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-select"
                      aria-label="Select Tag"
                      formControlName="tag"
                    >
                      @for (tag of allTags; track $index) {
                      <option [value]="tag.id">
                        {{ tag.tag }}
                      </option>
                      }
                    </select>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-6">
                  <div class="d-grid gap-2">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-dark me-2"
                      id="closeDownloadReportModal"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-sm btn-primary">
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <app-search-box (searchEvent)="search($event)"></app-search-box>
    @if (records.length > 0) {
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Member Number</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Gender</th>
            <th scope="col">ID Number</th>
            <th scope="col">Tax PIN</th>
            <th scope="col">Account</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr
            *ngFor="let record of records"
            (click)="view(record.id)"
            class="clickable-row"
          >
            <td>{{ record.memberNumber }}</td>
            <td>{{ record.name }}</td>
            <td>{{ record.email }}</td>
            <td>{{ record.phone }}</td>
            <td>{{ record.gender }}</td>
            <td>{{ record.idNumber }}</td>
            <td>{{ record.taxPin }}</td>
            <td>@if(record.enabled) { Enabled }@else { Disabled }</td>
            <td><fa-icon [icon]="faArrowRight" class="me-1"></fa-icon></td>
          </tr>
        </tbody>
      </table>
      <app-list-pagination
        [pageSearch]="pageSearch"
        [paginatatedRecord]="paginatatedRecord"
        (turnPage)="fetchRecords($event)"
      >
      </app-list-pagination>
    </div>
    } @else {
    <div class="alert alert-warning my-3" role="alert">No records found.</div>
    }
  </div>
</div>

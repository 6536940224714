import { CommonModule, PercentPipe } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListPaginationComponent } from '../../list-pagination/list-pagination.component';
import { LoaderComponent } from '../../loader/loader.component';
import { SearchBoxComponent } from '../../search-box/search-box.component';
import { faCalendar, faList } from '@fortawesome/free-solid-svg-icons';
import { AppService } from '../../services/app.service';
import { IPaginatedUser, IUser } from '../../models/user.interface';
import { environment } from '../../../environments/environment.development';
import { AppEnums } from '../../models/app.enums';
import { IPageSearch } from '../../models/page-search.interface';
import {
  IPaginatedLoanType,
  ILoanType,
} from '../../models/loan-type.interface';
import { ILoan } from '../../models/loan.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    SearchBoxComponent,
    ReactiveFormsModule,
    ListPaginationComponent,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  selector: 'app-loans-create',
  templateUrl: './loans-create.component.html',
  styleUrl: './loans-create.component.css',
})
export class LoansCreateComponent {
  maxDate = new Date();
  faCalendar = faCalendar;
  faList = faList;
  isLoading: boolean = false;
  addRecordForm: FormGroup;
  members: IUser[] = [];
  paginatatedUser!: IPaginatedUser;
  paginatatedLoanType!: IPaginatedLoanType;
  loanTypes: ILoanType[] = [];
  constructor(
    public appService: AppService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.addRecordForm = this.formBuilder.group({
      amount: [
        '',
        Validators.compose([Validators.required, Validators.min(1)]),
      ],
      userId: ['', Validators.compose([Validators.required])],
      loanTypeId: ['', Validators.compose([Validators.required])],
      transactionDate: ['', Validators.compose([Validators.required])],
    });

    this.addRecordForm.get('userId')?.valueChanges.subscribe((val: string) => {
      if (val) {
        if (val.length > 3) {
          this.fetchMembers({
            page: 0,
            pageSize: 15,
            searchTerm: val.trim(),
          });
        }
      }
    });

    this.addRecordForm
      .get('loanTypeId')
      ?.valueChanges.subscribe((val: string) => {
        if (val) {
          if (val.length > 3) {
            this.fetchLoanTypes({
              page: 0,
              pageSize: 15,
              searchTerm: val.trim(),
            });
          }
        }
      });
  }
  submit() {
    if (!this.addRecordForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }
    let data: any = {};
    let transactionDate: Date =
      this.addRecordForm.get('transactionDate')?.value;
    data['transactionDate'] = this.appService.getMySqlDate(transactionDate);
    let loanType: ILoanType = this.addRecordForm.get('loanTypeId')?.value;
    data['loanTypeId'] = loanType.id;
    let member: IUser = this.addRecordForm.get('userId')?.value;
    data['userId'] = member.id;
    let amount = this.addRecordForm.get('amount')?.value;
    data['amount'] = this.appService.sanitizeNumber(amount);

    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/loans`, data)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.addRecordForm.reset({});
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            res.statusMessage
          );
          let loan: ILoan = res.payload;
          this.router.navigate(['/loans/details', loan.id]);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  displayMemberFn(member: IUser): string {
    return member && member.id ? `${member.name} (${member.memberNumber})` : '';
  }
  displayloanTypFn(loanType: ILoanType): string {
    return loanType && loanType.id ? `${loanType.name}` : '';
  }

  fetchLoanTypes(pageSearch: IPageSearch) {
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.BASE_API_URL}/loan-types/list`,
        pageSearch
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.loanTypes = this.paginatatedLoanType.content;
        },
        next: (res) => {
          this.paginatatedLoanType = res.payload;
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }

  fetchMembers(pageSearch: IPageSearch) {
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/users/list`, pageSearch)
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.members = this.paginatatedUser.content;
        },
        next: (res) => {
          this.paginatatedUser = res.payload;
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }
}

<div class="container">
  <h3 class="my-5 text-center">Change your password</h3>
  <div class="row mt-3">
    <div class="col-md-6 my-3 mx-auto">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="changePasswordFormGroup" (submit)="submit()">
            <div class="my-2" *ngIf="isLoading">
              <app-loader></app-loader>
            </div>
            <div class="my-2">
              <label for="password" class="form-label fw-bold">Password </label>
              <input
                type="password"
                class="form-control"
                id="password"
                formControlName="password"
                autocomplete="off"
              />
            </div>
            <div class="my-2">
              <label for="cpassword" class="form-label fw-bold"
                >Confirm Password
              </label>
              <input
                type="password"
                class="form-control"
                id="cpassword"
                formControlName="cpassword"
                autocomplete="off"
              />
            </div>

            <div class="my-3" *ngIf="!isLoading">
              <a routerLink="/" class="btn btn-light me-2"> Cancel </a>
              <button type="submit" class="btn btn-primary me-2 text-white">
                Change Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Attachment Types</p>

    <button
      class="btn btn-sm btn-link"
      data-bs-toggle="modal"
      data-bs-target="#addAttachmentModal"
    >
      <fa-icon [icon]="faAdd" class="me-1"></fa-icon> Create Attachment Type
    </button>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  }
  <div class="my-4">
    <app-search-box (searchEvent)="search($event)"></app-search-box>
    @if (filteredRecords.length > 0) {
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Attachment Type</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr *ngFor="let record of filteredRecords">
            <td>{{ record.name }}</td>
            <td>
              <button
                class="btn btn-sm btn-light m-1 me-3"
                data-bs-toggle="modal"
                data-bs-target="#addAttachmentModal"
                (click)="openUpdateAttachmentType(record)"
              >
                <fa-icon [icon]="faFileEdit" class="me-1"></fa-icon> Edit
              </button>
              <button
                class="btn btn-sm btn-light m-1 me-3"
                data-bs-toggle="modal"
                data-bs-target="#deleteAttachmentModal"
                (click)="openDeleteAttachmentTypeDialog(record)"
              >
                <fa-icon [icon]="faTrashAlt" class="me-1"></fa-icon> Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    } @else {
    <div class="alert alert-warning my-3" role="alert">No records found.</div>
    }
  </div>
  <div
    class="modal fade"
    id="deleteAttachmentModal"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="deleteAttachmentModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteAttachmentModalLabel">
            Delete Attachment
          </h5>
          <button
            type="button"
            class="btn-close"
            id="del-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                @if (isLoading) {
                <div class="my-4 d-block">
                  <app-loader></app-loader>
                </div>
                }
              </div>

              @if (selectedAttachmentType) {
              <p>
                Are you sure you want to delete this attachmentType?
                <strong class="fw-bold">{{
                  selectedAttachmentType.name
                }}</strong>
              </p>
              }

              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="d-grid gap-2">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      class="btn btn-outline-primary m-1"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-grid gap-2">
                    <button
                      (click)="deleteRecord()"
                      class="btn btn-primary m-1"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="addAttachmentModal"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="addAttachmentModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addAttachmentModalLabel">
            {{ modalTitle }}
          </h5>
          <button
            type="button"
            class="btn-close"
            id="attachmentType-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                @if (isLoading) {
                <div class="my-4 d-block">
                  <app-loader></app-loader>
                </div>
                }
              </div>
              <form [formGroup]="attachmentTypeForm" (submit)="submit()">
                <label for="amount" class="form-label"
                  >Attachment <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="name"
                  id="name"
                  placeholder="e.g. ID Card"
                  required
                  autocomplete="off"
                />
                <div *ngIf="attachmentTypeFormControl['name'].touched">
                  <small
                    class="form-text text-danger"
                    *ngIf="attachmentTypeFormControl['name'].errors?.['required']"
                  >
                    Attachment is required!
                  </small>
                </div>

                <div class="row mt-3">
                  <div class="col-md-6">
                    <div class="d-grid gap-2">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        class="btn btn-outline-primary m-1"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-grid gap-2">
                      <button type="submit" class="btn btn-primary m-1">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

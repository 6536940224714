<div class="modal-header">
  <h1 class="modal-title fs-5" id="addGuaranteeModalLabel">
    Add Loan Guarantee
  </h1>
  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body">
  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  } @if(collateralType){
  <div class="m-2">
    <form class="row g-3" [formGroup]="addGuaranteeForm">
      <div class="col-md-12">
        <label for="collateralTypeId" class="form-label"
          >Guarantee Type <span class="text-danger">*</span></label
        >
        <select
          class="form-select"
          aria-label="Select Gender"
          formControlName="collateralTypeId"
        >
          @for (collateral of collateralTypes; track $index) {
          <option [value]="collateral.id">{{ collateral.name }}</option>
          }
        </select>
      </div>

      @if(collateralType.requireAttachment){
      <div class="col-md-12">
        <label for="collateralTypeId" class="form-label"
          >Attach Document <span class="text-danger">*</span></label
        >
        <div class="input-group">
          <input
            type="file"
            class="form-control"
            id="file"
            formControlName="file"
            (change)="onFileSelected($event.target)"
          />
        </div>
      </div>
      } @if(collateralType.requireUserId){
      <div class="col-md-12">
        <label for="uid" class="form-label"
          >Guarantor<span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="guarantor"
          placeholder="search members"
          [matAutocomplete]="memberuid"
        />
        <mat-autocomplete
          #memberuid="matAutocomplete"
          [displayWith]="displayMemberFn"
        >
          @for (memb of members ; track memb) {
          <mat-option [value]="memb"
            >{{ memb.name }} ({{ memb.memberNumber }})</mat-option
          >
          }
        </mat-autocomplete>
      </div>
      }

      <div class="col-md-12">
        <label for="description" class="form-label"
          >Description <span class="text-danger">*</span></label
        >
        <textarea
          class="form-control"
          formControlName="description"
          id="description"
          rows="2"
        ></textarea>
      </div>

      <div class="col-md-12 mb-3">
        <label for="value" class="form-label"
          >Value Guaranteed <span class="text-danger">*</span></label
        >
        <input
          type="number"
          class="form-control"
          formControlName="value"
          id="value"
          placeholder="e.g. 12000"
          required
          autocomplete="off"
        />
      </div>
    </form>
  </div>
  }
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-sm btn-outline-dark"
      data-bs-dismiss="modal"
      id="closeAddGuaranteeModal"
    >
      Cancel
    </button>
    <button
      type="button"
      (click)="submitGuarantee()"
      class="btn btn-sm btn-primary"
    >
      Add Guarantee
    </button>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSearch, faRemove } from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  imports: [FontAwesomeModule, ReactiveFormsModule],
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrl: './search-box.component.css',
})
export class SearchBoxComponent implements OnInit {
  formGroup: FormGroup;
  faSearch = faSearch;
  faRemove = faRemove;
  showClearBtn: boolean = false;
  @Input() seacrhBoxPlaceHolder: string = 'Search';
  @Output() searchEvent = new EventEmitter<string>();
  constructor(private formBuilder: UntypedFormBuilder) {
    this.formGroup = this.formBuilder.group({
      searchTerm: ['', [Validators.required]],
    });

    this.formGroup.get('searchTerm')?.valueChanges.subscribe((val) => {
      if (String(val).length > 0) {
        this.showClearBtn = true;
        this.submitSearch();
      } else {
        this.showClearBtn = false;
        this.submitSearch();
      }
    });
  }

  ngOnInit(): void {}
  submitSearch() {
    this.searchEvent.emit(this.formGroup.get('searchTerm')?.value);
  }

  clearSearch() {
    this.formGroup.patchValue({
      searchTerm: '',
    });
    this.searchEvent.emit(this.formGroup.get('searchTerm')?.value);
  }
}

<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Update Roles & Permissions</p>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  }
  <div class="my-4">
    <form class="row g-3" [formGroup]="editRoleForm" (submit)="submit()">
      <div class="col-md-6">
        <label for="name" class="form-label"
          >Name <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="name"
          id="name"
          placeholder="e.g. ACCOUNTANT"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editRoleFormControl['name'].touched &&
            editRoleFormControl['name'].errors?.['required']
          "
        >
          Role name is required
        </span>
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editRoleFormControl['name'].touched &&
            editRoleFormControl['name'].errors?.['valueInvalid']
          "
        >
          A role name can only contain capital letters with a maximum length of
          50 characters without spaces
        </span>
      </div>

      <div class="col-md-6">
        <label for="displayName" class="form-label"
          >Display Name <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="displayName"
          id="displayName"
          placeholder="e.g. Accountant Role"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editRoleFormControl['displayName'].touched &&
            editRoleFormControl['displayName'].errors?.['required']
          "
        >
          Display name is required
        </span>
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editRoleFormControl['displayName'].touched &&
            editRoleFormControl['displayName'].errors?.['maxlength']
          "
        >
          Display name cannot be more than 50 characters
        </span>
      </div>

      <div class="col-12">
        <label for="description" class="form-label"
          >Description <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="description"
          id="description"
          placeholder="e.g. The accountant role for managing invoices, payments and, receipts"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editRoleFormControl['description'].touched &&
            editRoleFormControl['description'].errors?.['required']
          "
        >
          Description is required
        </span>
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editRoleFormControl['description'].touched &&
            editRoleFormControl['description'].errors?.['maxlength']
          "
        >
          Description cannot be more than 200 characters
        </span>
      </div>

      <div class="col-12">
        <label for="permissionIds" class="form-label"
          >Privileges <span class="text-danger">*</span>
          <small class="text-muted">
            Select the privileges associated to this role</small
          ></label
        >
        <br />

        <ng-container formArrayName="permissionsFormArray">
          <ng-container
            *ngFor="
              let aPermission of permissionsFormArray.controls;
              let i = index
            "
          >
            <div class="form-check" [formGroupName]="i">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="permissionId"
                id="btn-perm-{{ i }}"
              />
              <label class="form-check-label" for="btn-perm-{{ i }}">
                {{ allPermissions[i].name }}
              </label>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="col-12 mt-4">
        <a
          routerLink="/settings/rbac-settings"
          type="button"
          class="btn btn-sm btn-outline-dark me-2"
          >Cancel</a
        >
        <button type="submit" class="btn btn-sm btn-primary me-2">
          Update Role
        </button>
      </div>
    </form>
  </div>
</div>

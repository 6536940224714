<div class="d-flex justify-content-between mt-4">
  <button
    data-bs-toggle="modal"
    data-bs-target="#addGuaranteeModal"
    class="btn btn-sm btn-link"
  >
    <fa-icon [icon]="faPlus" class="me-1"></fa-icon> Add Guarantees
  </button>
</div>
@if (loanCollaterals) { @if (loanCollaterals.length > 0) {
<div class="table-responsive">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Type</th>
        <th scope="col">Name</th>
        <th scope="col">Description</th>
        <th scope="col">value</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody class="table-group-divider">
      <tr *ngFor="let collateral of loanCollaterals">
        <td>{{ collateral.collateralTypeName }}</td>
        <td>
          @if (collateral.collateralTypeUid === 'GUARANTORS' ) {
          <a [routerLink]="['/members/member', collateral.uid]">{{
            collateral.name
          }}</a>
          } @else if(collateral.collateralTypeUid === 'LAND_TITLE_DEED' ||
          collateral.collateralTypeUid === 'VEHICLE_LOGBOOK') {
          <a [href]="collateral.link" target="_blank">{{ collateral.name }}</a>
          }@else {
          {{ collateral.name }} }
        </td>
        <td>{{ collateral.description }}</td>
        <td>{{ collateral.value | currency : " " }}</td>
        <td>
          <button
            (click)="setSelectedCollateral(collateral)"
            data-bs-toggle="modal"
            data-bs-target="#removeGuaranteeModal"
            class="btn btn-sm btn-link"
          >
            <fa-icon [icon]="faTrash" class="me-1"></fa-icon> Remove
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
} @else {
<div class="alert alert-warning my-3" role="alert">
  No guarantees found for this loan.
</div>
} }@else {
<div class="alert alert-warning my-3" role="alert">
  No guarantees found for this loan.
</div>
}
<!-- Start Guarantee Modal -->
<div
  class="modal fade"
  id="addGuaranteeModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="addGuaranteeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <app-loan-guarantee-create
        [loanId]="loanId"
        (reloadRecordEvent)="fetchLoanRecord($event)"
      ></app-loan-guarantee-create>
    </div>
  </div>
</div>

<!-- End Guarantee Modal -->

<!-- Start Remove Guarantee  modal -->
<div
  class="modal fade"
  id="removeGuaranteeModal"
  tabindex="-1"
  aria-labelledby="removeGuaranteeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="removeGuaranteeModalLabel">
          Remove Guarantee
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        @if(isLoading){
        <div class="my-2 d-block">
          <app-loader></app-loader>
        </div>
        } @if(selectedCollateral){
        <p>
          Are you sure you want to delete
          <strong
            >{{ selectedCollateral.description }} ({{
              selectedCollateral.value | currency : " "
            }}) </strong
          >?
        </p>
        }
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-sm btn-outline-dark me-2"
          data-bs-dismiss="modal"
          id="closeremoveGuaranteeModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary"
          (click)="submitRemoveCollateral()"
        >
          Remove
        </button>
      </div>
    </div>
  </div>
</div>
<!-- End Remove Guarantee  modal -->

import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  Validators,
  FormArray,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { environment } from '../../../../environments/environment.development';
import { AppEnums } from '../../../models/app.enums';
import { IPermission } from '../../../models/permission.interface';
import { IRole } from '../../../models/role.interface';
import { AppService } from '../../../services/app.service';
import { InputValidationService } from '../../../services/input-validation.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderComponent } from '../../../loader/loader.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    ReactiveFormsModule,
  ],
  selector: 'app-rbac-update',
  templateUrl: './rbac-update.component.html',
  styleUrl: './rbac-update.component.css',
})
export class RbacUpdateComponent implements OnInit {
  isLoading: boolean = false;
  selectedPermissions: string[] = [];
  allPermissions: IPermission[] = [];
  editRoleForm: FormGroup;
  roleId!: string;

  constructor(
    public appService: AppService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private inputValidationService: InputValidationService,
    private activeRoute: ActivatedRoute
  ) {
    this.editRoleForm = this.formBuilder.group({
      name: [
        '',
        Validators.compose([
          Validators.required,
          this.inputValidationService.patternValidator(/^[A-Z]{1,50}$/),
        ]),
      ],
      displayName: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      description: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(200)]),
      ],
      permissionsFormArray: this.formBuilder.array([]),
    });
    this.appService.getPermissions.subscribe((permissions) => {
      this.allPermissions = permissions;
      this.allPermissions.forEach((aPermission) => {
        this.permissionsFormArray.push(
          this.formBuilder.group({
            permissionId: [''],
          })
        );
      });
    });

    this.permissionsFormArray.valueChanges.subscribe((changes) => {
      this.selectedPermissions = [];
      for (let index = 0; index < changes.length; index++) {
        const element = changes[index];
        if (element.permissionId === true) {
          this.selectedPermissions.push(this.allPermissions[index].id);
        }
      }
    });
  }
  ngOnInit(): void {
    this.activeRoute.params.subscribe((p) => {
      this.roleId = p['id'];
      this.fetchRole(this.roleId);
    });
  }

  get editRoleFormControl() {
    return this.editRoleForm.controls;
  }

  get permissionsFormArray(): FormArray {
    return this.editRoleForm.get('permissionsFormArray') as FormArray;
  }

  fetchRole(roleId: string) {
    this.isLoading = true;
    this.appService
      .makeGetRequest(`${environment.BASE_API_URL}/roles/get/${roleId}`)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          let role: IRole = res.payload;
          this.editRoleForm.patchValue({
            name: role.name,
            displayName: role.displayName,
            description: role.description,
          });
          role.rolePermissions.forEach((permissionId) => {
            let index = this.allPermissions.findIndex(
              (x) => x.id === permissionId
            );
            if (index > -1) {
              this.permissionsFormArray
                .at(index)
                .patchValue({ permissionId: true });
            }
          });
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  submit() {
    if (!this.editRoleForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }

    if (this.selectedPermissions.length == 0) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Select at least one permission'
      );
      return;
    }

    let data = {
      name: this.editRoleForm.get('name')?.value,
      displayName: this.editRoleForm.get('displayName')?.value,
      description: this.editRoleForm.get('description')?.value,
      rolePermissions: this.selectedPermissions,
    };
    this.isLoading = true;
    this.appService
      .makePutRequest(
        `${environment.BASE_API_URL}/roles/update/${this.roleId}`,
        data
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            res.statusMessage
          );
          this.editRoleForm.reset();
          let role: IRole = res.payload;
          this.router.navigate(['/settings/rbac-settings/view', role.id]);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ILoan } from '../../models/loan.interface';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-loan-details-view',
  templateUrl: './loan-details-view.component.html',
  styleUrl: './loan-details-view.component.css',
})
export class LoanDetailsViewComponent {
  @Input()
  record!: ILoan;
}

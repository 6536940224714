<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Loan Types</p>

    <div>
      <button
        [routerLink]="['/settings/loan-types']"
        [routerLinkActive]="['active']"
        class="btn btn-sm btn-link"
      >
        <fa-icon [icon]="faList" class="me-1"></fa-icon> All Loan Types
      </button>
      <button
        [routerLink]="['/settings/loan-types/update', this.recordId]"
        [routerLinkActive]="['active']"
        class="btn btn-sm btn-link"
      >
        <fa-icon [icon]="faFileEdit" class="me-1"></fa-icon> Edit Loan Type
      </button>
      <button
        class="btn btn-sm btn-link"
        data-bs-toggle="modal"
        data-bs-target="#deleteRecord"
      >
        <fa-icon [icon]="faTrash" class="me-1"></fa-icon> Delete Loan Type
      </button>
    </div>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  } @if (record ) {
  <div class="my-4">
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <span class="text-muted">Loan Name</span>
        <div class="fw-bold">{{ record.name }}</div>
      </li>

      <li class="list-group-item">
        <span class="text-muted">Interest Rate</span>
        <div class="fw-bold">
          {{ record.interestRate | percent : "1.2-3" }}
          {{ record.periodicity.name }}
        </div>
      </li>
      <li class="list-group-item">
        <span class="text-muted">Loan Term</span>
        <div class="fw-bold">{{ record.term }} {{ record.termDesc }}</div>
      </li>
      <li class="list-group-item">
        <span class="text-muted">Maximum Amount Multiplier</span>
        <div class="fw-bold">{{ record.maxAmountMultiplier }}x</div>
      </li>
      <li class="list-group-item">
        <span class="text-muted">Loan Description</span>
        <div class="fw-bold">{{ record.description }}</div>
      </li>

      <li class="list-group-item">
        <small class="text-muted"
          >Created {{ record.createdAt | date : "medium" }} Last Updated
          {{ record.updatedAt | date : "medium" }}</small
        >
      </li>
    </ul>
  </div>

  <div
    class="modal fade"
    id="deleteRecord"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="deleteRecordLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteRecordLabel">Delete Loan Type</h5>
          <button
            type="button"
            class="btn-close"
            id="del-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Are you sure you want to delete the role
          <strong>{{ record.name }}</strong> ? @if (isLoading) {
          <app-loader></app-loader>
          }
        </div>
        <div class="modal-footer">
          <button
            id="closedeleteRecordModal"
            type="button"
            class="btn btn-sm btn-outline-dark"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-sm btn-outline-danger"
            (click)="deleteRecord()"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  }
</div>

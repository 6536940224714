<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Update Organization Details</p>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  }
  <div class="my-4">
    <form class="row g-3" [formGroup]="editOrgFormGroup" (submit)="submit()">
      <div class="col-md-4">
        <label for="name" class="form-label"
          >Organization Name <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="name"
          id="name"
          placeholder="e.g. Caritas SHG"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editOrgFormControl['name'].touched &&
            editOrgFormControl['name'].errors?.['required']
          "
        >
          Organization name is required
        </span>
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editOrgFormControl['name'].touched &&
            editOrgFormControl['name'].errors?.['maxlength']
          "
        >
          Maximum name length is 50 characters
        </span>
      </div>
      <div class="col-md-4">
        <label for="email" class="form-label"
          >Organization Email <span class="text-danger">*</span></label
        >
        <input
          type="email"
          class="form-control"
          formControlName="email"
          id="email"
          placeholder="e.g. info@sakko.com"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editOrgFormControl['email'].touched &&
            editOrgFormControl['email'].errors?.['required']
          "
        >
          Organization email is required
        </span>
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editOrgFormControl['email'].touched &&
            editOrgFormControl['email'].errors?.['email']
          "
        >
          Organization email is invalid
        </span>
      </div>

      <div class="col-md-4">
        <label for="phone" class="form-label"
          >Organization Phone Number <span class="text-danger">*</span></label
        >
        <input
          type="tel"
          class="form-control"
          formControlName="phone"
          id="phone"
          placeholder="e.g. +254718225954"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editOrgFormControl['phone'].touched &&
            editOrgFormControl['phone'].errors?.['required']
          "
        >
          Organization phone number is required
        </span>
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editOrgFormControl['phone'].touched &&
            editOrgFormControl['phone'].errors?.['invalidPhone']
          "
        >
          Organization phone number is invalid
        </span>
      </div>

      <div class="col-md-4">
        <label for="website" class="form-label"
          >Website Address <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="website"
          id="website"
          placeholder="e.g. https://sakko.co.ke"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editOrgFormControl['website'].touched &&
            editOrgFormControl['website'].errors?.['required']
          "
        >
          Organization website address is required
        </span>
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editOrgFormControl['website'].touched &&
            editOrgFormControl['website'].errors?.['valueInvalid']
          "
        >
          Organization website address invalid
        </span>
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editOrgFormControl['website'].touched &&
            editOrgFormControl['website'].errors?.['maxlength']
          "
        >
          Maximum length is 100 characters
        </span>
      </div>

      <div class="col-md-4">
        <label for="website" class="form-label"
          >Address <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="address"
          id="address"
          placeholder="e.g. Postal or Physical address"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editOrgFormControl['address'].touched &&
            editOrgFormControl['address'].errors?.['required']
          "
        >
          Organization address is required
        </span>
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            editOrgFormControl['address'].touched &&
            editOrgFormControl['address'].errors?.['maxlength']
          "
        >
          Maximum address length is 100 characters
        </span>
      </div>
      <div class="col-md-4">
        <label for="country" class="form-label"
          >Country <span class="text-danger">*</span></label
        >
        <select
          class="form-select"
          aria-label="Select Country"
          formControlName="country"
        >
          @for (country of countries; track $index) {
          <option [value]="country.id">{{ country.name }}</option>
          }
        </select>
      </div>

      <div class="col-md-4">
        <label for="currency" class="form-label"
          >Currency <span class="text-danger">*</span></label
        >
        <select
          class="form-select"
          aria-label="Select Currency"
          formControlName="currency"
        >
          @for (currency of currencies; track $index) {
          <option [value]="currency.id">{{ currency.name }}</option>
          }
        </select>
      </div>

      <div class="col-md-4">
        <label for="language" class="form-label"
          >Language <span class="text-danger">*</span></label
        >
        <select
          class="form-select"
          aria-label="Select Language"
          formControlName="language"
        >
          @for (language of languages; track $index) {
          <option [value]="language.id">{{ language.name }}</option>
          }
        </select>
      </div>

      <div class="col-md-4">
        <label for="timezone" class="form-label"
          >Timezone <span class="text-danger">*</span></label
        >
        <select
          class="form-select"
          aria-label="Select Timezone"
          formControlName="timezone"
        >
          @for (timezone of timezones; track $index) {
          <option [value]="timezone.zone">{{ timezone.zone }}</option>
          }
        </select>
      </div>

      <div class="col-12 mt-4">
        <a
          routerLink="/settings/organization-settings"
          type="button"
          class="btn btn-sm btn-outline-dark me-2"
          >Cancel</a
        >
        <button type="submit" class="btn btn-sm btn-primary me-2">
          Update Organization
        </button>
      </div>
    </form>
  </div>
</div>

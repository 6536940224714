import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderComponent } from '../../../loader/loader.component';
import {
  FormGroup,
  UntypedFormBuilder,
  Validators,
  FormArray,
  ReactiveFormsModule,
} from '@angular/forms';
import { environment } from '../../../../environments/environment.development';
import { AppEnums } from '../../../models/app.enums';
import { AppService } from '../../../services/app.service';
import { IPermission } from '../../../models/permission.interface';
import { IRole } from '../../../models/role.interface';
import { InputValidationService } from '../../../services/input-validation.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    ReactiveFormsModule,
  ],
  selector: 'app-rbac-create',
  templateUrl: './rbac-create.component.html',
  styleUrl: './rbac-create.component.css',
})
export class RbacCreateComponent {
  isLoading: boolean = false;
  selectedPermissions: string[] = [];
  allPermissions: IPermission[] = [];
  addRoleForm: FormGroup;
  constructor(
    public appService: AppService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private inputValidationService: InputValidationService
  ) {
    this.addRoleForm = this.formBuilder.group({
      name: [
        '',
        Validators.compose([
          Validators.required,
          this.inputValidationService.patternValidator(/^[A-Z]{1,50}$/),
        ]),
      ],
      displayName: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      description: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(200)]),
      ],
      permissionsFormArray: this.formBuilder.array([]),
    });
    this.appService.getPermissions.subscribe((permissions) => {
      this.allPermissions = permissions;
      this.allPermissions.forEach((aPermission) => {
        this.permissionsFormArray.push(
          this.formBuilder.group({
            permissionId: [''],
          })
        );
      });
    });

    this.permissionsFormArray.valueChanges.subscribe((changes) => {
      this.selectedPermissions = [];
      for (let index = 0; index < changes.length; index++) {
        const element = changes[index];
        if (element.permissionId === true) {
          this.selectedPermissions.push(this.allPermissions[index].id);
        }
      }
    });
  }

  get addRoleFormControl() {
    return this.addRoleForm.controls;
  }

  get permissionsFormArray(): FormArray {
    return this.addRoleForm.get('permissionsFormArray') as FormArray;
  }

  submit() {
    if (!this.addRoleForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }

    if (this.selectedPermissions.length == 0) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Select at least one permission'
      );
      return;
    }

    let data = {
      name: this.addRoleForm.get('name')?.value,
      displayName: this.addRoleForm.get('displayName')?.value,
      description: this.addRoleForm.get('description')?.value,
      rolePermissions: this.selectedPermissions,
    };
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/roles/add`, data)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            res.statusMessage
          );
          this.addRoleForm.reset();
          let role: IRole = res.payload;
          this.router.navigate(['/settings/rbac-settings/view', role.id]);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
}

<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Update Member Records</p>

    <button
      [routerLink]="['/members']"
      [routerLinkActive]="['active']"
      class="btn btn-sm btn-link"
    >
      <fa-icon [icon]="faList" class="me-1"></fa-icon> All Members
    </button>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  }

  <div class="my-4">
    <form class="row g-3" [formGroup]="addRecordForm" (submit)="submit()">
      <div class="col-md-4">
        <label for="memberNumber" class="form-label"
          >Member Number <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="memberNumber"
          id="memberNumber"
          placeholder="e.g. CSG004"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            addRecordFormControl['memberNumber'].touched &&
            addRecordFormControl['memberNumber'].errors?.['required']
          "
        >
          Member Number is required
        </span>
      </div>
      <div class="col-md-4">
        <label for="name" class="form-label"
          >Name <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="name"
          id="name"
          placeholder="e.g. Jane Doe"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            addRecordFormControl['name'].touched &&
            addRecordFormControl['name'].errors?.['required']
          "
        >
          Name is required
        </span>
      </div>

      <div class="col-md-4">
        <label for="email" class="form-label"
          >Email <span class="text-danger">*</span></label
        >
        <input
          type="email"
          class="form-control"
          formControlName="email"
          id="email"
          placeholder="e.g. jane@gmail.com"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            addRecordFormControl['email'].touched &&
            addRecordFormControl['email'].errors?.['required']
          "
        >
          Email is required
        </span>
      </div>

      <div class="col-md-4">
        <label for="phone" class="form-label"
          >Phone <span class="text-danger">*</span></label
        >
        <input
          type="tel"
          class="form-control"
          formControlName="phone"
          id="phone"
          placeholder="e.g. +254712345678"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            addRecordFormControl['phone'].touched &&
            addRecordFormControl['phone'].errors?.['required']
          "
        >
          Phone is required
        </span>
      </div>
      <div class="col-md-4">
        <label for="idNumber" class="form-label"
          >ID Number <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="idNumber"
          id="idNumber"
          placeholder="e.g. 12345678"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            addRecordFormControl['idNumber'].touched &&
            addRecordFormControl['idNumber'].errors?.['required']
          "
        >
          ID Number is required
        </span>
      </div>
      <div class="col-md-4">
        <label for="taxPin" class="form-label"
          >Tax PIN <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          formControlName="taxPin"
          id="taxPin"
          placeholder="e.g. A001234567C"
        />
        <span
          class="d-block text-danger fw-lighter fst-italic"
          *ngIf="
            addRecordFormControl['idNumber'].touched &&
            addRecordFormControl['idNumber'].errors?.['required']
          "
        >
          Tax PIN is required
        </span>
      </div>
      <div class="col-md-4">
        <label for="gender" class="form-label"
          >Gender <span class="text-danger">*</span></label
        >
        <select
          class="form-select"
          aria-label="Select Gender"
          formControlName="gender"
        >
          @for (gender of genders; track $index) {
          <option [value]="gender.name">{{ gender.name }}</option>
          }
        </select>
      </div>
      <div class="col-md-4">
        <label for="dob" class="form-label"
          >Date of Birth<span class="text-danger">*</span></label
        >
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            formControlName="dob"
            id="dob"
            readonly
            [max]="maxDateOfBirth"
            [matDatepicker]="dobPicker"
          />
          <mat-datepicker #dobPicker></mat-datepicker>

          <button
            class="btn btn-outline-dark"
            type="button"
            id="button-addon2"
            (click)="dobPicker.open()"
          >
            <fa-icon [icon]="faCalendar"></fa-icon>
          </button>
        </div>
      </div>

      <div class="col-12 mt-4">
        <a
          [routerLink]="['/members/member', this.recordId]"
          type="button"
          class="btn btn-sm btn-outline-dark me-2"
          >Cancel</a
        >
        <button type="submit" class="btn btn-sm btn-primary me-2">
          Update Member
        </button>
      </div>
    </form>
  </div>
</div>

<div class="container" id="ken-border">
  <div class="row mt-3">
    <div class="col-xs-12 col-md-6 mx-auto">
      <div class="card mb-3">
        <div class="row g-0">
          <div class="col-md-6">
            <img
              src="assets/auth-banner.png"
              class="img-fluid rounded-start"
              alt="Sakko"
            />
          </div>
          <div class="col-md-6">
            <div class="card-body">
              <div class="text-center">
                <img src="assets/logo.svg" class="img-fluid" />

                <h5 class="card-title mt-4 mb-3">Welcome to Sakko</h5>

                <button
                  class="gsi-material-button"
                  style="width: 400"
                  (click)="join()"
                >
                  <div class="gsi-material-button-state"></div>
                  <div class="gsi-material-button-content-wrapper">
                    <div class="gsi-material-button-icon">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        style="display: block"
                      >
                        <path
                          fill="#EA4335"
                          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                        ></path>
                        <path
                          fill="#4285F4"
                          d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                        ></path>
                        <path
                          fill="#FBBC05"
                          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                        ></path>
                        <path
                          fill="#34A853"
                          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                        ></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </svg>
                    </div>
                    <span class="gsi-material-button-contents"
                      >Continue with Google</span
                    >
                    <span style="display: none">Continue with Google</span>
                  </div>
                </button>
                @if (isLoading) {
                <div class="my-4 d-block">
                  <app-loader></app-loader>
                </div>
                }
                <hr class="my-4" />

                <div class="text-center d-block">
                  <a
                    href="https://play.google.com/store/apps/details?id=ke.rubixdigital.io&utm_source=admin-web&utm_campaign=admin-web&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    target="_blank"
                    ><img
                      alt="Get it on Google Play"
                      src="assets/google-play-badge.png"
                      class="img-playstore img-fluid"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

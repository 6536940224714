import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListPaginationComponent } from '../../list-pagination/list-pagination.component';
import { LoaderComponent } from '../../loader/loader.component';
import { SearchBoxComponent } from '../../search-box/search-box.component';
import {
  faPlusCircle,
  faCloudDownload,
  faArrowRight,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import { ILoan, IPaginatedLoan } from '../../models/loan.interface';
import { IPageSearch } from '../../models/page-search.interface';
import {
  IPaginatedAccount,
  IAccount,
} from '../../models/saving-account.interface';
import { IPaginatedUser, IUser } from '../../models/user.interface';
import { AppService } from '../../services/app.service';
import { environment } from '../../../environments/environment.development';
import { AppEnums } from '../../models/app.enums';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    SearchBoxComponent,
    ReactiveFormsModule,
    ListPaginationComponent,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  selector: 'app-loans-list',
  templateUrl: './loans-list.component.html',
  styleUrl: './loans-list.component.css',
})
export class LoansListComponent implements OnInit {
  appService = inject(AppService);
  router = inject(Router);
  faPlusCircle = faPlusCircle;
  faCloudDownload = faCloudDownload;
  faArrowRight = faArrowRight;
  faCalendar = faCalendar;
  isLoading: boolean = false;
  records: ILoan[] = [];
  members: IUser[] = [];
  paginatatedRecord!: IPaginatedLoan;
  pageSearch: IPageSearch = {
    page: 0,
    pageSize: 15,
    searchTerm: '',
  };
  filterFormGroup: FormGroup;
  paymodes: string[] = [];

  constructor(private formBuilder: UntypedFormBuilder) {
    this.filterFormGroup = this.formBuilder.group({
      startDate: [new Date(), Validators.compose([Validators.required])],
      endDate: [new Date(), Validators.compose([Validators.required])],
      payerId: [''],
      posterId: [''],
      accountId: [''],
      payMode: [''],
    });
  }
  ngOnInit(): void {
    this.fetchRecords(this.pageSearch);
  }

  fetchRecords(pageSearch: IPageSearch) {
    this.appService.clearErrorBag();
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/loans/list`, pageSearch)
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.records = this.paginatatedRecord.content;
        },
        next: (res) => {
          this.paginatatedRecord = res.payload;
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }

  search($evt: any) {
    let searchTerm: string = String($evt);
    if (searchTerm.length > 2) {
      this.pageSearch['searchTerm'] = searchTerm;
      this.fetchRecords(this.pageSearch);
    } else {
      this.pageSearch['searchTerm'] = '';
      this.fetchRecords(this.pageSearch);
    }
  }

  view(id: string) {
    this.router.navigate(['/loans/details', id]);
  }
}

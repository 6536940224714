import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILoanCollateral } from '../../models/loan-collateral.interface';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../environments/environment.development';
import { AppEnums } from '../../models/app.enums';
import { AppService } from '../../services/app.service';
import { LoanGuaranteeCreateComponent } from '../loan-guarantee-create/loan-guarantee-create.component';
import { RouterLink } from '@angular/router';
import { LoaderComponent } from '../../loader/loader.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    FontAwesomeModule,
    LoaderComponent,
    LoanGuaranteeCreateComponent,
  ],
  selector: 'app-loan-guarantee-view',
  templateUrl: './loan-guarantee-view.component.html',
  styleUrl: './loan-guarantee-view.component.css',
})
export class LoanGuaranteeViewComponent {
  faPlus = faPlus;
  faTrash = faTrash;
  @Input()
  loanId!: string;
  @Input()
  loanCollaterals: ILoanCollateral[] = [];
  isLoading: boolean = false;
  selectedCollateral!: ILoanCollateral;
  constructor(public appService: AppService) {}
  @Output() reloadRecordEvent = new EventEmitter<string>();
  setSelectedCollateral(collateral: ILoanCollateral) {
    this.selectedCollateral = collateral;
  }

  submitRemoveCollateral() {
    this.isLoading = true;
    this.appService
      .makeDeleteRequest(
        `${environment.BASE_API_URL}/loans/${this.loanId}/collateral/${this.selectedCollateral.id}/remove`
      )
      .subscribe({
        complete: () => {
          document.getElementById('closeremoveGuaranteeModal')?.click();
          this.isLoading = false;
        },
        next: (res) => {
          this.fetchLoanRecord(this.loanId);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  fetchLoanRecord($event: string) {
    this.reloadRecordEvent.emit($event);
  }
}

<div class="container-fluid">
  <h3 class="my-3">
    <fa-icon [icon]="faWrench" class="me-1"></fa-icon>
    System Settings
  </h3>
  <div class="list-group">
    @for (settingsModule of settingsModules; track $index) {
    <a
      [routerLink]="settingsModule.link"
      class="list-group-item list-group-item-action d-flex justify-content-between align-items-start"
    >
      <div>
        <div class="fw-bold">{{ settingsModule.title }}</div>
        {{ settingsModule.description }}
      </div>
      <fa-icon [icon]="faChevronRight" class="me-1"></fa-icon>
    </a>
    }
  </div>
</div>

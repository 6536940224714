<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Loan #{{ record.referenceNumber }}</p>

    <div>
      <button
        [routerLink]="['/loans']"
        [routerLinkActive]="['active']"
        class="btn btn-sm btn-link"
      >
        <fa-icon [icon]="faList" class="me-1"></fa-icon> All Loans
      </button>
    </div>
  </div>
  @if(isLoading){
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  } @if(record) {
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        id="loan-details-tab"
        data-bs-toggle="tab"
        data-bs-target="#loan-details-tab-pane"
        type="button"
        role="tab"
        aria-controls="loan-details-tab-pane"
        aria-selected="true"
      >
        <fa-icon [icon]="faClipboard" class="me-1"></fa-icon> Loan Details
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="loan-payments-tab"
        data-bs-toggle="tab"
        data-bs-target="#loan-payments-tab-pane"
        type="button"
        role="tab"
        aria-controls="loan-payments-tab-pane"
        aria-selected="false"
      >
        <fa-icon [icon]="faMoneyBill" class="me-1"></fa-icon> Loan Payments
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="loan-guarantee-tab"
        data-bs-toggle="tab"
        data-bs-target="#loan-guarantee-tab-pane"
        type="button"
        role="tab"
        aria-controls="loan-guarantee-tab-pane"
        aria-selected="false"
      >
        <fa-icon [icon]="faHandshake" class="me-1"></fa-icon> Loan Guarantees
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="loan-attachments-tab"
        data-bs-toggle="tab"
        data-bs-target="#loan-attachments-tab-pane"
        type="button"
        role="tab"
        aria-controls="loan-attachments-tab-pane"
        aria-selected="false"
      >
        <fa-icon [icon]="faPaperclip" class="me-1"></fa-icon> Loan Attachments
      </button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="loan-details-tab-pane"
      role="tabpanel"
      aria-labelledby="loan-details-tab"
      tabindex="0"
    >
      <app-loan-details-view [record]="record"></app-loan-details-view>
    </div>
    <div
      class="tab-pane fade"
      id="loan-payments-tab-pane"
      role="tabpanel"
      aria-labelledby="loan-payments-tab"
      tabindex="0"
    >
      <h1>Loan payments</h1>
    </div>
    <div
      class="tab-pane fade"
      id="loan-guarantee-tab-pane"
      role="tabpanel"
      aria-labelledby="loan-guarantee-tab"
      tabindex="0"
    >
      <app-loan-guarantee-view
        [loanCollaterals]="record.loanCollateral"
        [loanId]="recordId"
        (reloadRecordEvent)="fetchRecord($event)"
      ></app-loan-guarantee-view>
    </div>
    <div
      class="tab-pane fade"
      id="loan-attachments-tab-pane"
      role="tabpanel"
      aria-labelledby="loan-attachments-tab"
      tabindex="0"
    >
      <h1>Loan Attachments</h1>
    </div>
  </div>
  }
</div>

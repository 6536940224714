<div class="container text-center">
  <h3 class="my-5">
    Hello {{ firstName }},
    <small class="d-block small text-muted"
      >It seems you are not linked to a
      <span class="fst-italic text-dark"> chama </span> yet</small
    >
  </h3>

  <h5>How do you want to proceed?</h5>
  <div class="row mt-3">
    <div class="col-md-6 my-3">
      <div class="card clickable-card" (click)="registerGroup()">
        <div class="card-body">
          <p class="h3">
            I want to sign up my <span class="fst-italic"> chama </span>
          </p>
          <h6 class="text-muted">
            Create a new account for your
            <span class="fst-italic"> chama </span>
          </h6>
        </div>
      </div>
    </div>
    <div class="col-md-6 my-3">
      <div class="card clickable-card" (click)="joinGroup()">
        <div class="card-body">
          <p class="h3">
            I want to join a <span class="fst-italic"> chama </span>
          </p>
          <h6 class="text-muted">
            Provide the
            <span class="fst-italic"> chama </span> code to join
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faWrench, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ISettingsModule } from '../../models/settings-module.interface';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  standalone: true,
  imports: [FontAwesomeModule, RouterOutlet, RouterLink, RouterLinkActive],
  selector: 'app-default-settings',
  templateUrl: './default-settings.component.html',
  styleUrl: './default-settings.component.css',
})
export class DefaultSettingsComponent {
  faWrench = faWrench;
  faChevronRight = faChevronRight;

  settingsModules: ISettingsModule[] = [
    {
      title: 'Organization Profile',
      subtitle: 'Organization Profile',
      description: 'Set organization details such as name, address, logo etc.',
      ctaLabel: 'Manage Organization Profile',
      link: '/settings/organization-settings',
    },
    {
      title: 'SMS Communication',
      subtitle: 'SMS Settings',
      description: 'Configure Bulk SMS Messaging',
      ctaLabel: 'Manage SMS Communication',
      link: '/settings/sms-settings',
    },
    {
      title: 'Billing & Payments',
      subtitle: 'Billing Profile',
      description: 'View invoices, Payments & Receipts',
      ctaLabel: 'Manage Billing & Payments',
      link: '/settings/billing-settings',
    },
    {
      title: 'System Security',
      subtitle: 'Roles and Permissions',
      description: 'Ensure your users access only the information they need.',
      ctaLabel: 'Manage Security Settings',
      link: '/settings/rbac-settings',
    },
    {
      title: 'Tags',
      subtitle: 'Grouping Tags',
      description: 'Tags add metadata to help group related records.',
      ctaLabel: 'Manage Tags',
      link: '/settings/tags',
    },

    {
      title: 'Attachment Types',
      subtitle: 'Attachment Types',
      description:
        'Attachment Types is the list of attachments you will required.',
      ctaLabel: 'Manage Attachment Types',
      link: '/settings/attachments',
    },
    {
      title: 'Loan Periodicity',
      subtitle: 'Loan Periodicity',
      description:
        'Periodicity refers to the frequency at which interest accrues.',
      ctaLabel: 'Manage Loan Periodicity',
      link: '/settings/periodicity',
    },
    {
      title: 'Loan Type',
      subtitle: 'Loan Type',
      description:
        'Categorize loans based on their purpose, terms, and conditions.',
      ctaLabel: 'Manage Loan Types',
      link: '/settings/loan-types',
    },
    {
      title: 'Accounts',
      subtitle: 'Accounts',
      description: 'Manage accounts.',
      ctaLabel: 'Manage Accounts',
      link: '/settings/accounts',
    },
  ];
}

import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUserPlus, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { LoaderComponent } from '../../../loader/loader.component';
import { IPageSearch } from '../../../models/page-search.interface';
import { IPaginatedRole, IRole } from '../../../models/role.interface';
import { AppService } from '../../../services/app.service';
import { environment } from '../../../../environments/environment.development';
import { SearchBoxComponent } from '../../../search-box/search-box.component';
import { ListPaginationComponent } from '../../../list-pagination/list-pagination.component';
import { AppEnums } from '../../../models/app.enums';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    SearchBoxComponent,
    ListPaginationComponent,
  ],

  selector: 'app-rbac-list',
  templateUrl: './rbac-list.component.html',
  styleUrl: './rbac-list.component.css',
})
export class RbacListComponent implements OnInit {
  router = inject(Router);
  appService = inject(AppService);
  paginatatedRecord!: IPaginatedRole;
  isLoading: boolean = false;
  faUserPlus = faUserPlus;
  faArrowRight = faArrowRight;
  records: IRole[] = [];
  pageSearch: IPageSearch = {
    page: 0,
    pageSize: 15,
    searchTerm: '',
  };

  ngOnInit(): void {
    this.fetchRecords(this.pageSearch);
  }

  fetchRecords(pageSearch: IPageSearch) {
    this.appService.clearErrorBag();
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/roles/list`, pageSearch)
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.records = this.paginatatedRecord.content;
        },
        next: (res) => {
          this.paginatatedRecord = res.payload;
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }

  search($evt: any) {
    let searchTerm: string = String($evt);
    if (searchTerm.length > 2) {
      this.pageSearch['searchTerm'] = searchTerm;
      this.fetchRecords(this.pageSearch);
    } else {
      this.pageSearch['searchTerm'] = '';
      this.fetchRecords(this.pageSearch);
    }
  }

  view(id: string) {
    this.router.navigate(['/settings/rbac-settings/view', id]);
  }
}

import { Component, inject } from '@angular/core';
import { FirebaseAppModule } from '@angular/fire/app';
import { AppService } from '../../services/app.service';
import { Router } from '@angular/router';
import { delay, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment.development';
import { LoaderComponent } from '../../loader/loader.component';
import { AppEnums } from '../../models/app.enums';
import { IUser } from '../../models/user.interface';

@Component({
  standalone: true,
  imports: [FirebaseAppModule, LoaderComponent],
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  appService = inject(AppService);
  router = inject(Router);
  isLoading: boolean = false;
  join() {
    this.isLoading = true;
    this.appService.signInWithGoogle
      .pipe(
        delay(2000),
        switchMap((r) => this.getDatabaseUser())
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          let u: IUser = res.payload;
          this.appService.setUserSession(u);
          this.isLoading = false;
          if (u.tenantId) {
            //Go to dashboard
            this.router.navigate(['/dashboard']);
          } else {
            //Start onboarding process
            this.router.navigate(['/onboard']);
          }
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }
  getDatabaseUser() {
    return this.appService.makeGetRequest(
      `${environment.BASE_API_URL}/auth/login`
    );
  }
}

<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Register Collection</p>

    <button
      [routerLink]="['/collections']"
      [routerLinkActive]="['active']"
      class="btn btn-sm btn-link"
    >
      <fa-icon [icon]="faList" class="me-1"></fa-icon> All Collections
    </button>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  }
  <div class="my-4">
    <div class="m-3">
      <form class="row g-3" [formGroup]="addRecordForm" (submit)="submit()">
        <div class="col-md-4">
          <label for="payerId" class="form-label"
            >Received From<span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control"
            formControlName="payerId"
            placeholder="search members"
            [matAutocomplete]="member"
          />
          <mat-autocomplete
            #member="matAutocomplete"
            [displayWith]="displayMemberFn"
          >
            @for (memb of members ; track memb) {
            <mat-option [value]="memb"
              >{{ memb.name }} ({{ memb.memberNumber }})</mat-option
            >
            }
          </mat-autocomplete>
        </div>

        <div class="col-md-4">
          <label for="accountId" class="form-label"
            >Account<span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control"
            formControlName="accountId"
            placeholder="search accounts"
            [matAutocomplete]="acc"
          />
          <mat-autocomplete
            #acc="matAutocomplete"
            [displayWith]="displayAccountFn"
          >
            @for (acc of accounts ; track acc) {
            <mat-option [value]="acc"
              >{{ acc.name }} ({{ acc.type }})</mat-option
            >
            }
          </mat-autocomplete>
        </div>
        <div class="col-md-4">
          <label for="narration" class="form-label"
            >Narration <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control"
            formControlName="narration"
            id="narration"
            placeholder="e.g. June 2024 Contrib."
          />
        </div>
        <div class="col-md-3">
          <div class="mb-3">
            <label for="amount" class="form-label"
              >Amount <span class="text-danger">*</span></label
            >
            <input
              type="number"
              class="form-control"
              formControlName="amount"
              id="amount"
              placeholder="e.g. 12000"
              required
              autocomplete="off"
            />
          </div>
        </div>

        <div class="col-md-3">
          <label for="payMode" class="form-label"
            >Payment Mode <span class="text-danger">*</span></label
          >
          <select
            class="form-select"
            aria-label="Select payment mode"
            formControlName="payMode"
          >
            @for (paymode of paymodes; track $index) {
            <option [value]="paymode">{{ paymode }}</option>
            }
          </select>
        </div>
        <div class="col-md-3">
          <label for="reference" class="form-label"
            >Reference <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control"
            formControlName="reference"
            id="reference"
            placeholder="e.g. SH96H964D2"
          />
        </div>
        <div class="col-md-3">
          <label for="transactionDate" class="form-label"
            >Transaction Date<span class="text-danger">*</span></label
          >
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              formControlName="transactionDate"
              id="transactionDate"
              readonly
              [max]="maxDate"
              [matDatepicker]="transactionDatePicker"
            />
            <mat-datepicker #transactionDatePicker></mat-datepicker>

            <button
              class="btn btn-outline-dark"
              type="button"
              id="button-addon2"
              (click)="transactionDatePicker.open()"
            >
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>

        <div class="col-12 mt-4">
          <a
            routerLink="/collections"
            type="button"
            class="btn btn-sm btn-outline-dark me-2"
            >Cancel</a
          >
          <button type="submit" class="btn btn-sm btn-primary me-2">
            Submit Collection
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

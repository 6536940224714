import { Component } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { AppService } from '../../services/app.service';
import { IUser } from '../../models/user.interface';
import {
  faChevronRight,
  faFileEdit,
  faList,
  faAdd,
  faUserTimes,
  faUserCheck,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderComponent } from '../../loader/loader.component';
import { environment } from '../../../environments/environment.development';
import { AppEnums } from '../../models/app.enums';
import { RolePipe } from '../../pipes/role.pipe';
import {
  FormArray,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { IRole } from '../../models/role.interface';
import { IGroupingTag } from '../../models/grouping-tag.interface';
import { GroupTagPipe } from '../../pipes/group-tag.pipe';
import { IAttachmentType } from '../../models/attachment-type.interface';
import {
  IAttachment,
  IAttachmentResponse,
} from '../../models/attachment.interface';
import { HttpResponse } from '@angular/common/http';
import { switchMap } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    RolePipe,
    ReactiveFormsModule,
    GroupTagPipe,
  ],
  selector: 'app-members-details',
  templateUrl: './members-details.component.html',
  styleUrl: './members-details.component.css',
})
export class MembersDetailsComponent {
  isLoading: boolean = false;
  recordId!: string;
  record!: IUser;
  attachments: IAttachment[] = [];
  faChevronRight = faChevronRight;
  faFileEdit = faFileEdit;
  faUserTimes = faUserTimes;
  faUserCheck = faUserCheck;
  faTrash = faTrash;
  faList = faList;
  faAdd = faAdd;
  assignRoleForm: FormGroup;
  allRoles: IRole[] = [];
  selectedRoles: string[] = [];
  assignTagForm: FormGroup;
  allTags: IGroupingTag[] = [];
  selectedTags: string[] = [];
  attachmentTypes: IAttachmentType[] = [];
  aFile!: File;
  addDocumentForm: FormGroup;
  selectedAttachment!: IAttachment;
  constructor(
    public appService: AppService,
    private activeRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder
  ) {
    this.activeRoute.params.subscribe((p) => {
      this.recordId = p['id'];
      this.fetchRecord(this.recordId);
      this.loadAttachments(this.recordId);
    });

    this.assignTagForm = this.formBuilder.group({
      tagsFormArray: this.formBuilder.array([]),
    });

    this.addDocumentForm = this.formBuilder.group({
      file: ['', Validators.required],
      type: ['', Validators.required],
      id: ['', Validators.required],
    });

    this.appService.getTags.subscribe((tag) => {
      this.allTags = tag;
      this.allTags.forEach((aTag) => {
        this.tagsFormArray.push(
          this.formBuilder.group({
            tagId: [this.initializeTag(aTag)],
          })
        );
      });
    });

    this.tagsFormArray.valueChanges.subscribe((changes) => {
      this.selectedTags = [];
      for (let index = 0; index < changes.length; index++) {
        const element = changes[index];
        if (element.tagId === true) {
          this.selectedTags.push(this.allTags[index].id);
        }
      }
    });

    this.assignRoleForm = this.formBuilder.group({
      rolesFormArray: this.formBuilder.array([]),
    });

    this.appService.getRoles.subscribe((roles) => {
      this.allRoles = roles;
      this.allRoles.forEach((aRole) => {
        this.rolesFormArray.push(
          this.formBuilder.group({
            roleId: [this.initializeRole(aRole)],
          })
        );
      });
    });

    this.rolesFormArray.valueChanges.subscribe((changes: any) => {
      this.selectedRoles = [];
      for (let index = 0; index < changes.length; index++) {
        const element = changes[index];
        if (element.roleId === true) {
          this.selectedRoles.push(this.allRoles[index].id);
        }
      }
    });
    this.appService.getAttachmentTypes.subscribe((attachments) => {
      this.attachmentTypes = attachments;
    });
  }
  selectAttachment(attachment: IAttachment) {
    this.selectedAttachment = attachment;
  }

  submitDocuments() {
    if (!this.addDocumentForm.valid) {
    }
    let formData = new FormData();
    formData.append('file', this.aFile);
    formData.append(
      'attachmentTypeId',
      this.addDocumentForm.get('type')?.value
    );
    formData.append('recordId', this.recordId);
    formData.append('scope', 'PRIVATE');
    this.isLoading = true;
    this.appService
      .makeUploadRequest(`${environment.BASE_API_URL}/upload`, formData)
      .subscribe({
        complete: () => {},
        next: (event) => {
          if (event instanceof HttpResponse) {
            if (event.body) {
              let body: IAttachmentResponse = event.body;
              if (body.statusCode == 200) {
                let res: IAttachment = body.payload;
                this.isLoading = true;
                document.getElementById('closeeditDocumentsModal')?.click();
                this.loadAttachments(this.recordId);
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  '',
                  body.statusMessage
                );
              }
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeWarning,
                '',
                'Invalid response'
              );
            }
          }
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
  onFileSelected(e: any) {
    this.aFile = e.files[0];
  }
  updateUserDocuments(res: any) {
    return this.appService.makePutRequest(
      `${environment.BASE_API_URL}/users/update/${this.recordId}`,
      res
    );
  }

  getFileExtension(fileName: string): string {
    let arr = fileName.split('.');
    return arr[arr.length - 1];
  }
  initializeRole(role: IRole) {
    if (this.record?.roles) {
      return this.record.roles.includes(role.id);
    }
    return false;
  }

  initializeTag(tag: IGroupingTag) {
    if (this.record?.tags) {
      return this.record.tags.includes(tag.id);
    }
    return false;
  }
  get rolesFormArray(): FormArray {
    return this.assignRoleForm.get('rolesFormArray') as FormArray;
  }

  get tagsFormArray(): FormArray {
    return this.assignTagForm.get('tagsFormArray') as FormArray;
  }

  submitRoles() {
    this.updateMember({
      role: this.selectedRoles,
    });
  }

  submitTags() {
    this.updateMember({
      tags: this.selectedTags,
    });
  }
  submitDeleteDocument() {
    this.isLoading = true;
    this.appService
      .makeDeleteRequest(
        `${environment.BASE_API_URL}/attachments/${this.selectedAttachment.id}`
      )
      .pipe(switchMap((r) => this.loadAttachments$()))
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.attachments = res.payload;
          document.getElementById('closeDeleteAttachmentModal')?.click();
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  submitDisableAccount() {
    this.updateMember({
      enabled: !this.record.enabled,
    });
  }
  updateMember(body: any) {
    this.isLoading = true;
    this.appService
      .makePutRequest(
        `${environment.BASE_API_URL}/users/update/${this.recordId}`,
        body
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.record = res.payload;
          document.getElementById('closeeditDocumentsModal')?.click();
          document.getElementById('closeEditRoleModal')?.click();
          document.getElementById('closeEditTagsModal')?.click();
          document.getElementById('closeDisableAccountModal')?.click();
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
  loadAttachments(recordId: string) {
    this.isLoading = true;
    this.appService
      .makeGetRequest(`${environment.BASE_API_URL}/attachments/${recordId}`)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.attachments = res.payload;
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
  loadAttachments$() {
    return this.appService.makeGetRequest(
      `${environment.BASE_API_URL}/attachments/${this.recordId}`
    );
  }

  fetchRecord(recordId: string) {
    this.isLoading = true;
    this.appService
      .makeGetRequest(`${environment.BASE_API_URL}/users/get/${recordId}`)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.record = res.payload;
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
}

import { Component } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import {
  faChevronRight,
  faFileEdit,
  faTrash,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment.development';
import { AppEnums } from '../../../models/app.enums';
import { IRole } from '../../../models/role.interface';
import { AppService } from '../../../services/app.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderComponent } from '../../../loader/loader.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
  ],
  selector: 'app-rbac-view',
  templateUrl: './rbac-view.component.html',
  styleUrl: './rbac-view.component.css',
})
export class RbacViewComponent {
  isLoading: boolean = false;
  roleId!: string;
  role!: IRole;
  faChevronRight = faChevronRight;
  faFileEdit = faFileEdit;
  faTrash = faTrash;
  faList = faList;

  constructor(
    public appService: AppService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activeRoute.params.subscribe((p) => {
      this.roleId = p['id'];
      this.fetchRole(this.roleId);
    });
  }

  deleteRole() {
    this.isLoading = true;
    this.appService
      .makeDeleteRequest(
        `${environment.BASE_API_URL}/roles/delete/${this.roleId}`
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.router.navigate(['/settings/rbac-settings']);
        },
        next: (res) => {
          document.getElementById('del-btn-close')?.click();
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            res.statusMessage
          );
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  fetchRole(roleId: string) {
    this.isLoading = true;
    this.appService
      .makeGetRequest(`${environment.BASE_API_URL}/roles/get/${roleId}`)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.role = res.payload;
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
}

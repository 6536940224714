import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListPaginationComponent } from '../../list-pagination/list-pagination.component';
import { LoaderComponent } from '../../loader/loader.component';
import { SearchBoxComponent } from '../../search-box/search-box.component';
import {
  faUserPlus,
  faArrowRight,
  faCloudDownload,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import { IPageSearch } from '../../models/page-search.interface';
import { AppService } from '../../services/app.service';
import { IUser, IPaginatedUser } from '../../models/user.interface';
import { environment } from '../../../environments/environment.development';
import { AppEnums } from '../../models/app.enums';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { IGroupingTag } from '../../models/grouping-tag.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    SearchBoxComponent,
    ReactiveFormsModule,
    ListPaginationComponent,
    MatDatepickerModule,
  ],

  selector: 'app-members-list',
  templateUrl: './members-list.component.html',
  styleUrl: './members-list.component.css',
})
export class MembersListComponent {
  router = inject(Router);
  appService = inject(AppService);
  paginatatedRecord!: IPaginatedUser;
  isLoading: boolean = false;
  faUserPlus = faUserPlus;
  faArrowRight = faArrowRight;
  faCalendar = faCalendar;
  faCloudDownload = faCloudDownload;
  records: IUser[] = [];
  pageSearch: IPageSearch = {
    page: 0,
    pageSize: 15,
    searchTerm: '',
  };
  filterFormGroup: FormGroup;

  allTags: IGroupingTag[] = [
    {
      id: 'ALL',
      tag: 'ALL',
      tenantId: 'ALL',
    },
  ];
  constructor(private formBuilder: UntypedFormBuilder) {
    this.filterFormGroup = this.formBuilder.group({
      startDate: [new Date(), Validators.compose([Validators.required])],
      endDate: [new Date(), Validators.compose([Validators.required])],
      tag: ['ALL', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.fetchRecords(this.pageSearch);
    this.appService.getTags.subscribe((t) => {
      this.allTags = this.allTags.concat(t);
    });
  }

  fetchRecords(pageSearch: IPageSearch) {
    this.appService.clearErrorBag();
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/users/list`, pageSearch)
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.records = this.paginatatedRecord.content;
        },
        next: (res) => {
          this.paginatatedRecord = res.payload;
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }

  search($evt: any) {
    let searchTerm: string = String($evt);
    if (searchTerm.length > 2) {
      this.pageSearch['searchTerm'] = searchTerm;
      this.fetchRecords(this.pageSearch);
    } else {
      this.pageSearch['searchTerm'] = '';
      this.fetchRecords(this.pageSearch);
    }
  }

  view(id: string) {
    this.router.navigate(['/members/member', id]);
  }

  download() {
    let start: Date = this.filterFormGroup.get('startDate')?.value;
    let end: Date = this.filterFormGroup.get('endDate')?.value;
    let startDate = this.appService.getMySqlDate(start);
    let endDate = this.appService.getMySqlDate(end);
    let tagId = this.filterFormGroup.get('tag')?.value;
    let body = {
      startDate: startDate,
      endDate: endDate,
      tagId: tagId,
    };
    let mimeType = 'application/vnd.ms-excel';
    this.isLoading = true;
    this.appService
      .makeDownloadRequest(
        `${environment.BASE_API_URL}/users/download`,
        body,
        mimeType
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.appService.downloadReport(res, mimeType, 'Members_Report_');
          document.getElementById('closeDownloadReportModal')?.click();
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Data not found',
            'Change your parameters and try again'
          );
          this.isLoading = false;
        },
      });
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-default-comms',
  templateUrl: './default-comms.component.html',
  styleUrl: './default-comms.component.css'
})
export class DefaultCommsComponent {

}

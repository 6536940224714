<nav aria-label="Page navigation">
  <ul class="pagination justify-content-end">
    <li
      [ngClass]="paginatatedRecord.first ? 'page-item disabled' : 'page-item'"
    >
      <a
        class="page-link"
        href="javascript:void(0);"
        (click)="goToPreviousPage()"
        tabindex="-1"
        aria-disabled="true"
        >Previous</a
      >
    </li>

    <li [ngClass]="paginatatedRecord.last ? 'page-item disabled' : 'page-item'">
      <a class="page-link" href="javascript:void(0);" (click)="goToNextPage()"
        >Next</a
      >
    </li>
  </ul>
</nav>

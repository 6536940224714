<div class="container">
  @if(smsSetting) {
  <div class="card text-bg-light mb-3">
    <div class="card-body text-center">
      <app-loader *ngIf="isLoading"></app-loader>

      <h3 class="card-text">
        <small class="d-block text-muted fw-lighter">SMS Account Balance</small>
        {{
          smsSetting.smsAccountBalance
            | currency : smsSetting.smsAccountCurrency + " "
        }}
      </h3>
      <div>
        <button
          type="button"
          class="btn btn-outline-dark m-1"
          (click)="loadSmsSettings()"
        >
          <fa-icon [icon]="faRefresh" class="me-1"></fa-icon> Refresh
        </button>
        <button
          type="button"
          class="btn btn-outline-dark m-1"
          data-bs-toggle="modal"
          data-bs-target="#buyCreditModal"
        >
          <fa-icon [icon]="faCartShopping" class="me-1"></fa-icon> Buy Credit
        </button>

        <div
          class="modal fade"
          id="buyCreditModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="buyCreditModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="buyCreditModalLabel">
                  Buy SMS Credit
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  id="credit-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center">
                      @if (isLoading) {
                      <div class="my-4 d-block">
                        <app-loader></app-loader>
                      </div>
                      }
                    </div>
                    <form [formGroup]="buyCreditForm" (submit)="buyCredit()">
                      <div class="row">
                        <div class="col-md-4">
                          <label for="amount" class="form-label"
                            >Top-up Amount
                            <span class="text-danger">*</span></label
                          >
                          <input
                            type="number"
                            class="form-control"
                            formControlName="amount"
                            id="amount"
                            placeholder="e.g. 15000"
                            min="100"
                            max="150000"
                          />
                          <div *ngIf="buyCreditFormControl['amount'].touched">
                            <small
                              class="form-text text-danger"
                              *ngIf="buyCreditFormControl['amount'].errors?.['min']"
                            >
                              Minimum
                              {{ "100" | currency : "KES " }}
                            </small>

                            <small
                              class="form-text text-danger"
                              *ngIf="buyCreditFormControl['amount'].errors?.['max']"
                            >
                              Maximum
                              {{ "150000" | currency : "KES " }}
                            </small>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label for="payMode" class="form-label"
                            >Payment Method <span class="text-danger">*</span>
                          </label>
                          <select
                            class="form-select"
                            formControlName="payMode"
                            aria-label="Payment Method"
                          >
                            <option
                              *ngFor="let payMode of payModes"
                              [value]="payMode.id"
                            >
                              {{ payMode.name }}
                            </option>
                          </select>
                        </div>
                        <div class="col-md-4">
                          <label for="btn" class="form-label text-white"
                            >Submit Request
                          </label>
                          <div class="d-grid gap-2">
                            <button type="submit" class="btn btn-primary">
                              Buy Credit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div *ngIf="paymentUrl">
                      <iframe
                        [src]="paymentUrl"
                        frameborder="0"
                        allowfullscreen
                        style="width: 100%"
                        height="500"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  @if(smsSetting.freeAccount){

  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Own your SMS brand</h5>
      <p class="card-text">
        Let your members receive SMS from your brand name instead of the generic
        name SAKKO. <a href="#">Learn more</a>
      </p>
      <button type="button" class="btn btn-primary">Sign me up</button>
    </div>
  </div>
  } } @else {

  <h5 class="my-5 text-center">
    <small class="d-block small text-muted my-3"
      >Unlock streamlined communication and engagement with your members by
      leveraging our bulk SMS service, enhancing outreach, and fostering
      stronger community connections for your
      <span class="fst-italic text-dark"> Chama. </span></small
    >
  </h5>

  <p class="my-5 text-center fw-bold fst-italic">
    This service is powered by
    <a href="https://corporatesms.co.ke/" target="_blank">CorporateSMS</a>
  </p>
  <div class="text-center">
    @if (isLoading) {
    <div class="my-4 d-block">
      <app-loader></app-loader>
    </div>
    } @if(!isLoading) {
    <button class="btn btn-primary" (click)="activateSms()">
      Activate SMS
    </button>
    }
  </div>

  }
</div>

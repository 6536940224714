import { Component } from '@angular/core';

@Component({
  selector: 'app-loans-update',
  templateUrl: './loans-update.component.html',
  styleUrl: './loans-update.component.css'
})
export class LoansUpdateComponent {

}

import { Pipe, PipeTransform, inject } from '@angular/core';
import { IRole } from '../models/role.interface';
import { AppService } from '../services/app.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'role',
  standalone: true,
})
export class RolePipe implements PipeTransform {
  appService = inject(AppService);
  transform(roleId: unknown): Observable<IRole> {
    return this.appService.getRoles.pipe(
      map((roles) => {
        return roles.filter((r) => {
          return r.id === roleId;
        })[0];
      })
    );
  }
}

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { LoaderComponent } from '../../../loader/loader.component';
import { AppService } from '../../../services/app.service';
import { environment } from '../../../../environments/environment.development';
import { AppEnums } from '../../../models/app.enums';
import { ISmsSetting } from '../../../models/sms-setting.interface';
import { faCartShopping, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { IPaymentMethod } from '../../../models/payment-method.interface';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LoaderComponent,
    RouterLink,
    RouterLinkActive,
    FontAwesomeModule,
    ReactiveFormsModule,
  ],
  selector: 'app-sms-setup',
  templateUrl: './sms-setup.component.html',
  styleUrl: './sms-setup.component.css',
})
export class SmsSetupComponent implements OnInit {
  isLoading: boolean = false;
  smsSetting!: ISmsSetting;
  faCartShopping = faCartShopping;
  faRefresh = faRefresh;
  buyCreditForm: FormGroup;
  paymentUrl!: any;
  payModes: IPaymentMethod[] = [
    { id: 'MPESA', name: 'M-Pesa' },
    { id: 'CARD', name: 'Credit/Debit Card' },
  ];
  constructor(
    private appService: AppService,
    private formBuilder: UntypedFormBuilder,
    private sanitizer: DomSanitizer
  ) {
    this.buyCreditForm = this.formBuilder.group({
      amount: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^\d+$/),
          Validators.min(100),
          Validators.max(150000),
        ]),
      ],
      payMode: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.loadSmsSettings();
  }

  buyCredit() {
    this.paymentUrl = '';
    if (!this.buyCreditForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }

    this.isLoading = true;
    let data = {
      payMode: this.buyCreditForm.get('payMode')?.value,
      amount: this.buyCreditForm.get('amount')?.value,
      smsAccountId: this.smsSetting.smsAccountId,
    };
    this.appService
      .makePostRequest(
        `${environment.BASE_API_URL}/sms-settings/buy-credit`,
        data
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.paymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            res.payload
          );
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  get buyCreditFormControl() {
    return this.buyCreditForm.controls;
  }
  loadSmsSettings() {
    this.isLoading = true;
    this.appService
      .makeGetRequest(`${environment.BASE_API_URL}/sms-settings/status`)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.isLoading = false;
          this.smsSetting = res.payload;
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeInfo,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
  activateSms() {
    this.isLoading = true;
    this.appService
      .makeGetRequest(`${environment.BASE_API_URL}/sms-settings/activate`)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.isLoading = false;
          this.loadSmsSettings();
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
}

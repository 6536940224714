<form class="form" [formGroup]="formGroup" (submit)="submitSearch()">
  <div class="input-group mt-3">
    <input
      type="text"
      class="form-control"
      [placeholder]="seacrhBoxPlaceHolder"
      aria-label="Search"
      aria-describedby="button-search"
      formControlName="searchTerm"
    />
    @if (showClearBtn) {
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="clearSearch()"
    >
      <fa-icon [icon]="faRemove"></fa-icon> Clear
    </button>
    }

    <button class="btn btn-outline-primary" type="submit" id="button-search">
      <fa-icon [icon]="faSearch"></fa-icon> Search
    </button>
  </div>
</form>

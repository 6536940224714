import { Injectable, inject, signal } from '@angular/core';
import {
  Auth,
  confirmPasswordReset,
  GoogleAuthProvider,
  signInWithPopup,
  updatePassword,
  user,
} from '@angular/fire/auth';
import { Observable, catchError, from, map, of, shareReplay } from 'rxjs';
import { IUser } from '../models/user.interface';
import { Router } from '@angular/router';
import { IApiResponse } from '../models/api-response.interface';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { IAlertMessage } from '../models/alert-message.interface';
import { ToastrService } from 'ngx-toastr';
import { AppEnums } from '../models/app.enums';
import { environment } from '../../environments/environment.development';
import { IPermission } from '../models/permission.interface';
import { ITenant } from '../models/tenant.interface';
import { ICountry } from '../models/country.interface';
import { IGroupingTag } from '../models/grouping-tag.interface';
import { IGender } from '../models/gender.interface';
import { IRole } from '../models/role.interface';
import { IAttachmentType } from '../models/attachment-type.interface';
import { IPeriodicity } from '../models/periodicity.interface';
import { IKeyValue } from '../models/key-value.interface';
import { ICollateralType } from '../models/collateral-type.interface';
const CACHE_SIZE = 1;
@Injectable({
  providedIn: 'root',
})
export class AppService {
  downloadReport(data: any, mimeType: string, fileName: string) {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(
      new Blob([data], {
        type: mimeType,
      })
    );
    a.setAttribute('target', '_blank');
    a.setAttribute('download', this.buildFileName(fileName, '.xlsx'));
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  getMySqlDate(det: Date): string {
    let y = det.getFullYear();
    let m = det.getMonth() + 1;
    let d = det.getDate();
    let mStr = String(m).trim();
    if (mStr.length === 1) {
      mStr = `0${m}`;
    }
    let dStr = String(d).trim();
    if (dStr.length === 1) {
      dStr = `0${d}`;
    }
    return `${y}-${mStr}-${dStr}`;
  }
  buildFileName(fileName: string, extension: string): string {
    let tStamp = new Date();
    let y = tStamp.getFullYear();
    let m = this.prefixWithZero(tStamp.getMonth() + 1);
    let d = this.prefixWithZero(tStamp.getDate());
    let h = this.prefixWithZero(tStamp.getHours());
    let mins = this.prefixWithZero(tStamp.getMinutes());
    let s = this.prefixWithZero(tStamp.getMinutes());
    return `${fileName.trim()}${y}${m}${d}_${h}${mins}${s}${extension.trim()}`;
  }
  prefixWithZero(num: number) {
    if (String(num).length == 1) {
      return `0${num}`;
    }
    return num;
  }
  get getFirstName() {
    if (this.getUserSession()) {
      return this.getUserSession().name.split(' ')[0];
    }
  }
  setUserSession(user: IUser) {
    let u = this.getUserSession();
    let u2 = { ...u, ...user };
    sessionStorage.setItem('user', JSON.stringify(u2));
  }

  sanitizeNumber(input: string): number {
    if (typeof input === 'number') {
      return input;
    }
    const cleanedString = input.replace(/[\s,]/g, '');
    const result = Number(cleanedString);
    if (isNaN(result)) {
      throw new Error('Invalid number format');
    }
    return result;
  }

  getUserSession() {
    let u = sessionStorage.getItem('user');
    if (u) {
      return JSON.parse(u);
    }
    return {};
  }
  get getTermDescriptions(): Observable<IKeyValue[]> {
    let r: IKeyValue[] = [
      { id: 'YEARS', name: 'Year(s)' },
      { id: 'MONTHS', name: 'Month(s)' },
      { id: 'WEEKS', name: 'Week(s)' },
      { id: 'DAYS', name: 'Day(s)' },
    ];
    return of(r);
  }
  constructor(private httpClient: HttpClient, private toastr: ToastrService) {}
  private permissionsCache$!: Observable<IPermission[]>;
  private rolesCache$!: Observable<IRole[]>;
  private countriesCache$!: Observable<ICountry[]>;
  private gendersCache$!: Observable<IGender[]>;
  private collateralTypeCache$!: Observable<ICollateralType[]>;
  private tagsCache$!: Observable<IGroupingTag[]>;
  private attachmentTypeCache$!: Observable<IAttachmentType[]>;
  private tenantCache$!: Observable<ITenant>;
  private accountTypeCache$!: Observable<string[]>;
  firebaseAuth = inject(Auth);
  router = inject(Router);
  user$ = user(this.firebaseAuth).pipe(
    //TODO Find a way to remove this here
    map((u) => {
      u?.getIdToken(true).then((n) => {
        // this.idTokenSig.set(n);
      });
      return u;
    })
  );
  currentuserSig = signal<IUser | null | undefined>(undefined);
  //idTokenSig = signal<string | null | undefined>(undefined);
  alertMessageBagSig = signal<IAlertMessage | null | undefined>(undefined);

  clearErrorBag() {
    this.alertMessageBagSig.set(null);
  }

  makeUploadRequest(
    fullUrl: string,
    formData: FormData
  ): Observable<HttpEvent<IApiResponse>> {
    const req = new HttpRequest('POST', fullUrl, formData, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.getIdToken}`,
      }),
      reportProgress: true,
      responseType: 'json',
    });

    return this.httpClient.request(req);
  }

  makeDownloadRequest(
    fullUrl: string,
    data: any,
    acceptMimeType: string
  ): Observable<Blob> {
    const hds = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: acceptMimeType,
      Authorization: `Bearer ${this.getIdToken}`,
    });
    return this.httpClient.post<Blob>(fullUrl, data, {
      headers: hds,
      responseType: 'blob' as 'json',
    });
  }

  get getTenant(): Observable<ITenant> {
    if (!this.tenantCache$) {
      this.tenantCache$ = this.makeGetRequest(
        `${environment.BASE_API_URL}/tenants/tenant`
      ).pipe(map((r) => r.payload));
    }
    return this.tenantCache$;
  }

  getBearerToken() {
    this.user$.subscribe((u) => {
      u?.getIdToken(true).then((n) => {
        //this.idTokenSig.set(n);
        sessionStorage.setItem('idToken', n);
      });
    });
  }

  get getIdToken() {
    return sessionStorage.getItem('idToken');
  }

  logout() {
    this.firebaseAuth.signOut();
    this.currentuserSig.set(null);
    this.router.navigate(['/home']);
  }

  changePassword(oobCode: string, password: string) {
    return confirmPasswordReset(this.firebaseAuth, oobCode, password);
  }
  verifyPasswordResetCode(
    mode: string,
    oobCode: string,
    continueUrl: string,
    lang: string
  ) {
    let operation;
    switch (mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        operation = handleResetPassword(
          this.firebaseAuth,
          oobCode,
          continueUrl,
          lang
        );
        break;
      case 'recoverEmail':
        // Display email recovery handler and UI.
        operation = handleRecoverEmail(this.firebaseAuth, oobCode, lang);
        break;
      case 'verifyEmail':
        // Display email verification handler and UI.
        operation = handleVerifyEmail(
          this.firebaseAuth,
          oobCode,
          continueUrl,
          lang
        );
        break;
      default:
      // Error: invalid mode.
    }
    return operation;
  }
  get signInWithGoogle() {
    const promise = signInWithPopup(
      this.firebaseAuth,
      new GoogleAuthProvider()
    ).then((response) => {
      this.getBearerToken();
      return response;
    });
    return from(promise);
  }

  get fetchTags(): Observable<IGroupingTag[]> {
    return this.makeGetRequest(`${environment.BASE_API_URL}/tags`).pipe(
      map((res) => {
        let tags: IGroupingTag[] = res.payload;
        return tags.sort((a, b) => {
          return a.tag.localeCompare(b.tag);
        });
      })
    );
  }

  get getTags(): Observable<IGroupingTag[]> {
    if (!this.tagsCache$) {
      this.tagsCache$ = this.fetchTags
        .pipe(
          catchError((e) => {
            return [];
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.tagsCache$;
  }

  get fetchAttachmentTypes(): Observable<IAttachmentType[]> {
    return this.makeGetRequest(
      `${environment.BASE_API_URL}/attachment-types`
    ).pipe(
      map((res) => {
        let attachmentTypes: IAttachmentType[] = res.payload;
        return attachmentTypes.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      })
    );
  }
  get getAllPaymodes(): Observable<string[]> {
    return this.makeGetRequest(`${environment.BASE_API_URL}/pay-modes`).pipe(
      map((res) => {
        let paymodes: string[] = res.payload;
        return paymodes.sort((a, b) => {
          return a.localeCompare(b);
        });
      })
    );
  }

  get getAllPeriodicities(): Observable<IPeriodicity[]> {
    return this.makeGetRequest(`${environment.BASE_API_URL}/periodicity`).pipe(
      map((res) => {
        let periodicities: IPeriodicity[] = res.payload;
        return periodicities.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      })
    );
  }

  fetchPeriodicity(): Observable<IPeriodicity[]> {
    return this.makePostRequest(
      `${environment.BASE_API_URL}/periodicity/list`,
      {}
    ).pipe(
      map((res) => {
        let periodicities: IPeriodicity[] = res.payload;
        return periodicities.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      })
    );
  }

  get getAttachmentTypes(): Observable<IAttachmentType[]> {
    if (!this.attachmentTypeCache$) {
      this.attachmentTypeCache$ = this.fetchAttachmentTypes
        .pipe(
          catchError((e) => {
            return [];
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.attachmentTypeCache$;
  }
  get getAccountTypes(): Observable<string[]> {
    if (!this.accountTypeCache$) {
      this.accountTypeCache$ = this.fetchAccountTypes
        .pipe(
          catchError((e) => {
            return [];
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.accountTypeCache$;
  }

  get fetchAccountTypes(): Observable<string[]> {
    return this.makeGetRequest(
      `${environment.BASE_API_URL}/accounts/types`
    ).pipe(
      map((res) => {
        let t: string[] = res.payload;
        return t.sort((a, b) => {
          return a.localeCompare(b);
        });
      })
    );
  }

  get getCollateraltypes(): Observable<ICollateralType[]> {
    if (!this.collateralTypeCache$) {
      this.collateralTypeCache$ = this.makeGetRequest(
        `${environment.BASE_API_URL}/collateral-types/all`
      )
        .pipe(
          map((res) => {
            let collateralTypes: ICollateralType[] = res.payload;
            return collateralTypes.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
          })
        )
        .pipe(
          catchError((e) => {
            return [];
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.collateralTypeCache$;
  }

  get getGenders(): Observable<IGender[]> {
    if (!this.gendersCache$) {
      this.gendersCache$ = this.makeGetRequest(
        `${environment.BASE_API_URL}/genders/all`
      )
        .pipe(
          map((res) => {
            let genders: IGender[] = res.payload;
            return genders.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
          })
        )
        .pipe(
          catchError((e) => {
            return [];
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.gendersCache$;
  }

  get getCountries(): Observable<ICountry[]> {
    if (!this.countriesCache$) {
      this.countriesCache$ = this.makeGetRequest(
        `${environment.BASE_API_URL}/countries/all`
      )
        .pipe(
          map((res) => {
            let countries: ICountry[] = res.payload;
            return countries.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
          })
        )
        .pipe(
          catchError((e) => {
            return [];
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.countriesCache$;
  }
  get getRoles(): Observable<IRole[]> {
    if (!this.rolesCache$) {
      this.rolesCache$ = this.makeGetRequest(
        `${environment.BASE_API_URL}/roles/all`
      )
        .pipe(
          map((res) => {
            return res.payload;
          })
        )
        .pipe(
          catchError((e) => {
            return [];
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.rolesCache$;
  }

  get getPermissions(): Observable<IPermission[]> {
    if (!this.permissionsCache$) {
      this.permissionsCache$ = this.makeGetRequest(
        `${environment.BASE_API_URL}/permissions/all`
      )
        .pipe(
          map((res) => {
            return res.payload;
          })
        )
        .pipe(
          catchError((e) => {
            return [];
          })
        )
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.permissionsCache$;
  }
  makePostRequest(fullUrl: string, data: any): Observable<IApiResponse> {
    return this.httpClient.post<IApiResponse>(fullUrl, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${this.getIdToken}`,
      },
    });
  }

  makePutRequest(fullUrl: string, data: any): Observable<IApiResponse> {
    return this.httpClient.put<IApiResponse>(fullUrl, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${this.getIdToken}`,
      },
    });
  }
  makeGetRequest(fullUrl: string): Observable<IApiResponse> {
    return this.httpClient.get<IApiResponse>(fullUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${this.getIdToken}`,
      },
    });
  }

  makeDeleteRequest(fullUrl: string): Observable<IApiResponse> {
    return this.httpClient.delete<IApiResponse>(fullUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${this.getIdToken}`,
      },
    });
  }
  processHttpErrors(err: any) {
    if (err.code) {
      if (
        err.code === 'auth/wrong-password' ||
        err.code === 'auth/user-not-found' ||
        err.code === 'auth/invalid-login-credentials'
      ) {
        return 'Invalid email or password';
      }

      if (err.code === 'auth/too-many-requests') {
        return 'Too many failed attempts. Try again later';
      }
    } else {
      let e = err.error;

      if (e.hasOwnProperty('statusMessage')) {
        if (
          String(e.statusMessage).startsWith(
            'Firebase ID token has expired.'
          ) ||
          String(e.statusMessage).startsWith(
            'Token expired. Generate a fresh one'
          ) /* ||
          String(e.statusMessage).startsWith('Invalid bearer token.') */
        ) {
          this.logout();
          return 'Your session has expired. Login afresh.';
        }
        return e.statusMessage;
      } else {
        if (e?.code) {
          return `Status (${e?.code}) : ${e?.message} ${e.error}`;
        } else {
          return `Status (${err.status}) : ${err.statusText}`;
        }
      }
    }
  }

  showToastMessage(
    alertType: AppEnums,
    alertTitle: string,
    alertMessage: string
  ) {
    switch (alertType) {
      case AppEnums.ToastTypeSuccess:
        return this.toastr.success(alertMessage, alertTitle);
        break;
      case AppEnums.ToastTypeInfo:
        return this.toastr.info(alertMessage, alertTitle);
        break;
      case AppEnums.ToastTypeWarning:
        return this.toastr.warning(alertMessage, alertTitle);
        break;
      case AppEnums.ToastTypeError:
        return this.toastr.error(alertMessage, alertTitle);
        break;
      default:
        return this.toastr.info(alertMessage, alertTitle);
        break;
    }
  }
}
function handleResetPassword(
  auth: any,
  actionCode: any,
  continueUrl: any,
  lang: any
) {
  throw new Error('Function not implemented.');
}

function handleRecoverEmail(auth: any, actionCode: any, lang: any) {
  throw new Error('Function not implemented.');
}

function handleVerifyEmail(
  auth: any,
  actionCode: any,
  continueUrl: any,
  lang: any
) {
  throw new Error('Function not implemented.');
}

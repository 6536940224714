<div class="container-fluid">
  @if(isLoading){
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  } @if(record) {
  <div class="d-flex justify-content-between">
    <p class="h4">
      <span class="text-muted">Collection detail ></span> {{ record.reference }}
    </p>

    <div>
      <button
        [routerLink]="['/collections']"
        [routerLinkActive]="['active']"
        class="btn btn-sm btn-link"
      >
        <fa-icon [icon]="faList" class="me-1"></fa-icon> All Collections
      </button>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-4">
      <p class="fw-bold">
        <small class="text-muted d-block">Transaction Date</small>
        {{ record.transactionDate | date : "mediumDate" }}
      </p>
    </div>
    <div class="col-md-4">
      <p class="fw-bold">
        <small class="text-muted d-block">Amount</small>
        {{ record.amount | currency : " " }}
      </p>
    </div>
    <div class="col-md-4">
      <p class="fw-bold">
        <small class="text-muted d-block">Reference</small>
        {{ record.reference }}
      </p>
    </div>
    <div class="col-md-4">
      <p class="fw-bold">
        <small class="text-muted d-block">Account</small>
        {{ record.account.name }}
      </p>
    </div>
    <div class="col-md-4">
      <p class="fw-bold">
        <small class="text-muted d-block">Payment Mode</small>
        {{ record.payMode }}
      </p>
    </div>
    <div class="col-md-4">
      <p class="fw-bold">
        <small class="text-muted d-block">Received from</small>
        {{ record.payer.name }}
      </p>
    </div>

    <div class="col-md-4">
      <p class="fw-bold">
        <small class="text-muted d-block">Recorded By</small>
        {{ record.poster.name }}
      </p>
    </div>
    <div class="col-md-12">
      <p class="fw-bold">
        <small class="text-muted d-block">Narration</small>
        {{ record.narration }}
      </p>
    </div>

    <div class="col-md-4">
      <p class="fw-bold">
        <small class="text-muted d-block">Posted</small>
        {{ record.createdAt | date : "medium" }}
      </p>
    </div>
    <div class="col-md-4">
      <p class="fw-bold">
        <small class="text-muted d-block">Last Update</small>
        {{ record.updatedAt | date : "medium" }}
      </p>
    </div>
    @if (record.amount > 0) {
    <div class="col-md-12">
      <p class="fw-bold mt-3">
        <button
          class="btn btn-sm btn-outline-dark"
          data-bs-toggle="modal"
          data-bs-target="#reverseTransactionModal"
        >
          <fa-icon [icon]="faUndo" class="me-1"></fa-icon>
          Reverse Transactionxx
        </button>
      </p>
    </div>
    }
  </div>

  <div
    class="modal fade"
    id="reverseTransactionModal"
    tabindex="-1"
    aria-labelledby="reverseTransactionModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="reverseTransactionModalLabel">
            Confirm Transaction Reversal
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            class="row g-3"
            [formGroup]="addRecordForm"
            (submit)="reverseTransaction()"
          >
            <div class="col-md-12">
              <label for="narration" class="form-label"
                >Why do you want to reverse this transaction?
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="narration"
                id="narration"
                placeholder="e.g. Entered wrong amount"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="closeReverseTransactionModal"
            class="btn btn-default"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="reverseTransaction()"
            class="btn btn-dark"
          >
            Reverse Transaction
          </button>
        </div>
      </div>
    </div>
  </div>
  }
</div>

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderComponent } from '../../../loader/loader.component';
import {
  faChevronRight,
  faFileEdit,
  faTrash,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { ILoanType } from '../../../models/loan-type.interface';
import { AppService } from '../../../services/app.service';
import { environment } from '../../../../environments/environment.development';
import { AppEnums } from '../../../models/app.enums';
import { IKeyValue } from '../../../models/key-value.interface';
import { IPeriodicity } from '../../../models/periodicity.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    ReactiveFormsModule,
  ],
  selector: 'app-loan-type-update',
  templateUrl: './loan-type-update.component.html',
  styleUrl: './loan-type-update.component.css',
})
export class LoanTypeUpdateComponent implements OnInit {
  isLoading: boolean = false;
  recordId!: string;
  record!: ILoanType;
  faChevronRight = faChevronRight;
  faFileEdit = faFileEdit;
  faTrash = faTrash;
  faList = faList;
  addRecordForm: FormGroup;
  termDescriptions: IKeyValue[] = [];
  periodicities: IPeriodicity[] = [];

  constructor(
    public appService: AppService,
    private activeRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.activeRoute.params.subscribe((p) => {
      this.recordId = p['id'];
      this.fetchRecord(this.recordId);
    });
    this.addRecordForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required])],

      interestRate: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(100),
        ]),
      ],
      term: ['', Validators.compose([Validators.required, Validators.min(1)])],
      termDesc: ['', Validators.compose([Validators.required])],
      periodicityId: ['', Validators.compose([Validators.required])],
      maxAmountMultiplier: [
        '',
        Validators.compose([Validators.required, Validators.min(1)]),
      ],
    });

    this.appService.getTermDescriptions.subscribe((res) => {
      this.termDescriptions = res;
    });
    this.appService.getAllPeriodicities.subscribe((res) => {
      this.periodicities = res;
    });
  }
  ngOnInit(): void {}

  fetchRecord(recordId: string) {
    this.isLoading = true;
    this.appService
      .makeGetRequest(`${environment.BASE_API_URL}/loan-types/${recordId}`)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.record = res.payload;
          let data = this.record;
          data['periodicityId'] = this.record.periodicity.id;
          data['interestRate'] = this.record.interestRate * 100;
          this.addRecordForm.patchValue(data);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  submit() {
    if (!this.addRecordForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }

    let data = this.addRecordForm.value;
    this.isLoading = true;
    this.appService
      .makePutRequest(
        `${environment.BASE_API_URL}/loan-types/${this.recordId}`,
        data
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            res.statusMessage
          );
          this.addRecordForm.reset();
          let record: ILoanType = res.payload;
          this.router.navigate(['/settings/loan-types/details', record.id]);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderComponent } from '../../loader/loader.component';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { AppService } from '../../services/app.service';
import { AppEnums } from '../../models/app.enums';
import { ICollateralType } from '../../models/collateral-type.interface';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IPaginatedUser, IUser } from '../../models/user.interface';
import { environment } from '../../../environments/environment.development';
import { IPageSearch } from '../../models/page-search.interface';
import { HttpResponse } from '@angular/common/http';
import { IApiResponse } from '../../models/api-response.interface';
import { IFileObject } from '../../models/attachment.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    LoaderComponent,
    ReactiveFormsModule,
    MatAutocompleteModule,
  ],
  selector: 'app-loan-guarantee-create',
  templateUrl: './loan-guarantee-create.component.html',
  styleUrl: './loan-guarantee-create.component.css',
})
export class LoanGuaranteeCreateComponent implements OnInit {
  isLoading: boolean = false;
  @Input() loanId!: string;
  addGuaranteeForm: FormGroup;
  collateralTypes: ICollateralType[] = [];
  collateralType!: ICollateralType;
  aFile!: File;
  members: IUser[] = [];
  paginatatedUser!: IPaginatedUser;
  @Output() reloadRecordEvent = new EventEmitter<string>();

  constructor(
    public appService: AppService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.addGuaranteeForm = this.formBuilder.group({
      value: ['', Validators.compose([Validators.required, Validators.min(1)])],
      collateralTypeId: ['', Validators.compose([Validators.required])],
      loanId: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required])],
      uid: [''],
      name: [''],
      guarantor: [''],
      link: [''],
      file: [''],
    });
    this.addGuaranteeForm
      .get('guarantor')
      ?.valueChanges.subscribe((val: string) => {
        if (val) {
          if (val.length > 3) {
            this.fetchMembers({
              page: 0,
              pageSize: 15,
              searchTerm: val.trim(),
            });
          }
        }
      });

    this.addGuaranteeForm
      .get('collateralTypeId')
      ?.valueChanges.subscribe((val: string) => {
        let v = this.collateralTypes.find((f) => {
          return f.id === val;
        });
        if (v) {
          this.collateralType = v;
        }
      });
  }
  ngOnInit(): void {
    this.fetchCollateralTypes();
    this.addGuaranteeForm.patchValue({ loanId: this.loanId });
  }

  submitGuarantee() {
    if (!this.addGuaranteeForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }
    let uid = '';
    let name = '';
    let link = this.addGuaranteeForm.get('link')?.value;
    let guarantor: IUser = this.addGuaranteeForm.get('guarantor')?.value;
    if (guarantor?.id) {
      uid = guarantor.id;
      name = guarantor.name;
      link = '';
    } else {
      uid = this.collateralType.uid;
      name = this.collateralType.name;
    }

    let data = {
      collateralTypeId: this.addGuaranteeForm.get('collateralTypeId')?.value,
      loanId: this.addGuaranteeForm.get('loanId')?.value,
      uid: uid,
      name: name,
      description: this.addGuaranteeForm.get('description')?.value,
      value: this.appService.sanitizeNumber(
        this.addGuaranteeForm.get('value')?.value
      ),
      link: link,
    };
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/loans/collateral`, data)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          document.getElementById('closeAddGuaranteeModal')?.click();
          this.reloadRecordEvent.emit(this.loanId);
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }

  onFileSelected(e: any) {
    this.aFile = e.files[0];
    if (this.aFile == undefined) {
      return;
    }
    let formData = new FormData();
    formData.append('attachmentTypeId', this.collateralType.uid);
    formData.append('recordId', this.loanId + '');
    formData.append('scope', 'PRIVATE');
    formData.append('generic', 'generic'); // Add to return fileObject
    formData.append('file', this.aFile);
    this.isLoading = true;
    this.appService
      .makeUploadRequest(`${environment.BASE_API_URL}/upload`, formData)
      .subscribe({
        complete: () => {},
        next: (event) => {
          if (event instanceof HttpResponse) {
            if (event.body) {
              let body: IApiResponse = event.body;
              if (body.statusCode == 200) {
                let payload: IFileObject = body.payload;
                this.addGuaranteeForm.patchValue({
                  link: payload.url,
                });
                console.log('payload.url', payload.url);
                this.isLoading = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  '',
                  body.statusMessage
                );
                this.isLoading = false;
              }
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeWarning,
                '',
                'Invalid response'
              );
              this.isLoading = false;
            }
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  displayMemberFn(member: IUser): string {
    return member && member.id ? `${member.name} (${member.memberNumber})` : '';
  }

  fetchMembers(pageSearch: IPageSearch) {
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/users/list`, pageSearch)
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.members = this.paginatatedUser.content;
        },
        next: (res) => {
          this.paginatatedUser = res.payload;
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }
  fetchCollateralTypes() {
    this.isLoading = true;
    this.appService.getCollateraltypes.subscribe({
      complete: () => {
        this.isLoading = false;
      },
      next: (collateralTypes) => {
        this.collateralTypes = collateralTypes;
        this.collateralType = collateralTypes[0];
      },
      error: (err) => {
        this.isLoading = false;
        this.appService.showToastMessage(
          AppEnums.ToastTypeWarning,
          '',
          this.appService.processHttpErrors(err)
        );
      },
    });
  }
}

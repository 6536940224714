import { Component } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { faList, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../environments/environment.development';
import { AppEnums } from '../../models/app.enums';
import { IGender } from '../../models/gender.interface';
import { IUser } from '../../models/user.interface';
import { AppService } from '../../services/app.service';
import { InputValidationService } from '../../services/input-validation.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderComponent } from '../../loader/loader.component';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    ReactiveFormsModule,

    MatDatepickerModule,
  ],
  selector: 'app-members-update',
  templateUrl: './members-update.component.html',
  styleUrl: './members-update.component.css',
})
export class MembersUpdateComponent {
  maxDateOfBirth = new Date();
  isLoading: boolean = false;
  faList = faList;
  faCalendar = faCalendar;
  genders: IGender[] = [];
  recordId!: string;
  record!: IUser;
  addRecordForm: FormGroup;
  constructor(
    public appService: AppService,
    private formBuilder: UntypedFormBuilder,
    private inputValidationService: InputValidationService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    this.activeRoute.params.subscribe((p) => {
      this.recordId = p['id'];
      this.fetchRecord(this.recordId);
    });
    this.addRecordForm = this.formBuilder.group({
      memberNumber: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(10)]),
      ],
      name: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.maxLength(50),
        ]),
      ],
      phone: [
        '',
        Validators.compose([
          Validators.required,
          this.inputValidationService.phoneNumberValidator(),
        ]),
      ],
      gender: ['', Validators.compose([Validators.required])],
      idNumber: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(10)]),
      ],
      taxPin: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(12)]),
      ],
      dob: ['', Validators.compose([Validators.required])],
    });

    this.loadGenders();
  }

  get addRecordFormControl() {
    return this.addRecordForm.controls;
  }
  loadGenders() {
    this.appService.getGenders.subscribe((g) => {
      this.genders = g;
    });
  }

  submit() {
    if (!this.addRecordForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }
    let data = this.addRecordForm.value;
    let bDay = this.addRecordForm.get('dob')?.value;
    data['dob'] = bDay.toISOString().split('T')[0];
    this.isLoading = true;
    this.appService
      .makePutRequest(
        `${environment.BASE_API_URL}/users/update/${this.recordId}`,
        data
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            res.statusMessage
          );
          let record: IUser = res.payload;
          this.router.navigate(['/members/member', record.id]);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  fetchRecord(recordId: string) {
    this.isLoading = true;
    this.appService
      .makeGetRequest(`${environment.BASE_API_URL}/users/get/${recordId}`)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.record = res.payload;
          this.addRecordForm.patchValue(this.record);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
}

<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Loan Types</p>

    <a class="btn btn-sm btn-link" routerLink="add">
      <fa-icon [icon]="faAdd" class="me-1"></fa-icon> Create Loan Type
    </a>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  }
  <div class="my-4">
    <app-search-box (searchEvent)="search($event)"></app-search-box>
    @if (records.length > 0) {
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Interest</th>
            <th scope="col">Term</th>
            <th scope="col">Max Amount</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr
            class="clickable-row"
            *ngFor="let record of records"
            (click)="view(record.id)"
          >
            <td>{{ record.name }}</td>
            <td>
              {{ record.interestRate | percent : "1.2-3" }}
              {{ record.periodicity.name }}
            </td>
            <td>{{ record.term }} {{ record.termDesc }}</td>
            <td>{{ record.maxAmountMultiplier }}x</td>
            <td><fa-icon [icon]="faArrowRight" class="me-1"></fa-icon></td>
          </tr>
        </tbody>
      </table>
      <app-list-pagination
        [pageSearch]="pageSearch"
        [paginatatedRecord]="paginatatedRecord"
        (turnPage)="fetchRecords($event)"
      >
      </app-list-pagination>
    </div>
    } @else {
    <div class="alert alert-warning my-3" role="alert">No records found.</div>
    }
  </div>
</div>

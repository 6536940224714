import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBars,
  faUser,
  faTachometerAlt,
  faUsers,
  faMoneyBill,
  faRetweet,
  faRandom,
  faFileExcel,
  faUmbrella,
  faBullhorn,
  faCertificate,
  faPiggyBank,
  faWrench,
  faGavel,
  faSignOut,
  faClipboard,
} from '@fortawesome/free-solid-svg-icons';
import { AppService } from '../services/app.service';
import { ITenant } from '../models/tenant.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    FontAwesomeModule,
  ],
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrl: './base-layout.component.css',
})
export class BaseLayoutComponent implements OnInit {
  tenant!: ITenant;
  constructor(public appService: AppService) {}

  ngOnInit(): void {
    this.loadTenant();
  }

  loadTenant() {
    this.appService.getTenant.subscribe((t) => {
      this.tenant = t;
    });
  }
  alertMessage = this.appService.alertMessageBagSig();
  faBars = faBars;
  faUser = faUser;
  faTachometerAlt = faTachometerAlt;
  faUsers = faUsers;
  faMoneyBill = faMoneyBill;
  faRetweet = faRetweet;
  faRandom = faRandom;
  faFileExcel = faFileExcel;
  faUmbrella = faUmbrella;
  faBullhorn = faBullhorn;
  faCertificate = faCertificate;
  faPiggyBank = faPiggyBank;
  faWrench = faWrench;
  faGavel = faGavel;
  faSignOut = faSignOut;
  faClipboard = faClipboard;

  currentYear = new Date().getFullYear();

  logOut() {
    this.appService.logout();
  }
}

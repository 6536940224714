<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <p class="h4">Add Loan</p>

        <button
          [routerLink]="['/loans']"
          [routerLinkActive]="['active']"
          class="btn btn-sm btn-link"
        >
          <fa-icon [icon]="faList" class="me-1"></fa-icon> All Loans
        </button>
      </div>

      @if (isLoading) {
      <div class="my-4 d-block">
        <app-loader></app-loader>
      </div>
      }
      <div class="my-4">
        <div class="m-3">
          <form class="row g-3" [formGroup]="addRecordForm" (submit)="submit()">
            <div class="col-md-6">
              <label for="userId" class="form-label"
                >Applicant<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="userId"
                placeholder="Search members"
                [matAutocomplete]="member"
              />
              <mat-autocomplete
                #member="matAutocomplete"
                [displayWith]="displayMemberFn"
              >
                @for (memb of members ; track memb) {
                <mat-option [value]="memb"
                  >{{ memb.name }} ({{ memb.memberNumber }})</mat-option
                >
                }
              </mat-autocomplete>
            </div>

            <div class="col-md-6">
              <label for="loanTypeId" class="form-label"
                >Loan Type<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="loanTypeId"
                placeholder="Search loan types"
                [matAutocomplete]="loanTypeId"
              />
              <mat-autocomplete
                #loanTypeId="matAutocomplete"
                [displayWith]="displayloanTypFn"
              >
                @for (loanType of loanTypes ; track loanType) {
                <mat-option [value]="loanType"
                  >{{ loanType.name }} - {{ loanType.interestRate | percent }}
                  {{ loanType.periodicity.name }}</mat-option
                >
                }
              </mat-autocomplete>
            </div>

            <div class="col-md-6">
              <label for="amount" class="form-label"
                >Amount <span class="text-danger">*</span></label
              >
              <input
                type="number"
                class="form-control"
                formControlName="amount"
                id="amount"
                placeholder="e.g. 12000"
                required
                autocomplete="off"
              />
            </div>

            <div class="col-md-6">
              <label for="transactionDate" class="form-label"
                >Transaction Date<span class="text-danger">*</span></label
              >
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  formControlName="transactionDate"
                  id="transactionDate"
                  readonly
                  [max]="maxDate"
                  [matDatepicker]="transactionDatePicker"
                />
                <mat-datepicker #transactionDatePicker></mat-datepicker>

                <button
                  class="btn btn-outline-dark"
                  type="button"
                  id="button-addon2"
                  (click)="transactionDatePicker.open()"
                >
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </button>
              </div>
            </div>
            <div class="col-12 mt-4">
              <a
                routerLink="/loans"
                type="button"
                class="btn btn-sm btn-outline-dark me-2"
                >Cancel</a
              >
              <button type="submit" class="btn btn-sm btn-primary me-2">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderComponent } from '../../../loader/loader.component';
import { AppService } from '../../../services/app.service';
import { InputValidationService } from '../../../services/input-validation.service';
import { ITenant } from '../../../models/tenant.interface';
import {
  ICountry,
  ICurrency,
  ILanguage,
  ITimezone,
} from '../../../models/country.interface';
import { environment } from '../../../../environments/environment.development';
import { AppEnums } from '../../../models/app.enums';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    ReactiveFormsModule,
  ],
  selector: 'app-org-update',
  templateUrl: './org-update.component.html',
  styleUrl: './org-update.component.css',
})
export class OrgUpdateComponent implements OnInit {
  isLoading: boolean = false;
  editOrgFormGroup: FormGroup;
  tenant!: ITenant;
  countries: ICountry[] = [];
  currencies: ICurrency[] = [];
  languages: ILanguage[] = [];
  timezones: ITimezone[] = [];
  constructor(
    public appService: AppService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private inputValidationService: InputValidationService
  ) {
    this.editOrgFormGroup = this.formBuilder.group({
      name: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: [
        '',
        Validators.compose([
          Validators.required,
          this.inputValidationService.phoneNumberValidator(),
        ]),
      ],
      website: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(100),
          this.inputValidationService.patternValidator(
            /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/
          ),
        ]),
      ],
      address: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      language: ['', Validators.compose([Validators.required])],
      currency: ['', Validators.compose([Validators.required])],
      timezone: ['', Validators.compose([Validators.required])],
      country: ['', Validators.compose([Validators.required])],
    });

    this.editOrgFormGroup.get('country')?.valueChanges.subscribe((c) => {
      let selectedCountry = this.countries.find((country) => {
        return c === country.id;
      });
      if (selectedCountry) {
        this.currencies = selectedCountry?.currencies;
        this.languages = selectedCountry?.languages;
        this.timezones = selectedCountry?.timezones;
      } else {
        this.currencies = [];
        this.languages = [];
        this.timezones = [];
      }
    });
  }

  ngOnInit(): void {
    this.loadCountries();
    this.loadTenant();
  }

  submit() {
    if (!this.editOrgFormGroup.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }

    let data = this.editOrgFormGroup.value;
    this.isLoading = true;
    this.appService
      .makePutRequest(
        `${environment.BASE_API_URL}/tenants/update/${this.tenant.id}`,
        data
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            res.statusMessage
          );

          this.router.navigate(['/settings/organization-settings']);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  loadTenant() {
    this.isLoading = true;
    this.appService.getTenant.subscribe((t) => {
      this.tenant = t;
      this.editOrgFormGroup.patchValue({
        name: this.tenant.name,
        email: this.tenant.email,
        phone: this.tenant.phone,
        website: this.tenant.website,
        address: this.tenant.address,
        country: this.tenant.country?.id,
        currency: this.tenant.currency?.id,
        language: this.tenant.language?.id,
        timezone: this.tenant.timezone,
      });
      this.isLoading = false;
    });
  }

  loadCountries() {
    this.isLoading = true;
    this.appService.getCountries.subscribe((c) => {
      this.countries = c;
      this.isLoading = false;
    });
  }

  get editOrgFormControl() {
    return this.editOrgFormGroup.controls;
  }
}

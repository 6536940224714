import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  RouterLink,
  RouterLinkActive,
  ActivatedRoute,
  Router,
} from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faChevronRight,
  faFileEdit,
  faTrash,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment.development';
import { LoaderComponent } from '../../../loader/loader.component';
import { AppEnums } from '../../../models/app.enums';
import { AppService } from '../../../services/app.service';
import { ILoanType } from '../../../models/loan-type.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
  ],
  selector: 'app-loan-type-details',
  templateUrl: './loan-type-details.component.html',
  styleUrl: './loan-type-details.component.css',
})
export class LoanTypeDetailsComponent {
  isLoading: boolean = false;
  recordId!: string;
  record!: ILoanType;
  faChevronRight = faChevronRight;
  faFileEdit = faFileEdit;
  faTrash = faTrash;
  faList = faList;

  constructor(
    public appService: AppService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activeRoute.params.subscribe((p) => {
      this.recordId = p['id'];
      this.fetchRecord(this.recordId);
    });
  }

  deleteRecord() {
    this.isLoading = true;
    this.appService
      .makeDeleteRequest(
        `${environment.BASE_API_URL}/loan-types/${this.recordId}`
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.router.navigate(['/settings/loan-types']);
        },
        next: (res) => {
          document.getElementById('del-btn-close')?.click();
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            res.statusMessage
          );
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  fetchRecord(recordId: string) {
    this.isLoading = true;
    this.appService
      .makeGetRequest(`${environment.BASE_API_URL}/loan-types/${recordId}`)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.record = res.payload;
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
}

<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Loans</p>

    <div>
      <button
        [routerLink]="['./add']"
        [routerLinkActive]="['active']"
        class="btn btn-sm btn-link"
      >
        <fa-icon [icon]="faPlusCircle" class="me-1"></fa-icon> Record Loan
      </button>
      <button
        class="btn btn-sm btn-link"
        data-bs-toggle="modal"
        data-bs-target="#downloadLoanReportModal"
      >
        <fa-icon [icon]="faCloudDownload" class="me-1"></fa-icon> Download
        Report
      </button>
    </div>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  }
  <div class="my-4">
    <app-search-box (searchEvent)="search($event)"></app-search-box>

    @if (records.length > 0) {
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Loan Reference</th>
            <th scope="col">Applicant</th>
            <th scope="col">Loan Type</th>
            <th scope="col">Loan Period</th>
            <th scope="col">Loan Amount</th>
            <th scope="col">Loan Balance</th>
            <th scope="col">Loan Status</th>
            <th scope="col">Application Date</th>
            <th scope="col">Recorded By</th>
            <th scope="col">Date Recorded</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr
            *ngFor="let record of records"
            (click)="view(record.id)"
            class="clickable-row"
          >
            <td>{{ record.referenceNumber }}</td>
            <td>{{ record.applicant.name }}</td>
            <td>{{ record.loanType.name }}</td>
            <td>{{ record.loanType.term }} {{ record.loanType.termDesc }}</td>
            <td>{{ record.amount | currency : " " }}</td>
            <td>{{ record.balance | currency : " " }}</td>
            <td>{{ record.status }}</td>
            <td>{{ record.dateApplied | date : "mediumDate" }}</td>
            <td>{{ record.poster.name }}</td>
            <td>{{ record.createdAt | date : "medium" }}</td>
            <td><fa-icon [icon]="faArrowRight" class="me-1"></fa-icon></td>
          </tr>
        </tbody>
      </table>
      <app-list-pagination
        [pageSearch]="pageSearch"
        [paginatatedRecord]="paginatatedRecord"
        (turnPage)="fetchRecords($event)"
      >
      </app-list-pagination>
    </div>
    } @else {
    <div class="alert alert-warning my-3" role="alert">No records found.</div>
    }
  </div>
</div>

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderComponent } from '../../loader/loader.component';
import { ILoan } from '../../models/loan.interface';
import {
  faClipboard,
  faHandshake,
  faList,
  faMoneyBill,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import { AppService } from '../../services/app.service';
import { environment } from '../../../environments/environment.development';
import { AppEnums } from '../../models/app.enums';
import { ILoanCollateral } from '../../models/loan-collateral.interface';
import { LoanDetailsViewComponent } from '../loan-details-view/loan-details-view.component';
import { LoanGuaranteeViewComponent } from '../loan-guarantee-view/loan-guarantee-view.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    LoanDetailsViewComponent,
    LoanGuaranteeViewComponent,
  ],
  selector: 'app-loans-view',
  templateUrl: './loans-view.component.html',
  styleUrl: './loans-view.component.css',
})
export class LoansViewComponent {
  isLoading: boolean = false;
  recordId!: string;
  record!: ILoan;
  faList = faList;
  faPaperclip = faPaperclip;
  faHandshake = faHandshake;
  faMoneyBill = faMoneyBill;
  faClipboard = faClipboard;
  constructor(
    public appService: AppService,
    private activeRoute: ActivatedRoute
  ) {
    this.activeRoute.params.subscribe((p) => {
      this.recordId = p['id'];
      this.fetchRecord(this.recordId);
    });
  }

  fetchRecord(recordId: string) {
    this.isLoading = true;
    this.appService
      .makeGetRequest(`${environment.BASE_API_URL}/loans/get/${recordId}`)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.record = res.payload;
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
}

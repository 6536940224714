import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faAdd,
  faFileEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { LoaderComponent } from '../../loader/loader.component';
import { IPaginatedRole } from '../../models/role.interface';
import { SearchBoxComponent } from '../../search-box/search-box.component';
import { AppService } from '../../services/app.service';
import { IAttachmentType } from '../../models/attachment-type.interface';
import { environment } from '../../../environments/environment.development';
import { AppEnums } from '../../models/app.enums';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    SearchBoxComponent,
    ReactiveFormsModule,
  ],
  selector: 'app-attachment-types',
  templateUrl: './attachment-types.component.html',
  styleUrl: './attachment-types.component.css',
})
export class AttachmentTypesComponent {
  paginatatedRecord!: IPaginatedRole;
  isLoading: boolean = false;
  faAdd = faAdd;
  faFileEdit = faFileEdit;
  faTrashAlt = faTrashAlt;
  records: IAttachmentType[] = [];
  filteredRecords: IAttachmentType[] = [];
  modalTitle: string = 'Create Attachment Type';
  formMode: string = 'CREATE';
  attachmentTypeForm: FormGroup;
  selectedAttachmentType!: IAttachmentType;

  constructor(
    private appService: AppService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.attachmentTypeForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
    });
  }

  openUpdateAttachmentType(attachmentType: IAttachmentType) {
    this.formMode = 'EDIT';
    this.modalTitle = 'Update Attachment Type';
    this.selectedAttachmentType = attachmentType;
    this.attachmentTypeForm.patchValue({
      name: attachmentType.name,
    });
  }
  deleteRecord() {
    this.isLoading = true;
    this.appService
      .makeDeleteRequest(
        `${environment.BASE_API_URL}/attachment-types/${this.selectedAttachmentType.id}`
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.closeModal();
        },
        next: (res) => {
          this.fetchRecords();
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
  openDeleteAttachmentTypeDialog(attachmentType: IAttachmentType) {
    this.selectedAttachmentType = attachmentType;
  }
  closeModal() {
    document.getElementById('attachmentType-btn-close')?.click();
    document.getElementById('del-btn-close')?.click();
    this.modalTitle = 'Create Attachment Type';
    this.formMode = 'CREATE';
  }

  submit() {
    if (!this.attachmentTypeForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }
    this.isLoading = true;
    let data = {
      name: String(this.attachmentTypeForm.get('name')?.value).trim(),
    };
    if (this.formMode === 'CREATE') {
      this.appService
        .makePostRequest(`${environment.BASE_API_URL}/attachment-types`, data)
        .subscribe({
          complete: () => {
            this.isLoading = false;
            this.closeModal();
          },
          next: (res) => {
            this.fetchRecords();
          },
          error: (err) => {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              '',
              this.appService.processHttpErrors(err)
            );
          },
        });
    }
    if (this.formMode === 'EDIT') {
      this.appService
        .makePutRequest(
          `${environment.BASE_API_URL}/attachment-types/${this.selectedAttachmentType.id}`,
          data
        )
        .subscribe({
          complete: () => {
            this.isLoading = false;
            this.closeModal();
          },
          next: (res) => {
            this.fetchRecords();
          },
          error: (err) => {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              '',
              this.appService.processHttpErrors(err)
            );
          },
        });
    }
  }

  ngOnInit(): void {
    this.fetchRecords();
  }
  get attachmentTypeFormControl() {
    return this.attachmentTypeForm.controls;
  }
  fetchRecords() {
    this.isLoading = true;
    this.appService.fetchAttachmentTypes.subscribe({
      complete: () => {
        this.isLoading = false;
      },
      next: (attachmentTypes) => {
        this.records = attachmentTypes;
        this.filteredRecords = this.records;
      },
      error: (err) => {
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.appService.processHttpErrors(err)
        );
        this.isLoading = false;
      },
    });
  }

  search($evt: any) {
    let searchTerm: string = String($evt).toLowerCase();
    if (searchTerm.length > 1) {
      this.filteredRecords = this.records.filter((f) => {
        return f.name.toLowerCase().includes(searchTerm);
      });
    } else {
      this.fetchRecords();
    }
  }
}

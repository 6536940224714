import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';

import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderComponent } from '../../loader/loader.component';
import { faList, faUndo } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../environments/environment.development';
import { AppEnums } from '../../models/app.enums';
import { ICollection } from '../../models/collection.interface';
import { AppService } from '../../services/app.service';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    ReactiveFormsModule,
  ],
  selector: 'app-collections-details',
  templateUrl: './collections-details.component.html',
  styleUrl: './collections-details.component.css',
})
export class CollectionsDetailsComponent {
  router = inject(Router);
  faList = faList;
  faUndo = faUndo;
  isLoading: boolean = false;
  record!: ICollection;
  recordId!: string;
  addRecordForm: FormGroup;

  constructor(
    public appService: AppService,
    private activeRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder
  ) {
    this.activeRoute.params.subscribe((p) => {
      this.recordId = p['id'];
      this.fetchRecord(this.recordId);
    });
    this.addRecordForm = this.formBuilder.group({
      narration: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {}
  reverseTransaction() {
    if (!this.addRecordForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'The reversal reason is required'
      );
      return;
    }
    let narration = String(this.addRecordForm.get('narration')?.value).trim();
    this.isLoading = true;
    this.appService
      .makePutRequest(
        `${environment.BASE_API_URL}/collections/reverse/${this.recordId}`,
        {
          narration: narration,
        }
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.addRecordForm.reset({});
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            res.statusMessage
          );
          let p: ICollection = res.payload;
          document.getElementById('closeReverseTransactionModal')?.click();
          this.router.navigate(['/collections/details', p.id]);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  fetchRecord(recordId: string) {
    this.appService.clearErrorBag();
    this.isLoading = true;
    this.appService
      .makeGetRequest(`${environment.BASE_API_URL}/collections/${recordId}`)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.record = res.payload;
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }
}

<div id="layoutError">
  <div id="layoutError_content">
    <main>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="text-center mt-4">
              <img
                class="mb-4 img-error"
                src="assets/img/error-404-monochrome.svg"
              />
              <p class="lead">This requested page was not found.</p>
              <a routerLink="/dashboard">
                <fa-icon [icon]="faArrowLeft" class="me-1"></fa-icon>
                Return to Dashboard
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

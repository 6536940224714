import { Component, inject } from '@angular/core';
import { AppService } from '../services/app.service';
import { LoaderComponent } from '../loader/loader.component';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';

@Component({
  standalone: true,
  imports: [BaseLayoutComponent, LoaderComponent],
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css',
})
export class DashboardComponent {
  appService = inject(AppService);
  //user = this.appService.currentuserSig();
  //l = this.appService.logout();
}

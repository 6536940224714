import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListPaginationComponent } from '../../list-pagination/list-pagination.component';
import { LoaderComponent } from '../../loader/loader.component';
import { SearchBoxComponent } from '../../search-box/search-box.component';
import {
  faFileEdit,
  faTrashAlt,
  faAdd,
} from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../environments/environment.development';
import { AppEnums } from '../../models/app.enums';
import { IPaginatedRole, IRole } from '../../models/role.interface';
import { AppService } from '../../services/app.service';
import { IGroupingTag } from '../../models/grouping-tag.interface';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    SearchBoxComponent,
    ReactiveFormsModule,
  ],
  selector: 'app-grouping-tags',
  templateUrl: './grouping-tags.component.html',
  styleUrl: './grouping-tags.component.css',
})
export class GroupingTagsComponent {
  paginatatedRecord!: IPaginatedRole;
  isLoading: boolean = false;
  faAdd = faAdd;
  faFileEdit = faFileEdit;
  faTrashAlt = faTrashAlt;
  records: IGroupingTag[] = [];
  filteredRecords: IGroupingTag[] = [];
  modalTitle: string = 'Create Tag';
  formMode: string = 'CREATE';
  tagForm: FormGroup;
  selectedTag!: IGroupingTag;

  constructor(
    private appService: AppService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.tagForm = this.formBuilder.group({
      tag: ['', Validators.compose([Validators.required])],
    });
  }

  openUpdateTag(tag: IGroupingTag) {
    this.formMode = 'EDIT';
    this.modalTitle = 'Update Tag';
    this.selectedTag = tag;
    this.tagForm.patchValue({
      tag: tag.tag,
    });
  }
  deleteRecord() {
    this.isLoading = true;
    this.appService
      .makeDeleteRequest(
        `${environment.BASE_API_URL}/tags/${this.selectedTag.id}`
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.closeModal();
        },
        next: (res) => {
          this.fetchRecords();
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
  openDeleteTagDialog(tag: IGroupingTag) {
    this.selectedTag = tag;
  }
  closeModal() {
    document.getElementById('tag-btn-close')?.click();
    document.getElementById('del-btn-close')?.click();
    this.modalTitle = 'Create Tag';
    this.formMode = 'CREATE';
  }

  submit() {
    if (!this.tagForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }
    this.isLoading = true;
    let data = {
      tag: String(this.tagForm.get('tag')?.value).trim(),
    };
    if (this.formMode === 'CREATE') {
      this.appService
        .makePostRequest(`${environment.BASE_API_URL}/tags`, data)
        .subscribe({
          complete: () => {
            this.isLoading = false;
            this.closeModal();
          },
          next: (res) => {
            this.fetchRecords();
          },
          error: (err) => {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              '',
              this.appService.processHttpErrors(err)
            );
          },
        });
    }
    if (this.formMode === 'EDIT') {
      this.appService
        .makePutRequest(
          `${environment.BASE_API_URL}/tags/${this.selectedTag.id}`,
          data
        )
        .subscribe({
          complete: () => {
            this.isLoading = false;
            this.closeModal();
          },
          next: (res) => {
            this.fetchRecords();
          },
          error: (err) => {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              '',
              this.appService.processHttpErrors(err)
            );
          },
        });
    }
  }

  ngOnInit(): void {
    this.fetchRecords();
  }
  get tagFormControl() {
    return this.tagForm.controls;
  }
  fetchRecords() {
    this.isLoading = true;
    this.appService.fetchTags.subscribe({
      complete: () => {
        this.isLoading = false;
      },
      next: (tags) => {
        this.records = tags;
        this.filteredRecords = this.records;
      },
      error: (err) => {
        this.appService.showToastMessage(
          AppEnums.ToastTypeError,
          '',
          this.appService.processHttpErrors(err)
        );
        this.isLoading = false;
      },
    });
  }

  search($evt: any) {
    let searchTerm: string = String($evt).toLowerCase();
    if (searchTerm.length > 1) {
      this.filteredRecords = this.records.filter((f) => {
        return f.tag.toLowerCase().includes(searchTerm);
      });
    } else {
      this.fetchRecords();
    }
  }
}

import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import {
  RouterOutlet,
  RouterLink,
  RouterLinkActive,
  Router,
} from '@angular/router';
import { AppService } from '../../services/app.service';
import { IUser } from '../../models/user.interface';

@Component({
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive],
  selector: 'app-default-onboarding',
  templateUrl: './default-onboarding.component.html',
  styleUrl: './default-onboarding.component.css',
})
export class DefaultOnboardingComponent implements OnInit {
  router = inject(Router);
  appService = inject(AppService);
  user!: IUser;
  firstName: string = '';
  ngOnInit(): void {
    this.user = this.appService.getUserSession();
    if (this.user) {
      this.firstName = this.user.name.split(' ')[0];
    }
  }

  registerGroup() {
    this.router.navigate(['/onboard/register-group']);
  }
  joinGroup() {
    this.router.navigate(['/onboard/join-group']);
  }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoaderComponent } from '../../loader/loader.component';

@Component({
  standalone: true,
  imports: [CommonModule, RouterOutlet, LoaderComponent],
  selector: 'app-rbac-settings',
  templateUrl: './rbac-settings.component.html',
  styleUrl: './rbac-settings.component.css',
})
export class RbacSettingsComponent {}

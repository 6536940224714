import { Component } from '@angular/core';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  standalone: true,
  imports: [BaseLayoutComponent, LoaderComponent],
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css',
})
export class SettingsComponent {}

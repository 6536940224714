<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Collections</p>

    <div>
      <button
        [routerLink]="['./add']"
        [routerLinkActive]="['active']"
        class="btn btn-sm btn-link"
      >
        <fa-icon [icon]="faPlusCircle" class="me-1"></fa-icon> Record Collection
      </button>
      <button
        class="btn btn-sm btn-link"
        data-bs-toggle="modal"
        data-bs-target="#downloadCollectionReportModal"
      >
        <fa-icon [icon]="faCloudDownload" class="me-1"></fa-icon> Download
        Report
      </button>
    </div>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  }
  <div class="my-4">
    <div
      class="modal fade"
      id="downloadCollectionReportModal"
      tabindex="-1"
      aria-labelledby="downloadCollectionReportModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1
              class="modal-title fs-5"
              id="downloadCollectionReportModalLabel"
            >
              Download Report
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            @if(isLoading){
            <div class="my-2 d-block">
              <app-loader></app-loader>
            </div>
            }

            <form class="" [formGroup]="filterFormGroup" (submit)="download()">
              <div class="row">
                <div class="col-md-6">
                  <label for="startDate" class="form-label"
                    >Transactions From<span class="text-danger">*</span></label
                  >
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="startDate"
                      id="startDate"
                      readonly
                      [matDatepicker]="startDatePicker"
                    />
                    <mat-datepicker #startDatePicker></mat-datepicker>

                    <button
                      class="btn btn-outline-dark"
                      type="button"
                      id="button-addon2"
                      (click)="startDatePicker.open()"
                    >
                      <fa-icon [icon]="faCalendar"></fa-icon>
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="endDate" class="form-label"
                    >Transactions To <span class="text-danger">*</span></label
                  >
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="endDate"
                      readonly
                      id="endDate"
                      [matDatepicker]="endDatePicker"
                    />
                    <mat-datepicker #endDatePicker></mat-datepicker>
                    <button
                      class="btn btn-outline-dark"
                      type="button"
                      id="button-addon2"
                      (click)="endDatePicker.open()"
                    >
                      <fa-icon [icon]="faCalendar"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <label for="accountId" class="form-label"
                    >Filter by Account<span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="accountId"
                    placeholder="search accounts"
                    [matAutocomplete]="account"
                  />
                  <mat-autocomplete
                    #account="matAutocomplete"
                    [displayWith]="displayAccountFn"
                  >
                    @for (acc of accounts ; track acc) {
                    <mat-option [value]="acc"
                      >{{ acc.name }} ({{ acc.type }})</mat-option
                    >
                    }
                  </mat-autocomplete>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-12">
                  <label for="payMode" class="form-label"
                    >Filter by payment method</label
                  >
                  <select
                    class="form-select"
                    aria-label="Select payment mode"
                    formControlName="payMode"
                  >
                    @for (paymode of paymodes; track $index) {
                    <option [value]="paymode">{{ paymode }}</option>
                    }
                  </select>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-12">
                  <label for="posterId" class="form-label"
                    >Filter by Poster</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="posterId"
                    placeholder="search posters"
                    [matAutocomplete]="poster"
                  />
                  <mat-autocomplete
                    #poster="matAutocomplete"
                    [displayWith]="displayMemberFn"
                  >
                    @for (poster of posters ; track poster) {
                    <mat-option [value]="poster">{{ poster.name }}</mat-option>
                    }
                  </mat-autocomplete>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-12">
                  <label for="payerId" class="form-label"
                    >Filter by contributer</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="payerId"
                    placeholder="search members"
                    [matAutocomplete]="member"
                  />
                  <mat-autocomplete
                    #member="matAutocomplete"
                    [displayWith]="displayMemberFn"
                  >
                    @for (memb of members ; track memb) {
                    <mat-option [value]="memb"
                      >{{ memb.name }} ({{ memb.memberNumber }})</mat-option
                    >
                    }
                  </mat-autocomplete>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-6">
                  <div class="d-grid gap-2">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-dark me-2"
                      id="closeDownloadReportModal"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-sm btn-primary">
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <app-search-box
      (searchEvent)="search($event)"
      seacrhBoxPlaceHolder="Reference eg AHJYYYAJJA"
    ></app-search-box>
    @if (records.length > 0) {
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Transaction Date</th>
            <th scope="col">Amount</th>
            <th scope="col">Reference</th>
            <th scope="col">Account</th>
            <th scope="col">Received From</th>
            <th scope="col">PayMode</th>
            <th scope="col">Narration</th>
            <th scope="col">Recorded By</th>
            <th scope="col">Date Recorded</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr
            *ngFor="let record of records"
            (click)="view(record.id)"
            class="clickable-row"
          >
            <td>{{ record.transactionDate | date : "mediumDate" }}</td>
            <td>{{ record.amount | currency : " " }}</td>
            <td>{{ record.reference }}</td>
            <td>{{ record.account.name }}</td>
            <td>{{ record.payer.name }}</td>
            <td>{{ record.payMode }}</td>
            <td>{{ record.narration | slice : 0 : 30 }}</td>
            <td>{{ record.poster.name }}</td>
            <td>{{ record.createdAt | date : "medium" }}</td>
            <td><fa-icon [icon]="faArrowRight" class="me-1"></fa-icon></td>
          </tr>
        </tbody>
      </table>
      <app-list-pagination
        [pageSearch]="pageSearch"
        [paginatatedRecord]="paginatatedRecord"
        (turnPage)="fetchRecords($event)"
      >
      </app-list-pagination>
    </div>
    } @else {
    <div class="alert alert-warning my-3" role="alert">No records found.</div>
    }
  </div>
</div>

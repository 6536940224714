import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderComponent } from '../../loader/loader.component';
import {
  faFileExcel,
  faList,
  faUserPlus,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { AppService } from '../../services/app.service';
import { InputValidationService } from '../../services/input-validation.service';
import { IGender } from '../../models/gender.interface';
import { AppEnums } from '../../models/app.enums';
import { environment } from '../../../environments/environment';
import { IUser } from '../../models/user.interface';
import { HttpResponse } from '@angular/common/http';
import { IApiResponse } from '../../models/api-response.interface';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    ReactiveFormsModule,
    MatDatepickerModule,
  ],
  selector: 'app-members-create',
  templateUrl: './members-create.component.html',
  styleUrl: './members-create.component.css',
})
export class MembersCreateComponent {
  maxDateOfBirth = new Date();
  isLoading: boolean = false;
  faList = faList;
  genders: IGender[] = [];
  faUserPlus = faUserPlus;
  faFileExcel = faFileExcel;
  faCalendar = faCalendar;
  addRecordForm: FormGroup;
  uploadMembersFormGroup: FormGroup;
  templateUrl: string = './assets/member_upload_template.xlsx';
  aFile!: File;
  constructor(
    public appService: AppService,
    private formBuilder: UntypedFormBuilder,
    private inputValidationService: InputValidationService,
    private router: Router
  ) {
    this.uploadMembersFormGroup = this.formBuilder.group({
      file: ['', Validators.required],
    });
    this.addRecordForm = this.formBuilder.group({
      memberNumber: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(10)]),
      ],
      name: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.maxLength(50),
        ]),
      ],
      phone: [
        '',
        Validators.compose([
          Validators.required,
          this.inputValidationService.phoneNumberValidator(),
        ]),
      ],
      gender: ['', Validators.compose([Validators.required])],
      idNumber: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(10)]),
      ],
      taxPin: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(12)]),
      ],
      dob: ['', Validators.compose([Validators.required])],
    });

    this.loadGenders();
  }

  get addRecordFormControl() {
    return this.addRecordForm.controls;
  }
  loadGenders() {
    this.appService.getGenders.subscribe((g) => {
      this.genders = g;
    });
  }

  onFileSelected(e: any) {
    this.aFile = e.files[0];
  }

  upload() {
    if (this.aFile == undefined) {
      return;
    }
    let formData = new FormData();
    formData.append('file', this.aFile);
    this.isLoading = true;
    this.appService
      .makeUploadRequest(`${environment.BASE_API_URL}/users/upload`, formData)
      .subscribe({
        complete: () => {},
        next: (event) => {
          if (event instanceof HttpResponse) {
            if (event.body) {
              let body: IApiResponse = event.body;
              if (body.statusCode == 200) {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeSuccess,
                  '',
                  body.statusMessage
                );
                this.isLoading = false;
              } else {
                this.appService.showToastMessage(
                  AppEnums.ToastTypeWarning,
                  '',
                  body.statusMessage
                );
                this.isLoading = false;
              }
            } else {
              this.appService.showToastMessage(
                AppEnums.ToastTypeWarning,
                '',
                'Invalid response'
              );
              this.isLoading = false;
            }
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  submit() {
    if (!this.addRecordForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }
    let data = this.addRecordForm.value;
    let bDay = this.addRecordForm.get('dob')?.value;
    data['dob'] = bDay.toISOString().split('T')[0];
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/users/add`, data)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            res.statusMessage
          );
          let member: IUser = res.payload;
          this.router.navigate(['/members/member', member.id]);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { RouterLink, RouterLinkActive, Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from '../../../../environments/environment.development';
import { LoaderComponent } from '../../../loader/loader.component';
import { AppEnums } from '../../../models/app.enums';
import { AppService } from '../../../services/app.service';
import { ILoanType } from '../../../models/loan-type.interface';
import { IKeyValue } from '../../../models/key-value.interface';
import { IPeriodicity } from '../../../models/periodicity.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    ReactiveFormsModule,
  ],
  selector: 'app-loan-type-create',
  templateUrl: './loan-type-create.component.html',
  styleUrl: './loan-type-create.component.css',
})
export class LoanTypeCreateComponent {
  isLoading: boolean = false;
  addRecordForm: FormGroup;
  termDescriptions: IKeyValue[] = [];
  periodicities: IPeriodicity[] = [];
  constructor(
    public appService: AppService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.addRecordForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required])],

      interestRate: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(100),
        ]),
      ],
      term: ['', Validators.compose([Validators.required, Validators.min(1)])],
      termDesc: ['', Validators.compose([Validators.required])],
      periodicityId: ['', Validators.compose([Validators.required])],
      maxAmountMultiplier: [
        '',
        Validators.compose([Validators.required, Validators.min(1)]),
      ],
    });

    this.appService.getTermDescriptions.subscribe((res) => {
      this.termDescriptions = res;
    });
    this.appService.getAllPeriodicities.subscribe((res) => {
      this.periodicities = res;
    });
  }

  submit() {
    if (!this.addRecordForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }

    let data = this.addRecordForm.value;
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/loan-types`, data)
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            res.statusMessage
          );
          this.addRecordForm.reset();
          let record: ILoanType = res.payload;
          this.router.navigate(['/settings/loan-types/details', record.id]);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
}

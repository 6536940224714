import { Component } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { environment } from '../../../environments/environment.development';
import { AppEnums } from '../../models/app.enums';
import { IUser } from '../../models/user.interface';
import { AppService } from '../../services/app.service';
import { InputValidationService } from '../../services/input-validation.service';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../../loader/loader.component';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterLink, LoaderComponent],
  selector: 'app-join-group',
  templateUrl: './join-group.component.html',
  styleUrl: './join-group.component.css',
})
export class JoinGroupComponent {
  isLoading: boolean = false;
  registerFormGroup: FormGroup;
  user!: IUser;
  firstName: string = '';
  constructor(
    private appService: AppService,
    private inputValidationService: InputValidationService,
    private router: Router
  ) {
    this.registerFormGroup = new FormGroup({
      phone: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          this.inputValidationService.phoneNumberValidator(),
        ])
      ),
      code: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          this.inputValidationService.patternValidator(/^[A-Z0-9]{10,10}$/),
        ])
      ),
    });
  }

  ngOnInit(): void {
    this.firstName = this.appService.getFirstName;
  }

  submit() {
    if (!this.registerFormGroup.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }

    let phone = this.registerFormGroup.get('phone')?.value.trim();
    let code = this.registerFormGroup.get('code')?.value.trim();

    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/auth/join`, {
        code: code,
        phone: phone,
      })
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeSuccess,
            '',
            res.statusMessage
          );
          this.router.navigate(['/dashboard']);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  get registerFormControl() {
    return this.registerFormGroup.controls;
  }
}

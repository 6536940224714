<div class="container-fluid">
  @if(isLoading){
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  } @if(record) {
  <div class="d-flex justify-content-between">
    <p class="h4">
      {{ record.name }}
    </p>

    <div>
      <button
        [routerLink]="['/members']"
        [routerLinkActive]="['active']"
        class="btn btn-sm btn-link"
      >
        <fa-icon [icon]="faList" class="me-1"></fa-icon> All Members
      </button>
    </div>
  </div>
  <hr />
  @if(!record.enabled) {
  <div class="alert alert-warning" role="alert">Account is disabled</div>
  }
  <div class="row">
    <div class="col-md-4">
      <p class="fw-bold">
        <small class="text-muted d-block">Member Number</small>
        {{ record.memberNumber }}
      </p>
      <p class="fw-bold">
        <small class="text-muted d-block">Name</small>
        {{ record.name }}
      </p>

      <p class="fw-bold">
        <small class="text-muted d-block">Email</small>
        {{ record.email }}
      </p>
      <p class="fw-bold">
        <small class="text-muted d-block">Phone</small>
        {{ record.phone }}
      </p>
      <p class="fw-bold">
        <small class="text-muted d-block">Gender</small>
        {{ record.gender }}
      </p>
      <p class="fw-bold">
        <small class="text-muted d-block">Birthday</small>
        {{ record.dob }}
      </p>
      <p class="fw-bold">
        <small class="text-muted d-block">ID Number</small>
        {{ record.idNumber }}
      </p>

      <p class="fw-bold">
        <small class="text-muted d-block">Tax PIN</small>
        {{ record.taxPin }}
      </p>

      <p class="fw-bold">
        <small class="text-muted d-block">Created</small>
        {{ record.createdAt | date : "medium" }}
      </p>
      <p class="fw-bold">
        <small class="text-muted d-block">Last updated</small>
        {{ record.updatedAt | date : "medium" }}
      </p>
    </div>
    <div class="col-md-4">
      @if (attachments.length>0) {
      <div class="text-muted d-block fw-bold">Member Documents</div>
      <ul>
        @for (attachment of attachments; track attachment) {
        <li>
          <a [href]="attachment.url" target="_blank">{{ attachment.name }}</a>
          <button
            class="btn btn-sm ms-1"
            (click)="selectAttachment(attachment)"
            data-bs-toggle="modal"
            data-bs-target="#deleteAttachmentModal"
          >
            <fa-icon [icon]="faTrash"></fa-icon> Delete
          </button>
        </li>
        }
      </ul>
      } @if (record.roles.length>0) {
      <div class="text-muted d-block fw-bold">Member Roles</div>
      <ul>
        @for (r of record.roles; track r) {
        <li>{{ (r | role | async)?.displayName }}</li>
        }
      </ul>
      @if (record.tags) { @if (record.tags.length>0) {
      <div class="text-muted d-block fw-bold">Member Tags</div>
      <ul>
        @for (t of record.tags; track t) {
        <li>{{ (t | groupTag | async)?.tag }}</li>
        }
      </ul>
      } } }
    </div>
    <div class="col-md-4">
      <div class="fw-bold">
        <div class="text-muted d-block">
          Actions
          <button
            [routerLink]="['/members/edit', this.recordId]"
            [routerLinkActive]="['active']"
            class="btn btn-sm btn-link m-1 d-block"
          >
            <fa-icon [icon]="faFileEdit" class="me-1"></fa-icon> Edit Member
            Records
          </button>
          <button
            class="btn btn-sm btn-link m-1 d-block"
            data-bs-toggle="modal"
            data-bs-target="#editDocumentsModal"
          >
            <fa-icon [icon]="faFileEdit" class="me-1"></fa-icon> Edit Member
            Documents
          </button>
          <button
            class="btn btn-sm btn-link m-1 d-block"
            data-bs-toggle="modal"
            data-bs-target="#editRoleModal"
          >
            <fa-icon [icon]="faFileEdit" class="me-1"></fa-icon> Edit Member
            Roles
          </button>

          <button
            class="btn btn-sm btn-link m-1 d-block"
            data-bs-toggle="modal"
            data-bs-target="#editTagsModal"
          >
            <fa-icon [icon]="faFileEdit" class="me-1"></fa-icon> Edit Member
            Tags
          </button>

          @if(record.enabled) {
          <button
            class="btn btn-sm btn-link m-1 d-block"
            data-bs-toggle="modal"
            data-bs-target="#disableAccountModal"
          >
            <fa-icon [icon]="faUserTimes" class="me-1"></fa-icon> Disable
            Account
          </button>
          }@else {
          <button
            class="btn btn-sm btn-link m-1 d-block"
            (click)="submitDisableAccount()"
          >
            <fa-icon [icon]="faUserCheck" class="me-1"></fa-icon> Enable Account
          </button>
          }
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="editDocumentsModal"
    tabindex="-1"
    aria-labelledby="editDocumentsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="editDocumentsModalLabel">
            Update Documents for {{ record.name }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          @if(isLoading){
          <div class="my-2 d-block">
            <app-loader></app-loader>
          </div>
          }
          <form class="row g-3" [formGroup]="addDocumentForm">
            <div class="mb-2">
              <label for="type" class="form-label"
                >Attachment Type <span class="text-danger">*</span></label
              >
              <select
                class="form-select"
                aria-label="Select Gender"
                formControlName="type"
              >
                @for (attachmentType of attachmentTypes; track $index) {
                <option [value]="attachmentType.id">
                  {{ attachmentType.name }}
                </option>
                }
              </select>
            </div>
            <label for="x" class="form-label"
              >Select Document <span class="text-danger">*</span></label
            >
            <div class="input-group">
              <input
                type="file"
                class="form-control"
                id="file"
                (change)="onFileSelected($event.target)"
                formControlName="file"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-outline-dark me-2"
            data-bs-dismiss="modal"
            id="closeeditDocumentsModal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="submitDocuments()"
          >
            Submit Document
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="deleteAttachmentModal"
    tabindex="-1"
    aria-labelledby="deleteAttachmentModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="deleteAttachmentModalLabel">
            Delete Document
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          @if(isLoading){
          <div class="my-2 d-block">
            <app-loader></app-loader>
          </div>
          } @if(selectedAttachment){
          <p>
            Are you sure you want to delete
            <strong>{{ selectedAttachment.name }} </strong> for
            {{ record.name }}?
          </p>
          }
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-outline-dark me-2"
            data-bs-dismiss="modal"
            id="closeDeleteAttachmentModal"
          >
            NO. Cancel
          </button>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="submitDeleteDocument()"
          >
            YES. Delete Document
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="disableAccountModal"
    tabindex="-1"
    aria-labelledby="disableAccountModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="disableAccountModalLabel">
            Disable Account for {{ record.name }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          @if(isLoading){
          <div class="my-2 d-block">
            <app-loader></app-loader>
          </div>
          }
          <p>
            Are you sure you want to disble the account for {{ record.name }}?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-outline-dark me-2"
            data-bs-dismiss="modal"
            id="closeDisableAccountModal"
          >
            NO. Cancel
          </button>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="submitDisableAccount()"
          >
            YES. Disable Account
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="editTagsModal"
    tabindex="-1"
    aria-labelledby="editTagsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="editTagsModalLabel">
            Update Tags for {{ record.name }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          @if(isLoading){
          <div class="my-2 d-block">
            <app-loader></app-loader>
          </div>
          }
          <form class="row g-3" [formGroup]="assignTagForm">
            <ng-container formArrayName="tagsFormArray">
              <ng-container
                *ngFor="let aTag of tagsFormArray.controls; let i = index"
              >
                <div class="form-check" [formGroupName]="i">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="tagId"
                    id="btn-tag-{{ i }}"
                  />
                  <label class="form-check-label" for="btn-tag-{{ i }}">
                    {{ allTags[i].tag }}
                  </label>
                </div>
              </ng-container>
            </ng-container>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-outline-dark me-2"
            data-bs-dismiss="modal"
            id="closeEditTagsModal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="submitTags()"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="editRoleModal"
    tabindex="-1"
    aria-labelledby="editRoleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="editRoleModalLabel">
            Update Roles for {{ record.name }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          @if(isLoading){
          <div class="my-2 d-block">
            <app-loader></app-loader>
          </div>
          }
          <form class="row g-3" [formGroup]="assignRoleForm">
            <ng-container formArrayName="rolesFormArray">
              <ng-container
                *ngFor="let aRole of rolesFormArray.controls; let i = index"
              >
                <div class="form-check" [formGroupName]="i">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="roleId"
                    id="btn-role-{{ i }}"
                  />
                  <label class="form-check-label" for="btn-role-{{ i }}">
                    {{ allRoles[i].displayName }}
                  </label>
                </div>
              </ng-container>
            </ng-container>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-outline-dark me-2"
            data-bs-dismiss="modal"
            id="closeEditRoleModal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="submitRoles()"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>

  }
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-8 mx-auto">
      @if (isLoading) {
      <div class="text-center">
        <div class="my-4 d-block">
          <app-loader></app-loader>
        </div>
      </div>
      } @if (tenant) {

      <div class="card">
        <div class="card-body">
          <div class="text-center">
            <img [src]="tenant.logoUrl" class="img-fluid" [alt]="tenant.name" />
            <form>
              <label class="custom-file-upload">
                <fa-icon [icon]="faCamera"></fa-icon> Change logo

                <input
                  type="file"
                  id="logo"
                  name="logo"
                  (change)="onFileSelected($event)"
                />
              </label>
            </form>
          </div>
          <div class="my-3">
            <a
              routerLink="/settings/organization-settings/update"
              class="card-link clickable"
              >Edit Organization Details</a
            >
          </div>
          <p class="fw-bold">
            <small class="text-muted d-block">Code</small>
            {{ tenant.code }}
          </p>
          <p class="fw-bold">
            <small class="text-muted d-block">Name</small>
            {{ tenant.name }}
          </p>
          <p class="fw-bold">
            <small class="text-muted d-block">Email</small>
            {{ tenant.email }}
          </p>
          <p class="fw-bold">
            <small class="text-muted d-block">Phone</small>
            {{ tenant.phone }}
          </p>
          <p class="fw-bold">
            <small class="text-muted d-block">Website</small>
            <a [href]="tenant.website" target="_blank">{{ tenant.website }}</a>
          </p>
          <p class="fw-bold">
            <small class="text-muted d-block">Address</small>
            {{ tenant.address }}
          </p>
          @if (tenant.country) {
          <p class="fw-bold">
            <small class="text-muted d-block">Country</small>
            {{ tenant.country.name }} {{ tenant.country.flag }}
          </p>
          } @if (tenant.currency) {
          <p class="fw-bold">
            <small class="text-muted d-block">Currency</small>
            {{ tenant.currency.id }} - {{ tenant.currency.name }} ({{
              tenant.currency.symbol
            }})
          </p>
          } @if (tenant.language) {
          <p class="fw-bold">
            <small class="text-muted d-block">Language</small>
            {{ tenant.language.id | uppercase }} {{ tenant.language.name }}
          </p>
          }
          <p class="fw-bold">
            <small class="text-muted d-block">Timezone</small>
            {{ tenant.timezone }}
          </p>
          <p class="fw-bold">
            <small class="text-muted d-block">Created</small>
            {{ tenant.createdAt | date : "medium" }}
          </p>
          <p class="fw-bold">
            <small class="text-muted d-block">Last updated</small>
            {{ tenant.updatedAt | date : "medium" }}
          </p>
        </div>
      </div>
      }
    </div>
  </div>
</div>

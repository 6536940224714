import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPageSearch } from '../models/page-search.interface';
import { CommonModule } from '@angular/common';
import { IPaginatedUser } from '../models/user.interface';
import { IPaginatedRole } from '../models/role.interface';
import { IPaginatedIPeriodicity } from '../models/periodicity.interface';
import { IPaginatedLoanType } from '../models/loan-type.interface';
import { IPaginatedAccount } from '../models/saving-account.interface';
import { IPaginatedCollection } from '../models/collection.interface';
import { IPaginatedLoan } from '../models/loan.interface';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-list-pagination',
  templateUrl: './list-pagination.component.html',
  styleUrl: './list-pagination.component.css',
})
export class ListPaginationComponent {
  @Input()
  paginatatedRecord!:
    | IPaginatedUser
    | IPaginatedRole
    | IPaginatedIPeriodicity
    | IPaginatedLoanType
    | IPaginatedCollection
    | IPaginatedAccount
    | IPaginatedLoan;

  @Input()
  pageSearch!: IPageSearch;

  @Output() turnPage = new EventEmitter<IPageSearch>();
  constructor() {}

  ngOnInit(): void {}

  goToPreviousPage() {
    if (this.paginatatedRecord.first === false) {
      this.pageSearch.page = this.paginatatedRecord.number - 1;
      this.turnPage.emit(this.pageSearch);
    }
  }

  goToNextPage() {
    if (this.paginatatedRecord.last === false) {
      if (this.paginatatedRecord.number !== this.paginatatedRecord.totalPages) {
        this.pageSearch.page = this.paginatatedRecord.number + 1;
        this.turnPage.emit(this.pageSearch);
      }
    }
  }
}

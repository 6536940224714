<nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
  <!-- Navbar Brand-->
  <a
    class="navbar-brand ps-3"
    routerLink="/dashboard"
    routerLinkActive="active"
  >
    @if (tenant) {
    {{ tenant.name }}
    } @else { Sakko }
  </a>
  <!-- Sidebar Toggle-->
  <button
    class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
    id="sidebarToggle"
    href="#!"
  >
    <fa-icon [icon]="faBars"></fa-icon>
  </button>
  <!-- Navbar Search-->

  <!-- Navbar-->
  <ul class="navbar-nav ms-auto me-0 me-md-3 my-2 my-md-0">
    <li class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle"
        id="navbarDropdown"
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        ><fa-icon [icon]="faUser" class="me-1"></fa-icon> Richard Omoka</a
      >
      <ul
        class="dropdown-menu dropdown-menu-end"
        aria-labelledby="navbarDropdown"
      >
        <li>
          <a class="dropdown-item" href="#!"
            ><fa-icon [icon]="faUser" class="me-1"></fa-icon>My profile</a
          >
        </li>
        <li><hr class="dropdown-divider" /></li>
        <li>
          <a class="dropdown-item" href="javascript:void(0)" (click)="logOut()"
            ><fa-icon [icon]="faSignOut" class="me-1"></fa-icon> Log out</a
          >
        </li>
      </ul>
    </li>
  </ul>
</nav>
<div id="layoutSidenav">
  <div id="layoutSidenav_nav">
    <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div class="sb-sidenav-menu">
        <div class="nav">
          <div class="sb-sidenav-menu-heading">Operations</div>
          <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">
            <div class="sb-nav-link-icon">
              <fa-icon [icon]="faTachometerAlt" class="me-1"></fa-icon>
            </div>
            Dashboard
          </a>
          <a class="nav-link" routerLink="/members" routerLinkActive="active">
            <div class="sb-nav-link-icon">
              <fa-icon [icon]="faUsers" class="me-1"></fa-icon>
            </div>
            Members
          </a>
          <a class="nav-link" routerLink="/loans" routerLinkActive="active">
            <div class="sb-nav-link-icon">
              <fa-icon [icon]="faRetweet" class="me-1"></fa-icon>
            </div>
            Loans
          </a>
          <a
            class="nav-link"
            routerLink="/collections"
            routerLinkActive="active"
          >
            <div class="sb-nav-link-icon">
              <fa-icon [icon]="faMoneyBill" class="me-1"></fa-icon>
            </div>
            Collections
          </a>
          <a
            class="nav-link"
            routerLink="/disbursements"
            routerLinkActive="active"
          >
            <div class="sb-nav-link-icon">
              <fa-icon [icon]="faRandom" class="me-1"></fa-icon>
            </div>
            Disbursements
          </a>
          <a class="nav-link" routerLink="/penalties" routerLinkActive="active">
            <div class="sb-nav-link-icon">
              <fa-icon [icon]="faGavel" class="me-1"></fa-icon>
            </div>
            Penalties
          </a>

          <a class="nav-link" routerLink="/reports" routerLinkActive="active">
            <div class="sb-nav-link-icon">
              <fa-icon [icon]="faFileExcel" class="me-1"></fa-icon>
            </div>
            Reporting
          </a>
          <a
            class="nav-link"
            routerLink="/investments"
            routerLinkActive="active"
          >
            <div class="sb-nav-link-icon">
              <fa-icon [icon]="faPiggyBank" class="me-1"></fa-icon>
            </div>
            Investments
          </a>

          <div class="sb-sidenav-menu-heading">Addons</div>
          <a
            class="nav-link"
            routerLink="/communications"
            routerLinkActive="active"
          >
            <div class="sb-nav-link-icon">
              <fa-icon [icon]="faBullhorn" class="me-1"></fa-icon>
            </div>
            Communication
          </a>
          <a class="nav-link" routerLink="/minutes" routerLinkActive="active">
            <div class="sb-nav-link-icon">
              <fa-icon [icon]="faClipboard" class="me-1"></fa-icon>
            </div>
            Meeting Minutes
          </a>

          <a class="nav-link" routerLink="/insurance" routerLinkActive="active">
            <div class="sb-nav-link-icon">
              <fa-icon [icon]="faUmbrella" class="me-1"></fa-icon>
            </div>
            Insurance
          </a>
          <a class="nav-link" routerLink="/crb" routerLinkActive="active">
            <div class="sb-nav-link-icon">
              <fa-icon [icon]="faCertificate" class="me-1"></fa-icon>
            </div>
            CRB Check
          </a>

          <div class="sb-sidenav-menu-heading">Settings</div>
          <a class="nav-link" routerLink="/settings" routerLinkActive="active">
            <div class="sb-nav-link-icon">
              <fa-icon [icon]="faWrench" class="me-1"></fa-icon>
            </div>
            System Settings
          </a>
        </div>
      </div>
      <div class="sb-sidenav-footer text-uppercase">
        @if (tenant) {
        <div class="small text-white">
          {{ tenant.name }}
        </div>
        }
      </div>
    </nav>
  </div>
  <div id="layoutSidenav_content">
    <main>
      <div class="container-fluid p-4">
        <router-outlet></router-outlet>
        <!--   <h1 class="mt-4">Static Navigation</h1>
        <ol class="breadcrumb mb-4">
          <li class="breadcrumb-item"><a href="index.html">Dashboard</a></li>
          <li class="breadcrumb-item active">Static Navigation</li>
        </ol>
        <div class="card mb-4">
          <div class="card-body">
            <p class="mb-0">
              This page is an example of using static navigation. By removing
              the
              <code>.sb-nav-fixed</code>
              class from the
              <code>body</code>
              , the top navigation and side navigation will become static on
              scroll. Scroll down this page to see an example.
            </p>
          </div>
        </div>
        <div style="height: 100vh"></div>
        <div class="card mb-4">
          <div class="card-body">
            When scrolling, the navigation stays at the top of the page. This is
            the end of the static navigation demo.
          </div>
        </div>
      --></div>
    </main>
    <footer class="py-4 bg-light mt-auto">
      <div class="container-fluid px-4">
        <div class="d-flex align-items-center justify-content-between small">
          <div class="text-muted">
            &copy;
            <a href="https://sakko.co.ke/" target="_blank"
              >sakko.co.ke {{ currentYear }}</a
            >
          </div>
          <!--  <div>
            <a href="#">Privacy Policy</a>
            &middot;
            <a href="#">Terms &amp; Conditions</a>
          </div> -->
        </div>
      </div>
    </footer>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { LoaderComponent } from '../../../loader/loader.component';
import { AppService } from '../../../services/app.service';
import { ITenant } from '../../../models/tenant.interface';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { AppEnums } from '../../../models/app.enums';
import { environment } from '../../../../environments/environment.development';
import { HttpEventType } from '@angular/common/http';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    LoaderComponent,
    FontAwesomeModule,
  ],
  selector: 'app-org-view',
  templateUrl: './org-view.component.html',
  styleUrl: './org-view.component.css',
})
export class OrgViewComponent implements OnInit {
  isLoading: boolean = false;
  tenant!: ITenant;
  faCamera = faCamera;
  file?: File;
  constructor(private appService: AppService) {}
  ngOnInit(): void {
    this.loadTenant();
  }

  loadTenant() {
    this.isLoading = true;
    this.appService.getTenant.subscribe((t) => {
      this.tenant = t;
      this.isLoading = false;
    });
  }

  onFileSelected(e: any) {
    let aFile: File = e.target.files[0];
    let formData = new FormData();
    formData.append('file', aFile);
    formData.append('type', AppEnums.UploadTypeLogo);
    formData.append('id', this.tenant.id);
    this.isLoading = true;
    this.appService
      .makeUploadRequest(`${environment.BASE_API_URL}/upload`, formData)
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeInfo,
            '',
            'Logo successfully!'
          );
        },
        next: (res) => {
          if (res.type === HttpEventType.Response) {
            this.loadTenant();
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }
}

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListPaginationComponent } from '../../../list-pagination/list-pagination.component';
import { LoaderComponent } from '../../../loader/loader.component';
import { SearchBoxComponent } from '../../../search-box/search-box.component';
import { faAdd, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { IPageSearch } from '../../../models/page-search.interface';
import { environment } from '../../../../environments/environment.development';
import { AppEnums } from '../../../models/app.enums';
import { AppService } from '../../../services/app.service';
import {
  ILoanType,
  IPaginatedLoanType,
} from '../../../models/loan-type.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    SearchBoxComponent,
    ListPaginationComponent,
    ReactiveFormsModule,
  ],
  selector: 'app-loan-type-list',
  templateUrl: './loan-type-list.component.html',
  styleUrl: './loan-type-list.component.css',
})
export class LoanTypeListComponent implements OnInit {
  isLoading: boolean = false;
  faAdd = faAdd;
  faArrowRight = faArrowRight;
  paginatatedRecord!: IPaginatedLoanType;
  records: ILoanType[] = [];
  pageSearch: IPageSearch = {
    page: 0,
    pageSize: 15,
    searchTerm: '',
  };

  constructor(private appService: AppService, private router: Router) {}
  ngOnInit(): void {
    this.fetchRecords(this.pageSearch);
  }
  view(id: string) {
    this.router.navigate(['/settings/loan-types/details', id]);
  }

  fetchRecords(pageSearch: IPageSearch) {
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.BASE_API_URL}/loan-types/list`,
        pageSearch
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.records = this.paginatatedRecord.content;
        },
        next: (res) => {
          this.paginatatedRecord = res.payload;
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }
  search($evt: any) {
    let searchTerm: string = String($evt);
    if (searchTerm.length > 2) {
      this.pageSearch['searchTerm'] = searchTerm;
      this.fetchRecords(this.pageSearch);
    } else {
      this.pageSearch['searchTerm'] = '';
      this.fetchRecords(this.pageSearch);
    }
  }
}

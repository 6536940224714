<div class="container">
  <h3 class="my-5 text-center">
    Hello {{ firstName }},
    <small class="d-block small text-muted my-3"
      >Enter the <span class="fst-italic text-dark"> Chama </span> code & your
      phone number below</small
    >
  </h3>

  <div class="row">
    <div class="col-md-6 mx-auto">
      <form [formGroup]="registerFormGroup" (submit)="submit()">
        <div class="my-2" *ngIf="isLoading">
          <app-loader></app-loader>
        </div>

        <div class="my-2">
          <label for="code" class="form-label fw-bold"
            >Enter the <span class="fst-italic text-dark"> Chama </span> code
            below
          </label>
          <input
            type="text"
            class="form-control"
            id="code"
            formControlName="code"
            autocomplete="off"
            placeholder="e.g XNRRZPQ3CF"
          />
          <span
            class="d-block text-danger fw-lighter fst-italic"
            *ngIf="
            registerFormControl['code'].touched &&
            registerFormControl['code'].errors?.['required']
          "
          >
            <span class="fst-italic"> Chama </span> code is required
          </span>
          <span
            class="d-block text-danger fw-lighter fst-italic"
            *ngIf="
            registerFormControl['code'].touched &&
            registerFormControl['code'].errors?.['valueInvalid']
          "
          >
            <span class="fst-italic"> Chama </span> code can only have a maximum
            length of 10 characters without spaces
          </span>
        </div>

        <div class="my-2">
          <label for="phone" class="form-label fw-bold"
            >Enter your phone number
          </label>
          <input
            type="tel"
            class="form-control"
            id="phone"
            formControlName="phone"
            autocomplete="off"
            placeholder="e.g +254712345678"
          />
          <span
            class="d-block text-danger fw-lighter fst-italic"
            *ngIf="
            registerFormControl['phone'].touched &&
            registerFormControl['phone'].errors?.['required']
          "
          >
            Phone number is required
          </span>
          <span
            class="d-block text-danger fw-lighter fst-italic"
            *ngIf="
            registerFormControl['phone'].touched &&
            registerFormControl['phone'].errors?.['invalidPhone']
          "
          >
            Phone number is invalid
          </span>
        </div>
        <div class="my-3" *ngIf="!isLoading">
          <a routerLink="/onboard" class="btn btn-light me-2"> Cancel </a>
          <button type="submit" class="btn btn-primary me-2 text-white">
            Join Chama
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

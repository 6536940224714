import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListPaginationComponent } from '../../list-pagination/list-pagination.component';
import { LoaderComponent } from '../../loader/loader.component';
import { SearchBoxComponent } from '../../search-box/search-box.component';
import {
  faArrowRight,
  faCloudDownload,
  faPlusCircle,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import {
  ICollection,
  IPaginatedCollection,
} from '../../models/collection.interface';
import { IPageSearch } from '../../models/page-search.interface';
import { AppService } from '../../services/app.service';
import { environment } from '../../../environments/environment.development';
import { AppEnums } from '../../models/app.enums';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IPaginatedUser, IUser } from '../../models/user.interface';
import {
  IPaginatedAccount,
  IAccount,
} from '../../models/saving-account.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    SearchBoxComponent,
    ReactiveFormsModule,
    ListPaginationComponent,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  selector: 'app-collections-list',
  templateUrl: './collections-list.component.html',
  styleUrl: './collections-list.component.css',
})
export class CollectionsListComponent {
  appService = inject(AppService);
  router = inject(Router);
  faPlusCircle = faPlusCircle;
  faCloudDownload = faCloudDownload;
  faArrowRight = faArrowRight;
  faCalendar = faCalendar;
  isLoading: boolean = false;
  records: ICollection[] = [];

  paginatatedAccount!: IPaginatedAccount;
  accounts: IAccount[] = [];
  paginatatedUser!: IPaginatedUser;
  members: IUser[] = [];
  posters: IUser[] = [];
  paginatatedRecord!: IPaginatedCollection;

  pageSearch: IPageSearch = {
    page: 0,
    pageSize: 15,
    searchTerm: '',
  };
  filterFormGroup: FormGroup;
  paymodes: string[] = [];

  constructor(private formBuilder: UntypedFormBuilder) {
    this.filterFormGroup = this.formBuilder.group({
      startDate: [new Date(), Validators.compose([Validators.required])],
      endDate: [new Date(), Validators.compose([Validators.required])],
      payerId: [''],
      posterId: [''],
      accountId: [''],
      payMode: [''],
    });
  }

  ngOnInit(): void {
    this.fetchRecords(this.pageSearch);
    this.appService.getAllPaymodes.subscribe((modes: string[]) => {
      this.paymodes = modes;
    });

    this.filterFormGroup
      .get('payerId')
      ?.valueChanges.subscribe((val: string) => {
        if (val) {
          if (val.length > 3) {
            this.fetchMembers({
              page: 0,
              pageSize: 15,
              searchTerm: val.trim(),
            });
          }
        }
      });
    this.filterFormGroup
      .get('posterId')
      ?.valueChanges.subscribe((val: string) => {
        if (val) {
          if (val.length > 3) {
            this.fetchMembers({
              page: 0,
              pageSize: 15,
              searchTerm: val.trim(),
            });
          }
        }
      });
    this.filterFormGroup
      .get('accountId')
      ?.valueChanges.subscribe((val: string) => {
        if (val) {
          if (val.length > 3) {
            this.fetchAccounts({
              page: 0,
              pageSize: 15,
              searchTerm: val.trim(),
            });
          }
        }
      });
    this.appService.getAllPaymodes.subscribe((modes: string[]) => {
      this.paymodes = modes;
    });
  }

  download() {
    let data: any = {};
    let startDate: Date = this.filterFormGroup.get('startDate')?.value;
    console.log(startDate);
    data['startDate'] = this.appService.getMySqlDate(startDate);

    let endDate: Date = this.filterFormGroup.get('endDate')?.value;
    data['endDate'] = this.appService.getMySqlDate(endDate);

    let account: IAccount = this.filterFormGroup.get('accountId')?.value;
    if (account) {
      data['accountId'] = account.id;
    }
    let member: IUser = this.filterFormGroup.get('payerId')?.value;
    if (member) {
      data['payerId'] = member.id;
    }

    let poster: IUser = this.filterFormGroup.get('posterId')?.value;
    if (poster) {
      data['posterId'] = poster.id;
    }
    let payMode = this.filterFormGroup.get('payMode')?.value;
    if (payMode) {
      data['payMode'] = String(payMode).trim();
    }

    this.isLoading = true;
    let mimeType = 'application/vnd.ms-excel';
    this.isLoading = true;
    this.appService
      .makeDownloadRequest(
        `${environment.BASE_API_URL}/collections/download`,
        data,
        mimeType
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
        },
        next: (res) => {
          this.appService.downloadReport(res, mimeType, 'Collections_Report_');
          document.getElementById('closeDownloadReportModal')?.click();
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            'Data not found',
            'Change your parameters and try again'
          );
          this.isLoading = false;
        },
      });
  }

  displayMemberFn(member: IUser): string {
    return member && member.id ? `${member.name} (${member.memberNumber})` : '';
  }

  displayAccountFn(account: IAccount): string {
    return account && account.id ? `${account.name} (${account.type})` : '';
  }

  view(id: string) {
    this.router.navigate(['/collections/details', id]);
  }

  fetchRecords(pageSearch: IPageSearch) {
    this.appService.clearErrorBag();
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.BASE_API_URL}/collections/list`,
        pageSearch
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.records = this.paginatatedRecord.content;
        },
        next: (res) => {
          this.paginatatedRecord = res.payload;
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }

  search($evt: any) {
    let searchTerm: string = String($evt);
    if (searchTerm.length > 2) {
      this.pageSearch['searchTerm'] = searchTerm;
      this.fetchRecords(this.pageSearch);
    } else {
      this.pageSearch['searchTerm'] = '';
      this.fetchRecords(this.pageSearch);
    }
  }

  fetchMembers(pageSearch: IPageSearch) {
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/users/list`, pageSearch)
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.members = this.paginatatedUser.content;
          this.posters = this.paginatatedUser.content;
        },
        next: (res) => {
          this.paginatatedUser = res.payload;
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }
  fetchAccounts(pageSearch: IPageSearch) {
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/accounts/list`, pageSearch)
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.accounts = this.paginatatedAccount.content;
        },
        next: (res) => {
          this.paginatatedAccount = res.payload;
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }
}

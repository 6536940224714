import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderComponent } from '../../loader/loader.component';
import { SearchBoxComponent } from '../../search-box/search-box.component';
import {
  IPaginatedIPeriodicity,
  IPeriodicity,
} from '../../models/periodicity.interface';
import {
  faAdd,
  faFileEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { AppService } from '../../services/app.service';
import { AppEnums } from '../../models/app.enums';
import { IPageSearch } from '../../models/page-search.interface';
import { environment } from '../../../environments/environment';
import { ListPaginationComponent } from '../../list-pagination/list-pagination.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    SearchBoxComponent,
    ListPaginationComponent,
    ReactiveFormsModule,
  ],
  selector: 'app-periodicity',
  templateUrl: './periodicity.component.html',
  styleUrl: './periodicity.component.css',
})
export class PeriodicityComponent {
  paginatatedRecord!: IPaginatedIPeriodicity;
  records: IPeriodicity[] = [];
  isLoading: boolean = false;
  faAdd = faAdd;
  faFileEdit = faFileEdit;
  faTrashAlt = faTrashAlt;
  modalTitle: string = 'Create Periodicity';
  formMode: string = 'CREATE';
  periodicityForm: FormGroup;
  selectedPeriodicity!: IPeriodicity;

  pageSearch: IPageSearch = {
    page: 0,
    pageSize: 15,
    searchTerm: '',
  };
  constructor(
    private appService: AppService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.periodicityForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      days: ['', Validators.compose([Validators.required, Validators.min(1)])],
      description: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.fetchRecords(this.pageSearch);
  }
  get periodicityFormControl() {
    return this.periodicityForm.controls;
  }
  fetchRecords(pageSearch: IPageSearch) {
    this.isLoading = true;
    this.appService
      .makePostRequest(
        `${environment.BASE_API_URL}/periodicity/list`,
        pageSearch
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.records = this.paginatatedRecord.content;
        },
        next: (res) => {
          this.paginatatedRecord = res.payload;
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }

  search($evt: any) {
    let searchTerm: string = String($evt);
    if (searchTerm.length > 2) {
      this.pageSearch['searchTerm'] = searchTerm;
      this.fetchRecords(this.pageSearch);
    } else {
      this.pageSearch['searchTerm'] = '';
      this.fetchRecords(this.pageSearch);
    }
  }

  openUpdatePeriodicity(Periodicity: IPeriodicity) {
    this.formMode = 'EDIT';
    this.modalTitle = 'Update Loan Periodicity';
    this.selectedPeriodicity = Periodicity;
    this.periodicityForm.patchValue({
      name: Periodicity.name,
    });
  }

  openDeletePeriodicityDialog(periodicity: IPeriodicity) {
    this.selectedPeriodicity = periodicity;
  }
  closeModal() {
    document.getElementById('periodicity-btn-close')?.click();
    document.getElementById('del-btn-close')?.click();
    this.modalTitle = 'Create Periodicity';
    this.formMode = 'CREATE';
  }

  deleteRecord() {
    this.isLoading = true;
    this.appService
      .makeDeleteRequest(
        `${environment.BASE_API_URL}/periodicity/${this.selectedPeriodicity.id}`
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.closeModal();
        },
        next: (res) => {
          this.fetchRecords(this.pageSearch);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  submit() {
    if (!this.periodicityForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }
    this.isLoading = true;
    let data = {
      name: String(this.periodicityForm.get('name')?.value).trim(),
      days: Number(this.periodicityForm.get('days')?.value),
      description: String(
        this.periodicityForm.get('description')?.value
      ).trim(),
    };
    if (this.formMode === 'CREATE') {
      this.appService
        .makePostRequest(`${environment.BASE_API_URL}/periodicity`, data)
        .subscribe({
          complete: () => {
            this.isLoading = false;
            this.closeModal();
          },
          next: (res) => {
            this.fetchRecords(this.pageSearch);
          },
          error: (err) => {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              '',
              this.appService.processHttpErrors(err)
            );
          },
        });
    }
    if (this.formMode === 'EDIT') {
      this.appService
        .makePutRequest(
          `${environment.BASE_API_URL}/periodicity/${this.selectedPeriodicity.id}`,
          data
        )
        .subscribe({
          complete: () => {
            this.isLoading = false;
            this.closeModal();
          },
          next: (res) => {
            this.fetchRecords(this.pageSearch);
          },
          error: (err) => {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              '',
              this.appService.processHttpErrors(err)
            );
          },
        });
    }
  }
}

<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Loan Periodicity</p>

    <button
      class="btn btn-sm btn-link"
      data-bs-toggle="modal"
      data-bs-target="#addPeriodicityModal"
    >
      <fa-icon [icon]="faAdd" class="me-1"></fa-icon> Create Periodicity
    </button>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  }
  <div class="my-4">
    <app-search-box (searchEvent)="search($event)"></app-search-box>
    @if (records.length > 0) {
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Periodicity</th>
            <th scope="col">Description</th>
            <th scope="col">Days</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr *ngFor="let record of records">
            <td>{{ record.name }}</td>
            <td>{{ record.description }}</td>
            <td>{{ record.days }}</td>
            <td>
              <button
                class="btn btn-sm btn-light m-1 me-3"
                data-bs-toggle="modal"
                data-bs-target="#addPeriodicityModal"
                (click)="openUpdatePeriodicity(record)"
              >
                <fa-icon [icon]="faFileEdit" class="me-1"></fa-icon> Edit
              </button>
              <button
                class="btn btn-sm btn-light m-1 me-3"
                data-bs-toggle="modal"
                data-bs-target="#deletePeriodicityModal"
                (click)="openDeletePeriodicityDialog(record)"
              >
                <fa-icon [icon]="faTrashAlt" class="me-1"></fa-icon> Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <app-list-pagination
        [pageSearch]="pageSearch"
        [paginatatedRecord]="paginatatedRecord"
        (turnPage)="fetchRecords($event)"
      >
      </app-list-pagination>
    </div>
    } @else {
    <div class="alert alert-warning my-3" role="alert">No records found.</div>
    }
  </div>
  <div
    class="modal fade"
    id="deletePeriodicityModal"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="deletePeriodicityModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deletePeriodicityModalLabel">
            Delete Periodicity
          </h5>
          <button
            type="button"
            class="btn-close"
            id="del-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                @if (isLoading) {
                <div class="my-4 d-block">
                  <app-loader></app-loader>
                </div>
                }
              </div>

              @if (selectedPeriodicity) {
              <p>
                Are you sure you want to delete this Loan Periodicity?
                <strong class="fw-bold">{{ selectedPeriodicity.name }}</strong>
              </p>
              }

              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="d-grid gap-2">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      class="btn btn-outline-primary m-1"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-grid gap-2">
                    <button
                      (click)="deleteRecord()"
                      class="btn btn-primary m-1"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="addPeriodicityModal"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="addPeriodicityModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addPeriodicityModalLabel">
            {{ modalTitle }}
          </h5>
          <button
            type="button"
            class="btn-close"
            id="periodicity-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                @if (isLoading) {
                <div class="my-4 d-block">
                  <app-loader></app-loader>
                </div>
                }
              </div>
              <form [formGroup]="periodicityForm" (submit)="submit()">
                <div class="mb-3">
                  <label for="name" class="form-label"
                    >Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="name"
                    id="name"
                    placeholder="e.g. Per Annum (PA)"
                    required
                    autocomplete="off"
                  />
                </div>
                <div class="mb-3">
                  <label for="days" class="form-label"
                    >Duration in Days <span class="text-danger">*</span></label
                  >
                  <input
                    type="number"
                    class="form-control"
                    formControlName="days"
                    id="days"
                    placeholder="e.g. 360"
                    required
                    autocomplete="off"
                  />
                </div>

                <div class="mb-3">
                  <label for="description" class="form-label"
                    >Description <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="description"
                    id="description"
                    placeholder="e.g. Interest accrues once per year."
                    required
                    autocomplete="off"
                  />
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <div class="d-grid gap-2">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        class="btn btn-outline-primary m-1"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-grid gap-2">
                      <button type="submit" class="btn btn-primary m-1">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

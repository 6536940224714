import { Pipe, PipeTransform, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppService } from '../services/app.service';
import { IGroupingTag } from '../models/grouping-tag.interface';

@Pipe({
  name: 'groupTag',
  standalone: true,
})
export class GroupTagPipe implements PipeTransform {
  appService = inject(AppService);
  transform(tagId: unknown): Observable<IGroupingTag> {
    return this.appService.getTags.pipe(
      map((tags) => {
        return tags.filter((t) => {
          return t.id === tagId;
        })[0];
      })
    );
  }
}

import { Component } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  faAdd,
  faFileEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../environments/environment.development';
import { AppEnums } from '../../models/app.enums';
import { IPageSearch } from '../../models/page-search.interface';
import { AppService } from '../../services/app.service';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListPaginationComponent } from '../../list-pagination/list-pagination.component';
import { LoaderComponent } from '../../loader/loader.component';
import { SearchBoxComponent } from '../../search-box/search-box.component';
import {
  IPaginatedAccount,
  IAccount,
} from '../../models/saving-account.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    LoaderComponent,
    SearchBoxComponent,
    ListPaginationComponent,
    ReactiveFormsModule,
  ],
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrl: './accounts.component.css',
})
export class AccountsComponent {
  accountTypes: string[] = [];
  paginatatedRecord!: IPaginatedAccount;
  records: IAccount[] = [];
  selectedAccount!: IAccount;
  isLoading: boolean = false;
  faAdd = faAdd;
  faFileEdit = faFileEdit;
  faTrashAlt = faTrashAlt;
  modalTitle: string = 'Create Account';
  formMode: string = 'CREATE';
  savingAccountForm: FormGroup;

  pageSearch: IPageSearch = {
    page: 0,
    pageSize: 15,
    searchTerm: '',
  };
  constructor(
    private appService: AppService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.savingAccountForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      type: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.fetchRecords(this.pageSearch);
    this.appService.getAccountTypes.subscribe((a) => {
      this.accountTypes = a;
    });
  }
  get savingAccountFormControl() {
    return this.savingAccountForm.controls;
  }
  fetchRecords(pageSearch: IPageSearch) {
    this.isLoading = true;
    this.appService
      .makePostRequest(`${environment.BASE_API_URL}/accounts/list`, pageSearch)
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.records = this.paginatatedRecord.content;
        },
        next: (res) => {
          this.paginatatedRecord = res.payload;
        },
        error: (err) => {
          this.appService.showToastMessage(
            AppEnums.ToastTypeError,
            '',
            this.appService.processHttpErrors(err)
          );
          this.isLoading = false;
        },
      });
  }

  search($evt: any) {
    let searchTerm: string = String($evt);
    if (searchTerm.length > 2) {
      this.pageSearch['searchTerm'] = searchTerm;
      this.fetchRecords(this.pageSearch);
    } else {
      this.pageSearch['searchTerm'] = '';
      this.fetchRecords(this.pageSearch);
    }
  }

  onUpdate(account: IAccount) {
    this.formMode = 'EDIT';
    this.modalTitle = 'Update Account';
    this.selectedAccount = account;
    this.savingAccountForm.patchValue({
      name: account.name,
      type: account.type,
    });
  }

  openDeleteDialog(savingAccount: IAccount) {
    this.selectedAccount = savingAccount;
  }
  closeModal() {
    document.getElementById('savingAccount-btn-close')?.click();
    document.getElementById('del-btn-close')?.click();
    this.modalTitle = 'Create Account';
    this.formMode = 'CREATE';
    this.savingAccountForm.reset({});
  }

  deleteRecord() {
    this.isLoading = true;
    this.appService
      .makeDeleteRequest(
        `${environment.BASE_API_URL}/accounts/${this.selectedAccount.id}`
      )
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.closeModal();
        },
        next: (res) => {
          this.fetchRecords(this.pageSearch);
        },
        error: (err) => {
          this.isLoading = false;
          this.appService.showToastMessage(
            AppEnums.ToastTypeWarning,
            '',
            this.appService.processHttpErrors(err)
          );
        },
      });
  }

  submit() {
    if (!this.savingAccountForm.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }
    this.isLoading = true;
    let data = {
      name: String(this.savingAccountForm.get('name')?.value).trim(),
      type: String(this.savingAccountForm.get('type')?.value).trim(),
    };
    if (this.formMode === 'CREATE') {
      this.appService
        .makePostRequest(`${environment.BASE_API_URL}/accounts`, data)
        .subscribe({
          complete: () => {
            this.isLoading = false;
            this.closeModal();
          },
          next: (res) => {
            this.fetchRecords(this.pageSearch);
          },
          error: (err) => {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              '',
              this.appService.processHttpErrors(err)
            );
          },
        });
    }
    if (this.formMode === 'EDIT') {
      this.appService
        .makePutRequest(
          `${environment.BASE_API_URL}/accounts/${this.selectedAccount.id}`,
          data
        )
        .subscribe({
          complete: () => {
            this.isLoading = false;
            this.closeModal();
          },
          next: (res) => {
            this.fetchRecords(this.pageSearch);
          },
          error: (err) => {
            this.isLoading = false;
            this.appService.showToastMessage(
              AppEnums.ToastTypeWarning,
              '',
              this.appService.processHttpErrors(err)
            );
          },
        });
    }
  }
}

import { Component } from '@angular/core';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  standalone: true,
  imports: [BaseLayoutComponent, LoaderComponent],
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrl: './members.component.css',
})
export class MembersComponent {}

<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Roles & Permissions</p>

    <button
      [routerLink]="['./create']"
      [routerLinkActive]="['active']"
      class="btn btn-sm btn-link"
    >
      <fa-icon [icon]="faUserPlus" class="me-1"></fa-icon> Create Role
    </button>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  }
  <div class="my-4">
    <app-search-box (searchEvent)="search($event)"></app-search-box>
    @if (records.length > 0) {
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Role</th>
            <th scope="col">Role Name</th>
            <th scope="col">Description</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr
            *ngFor="let record of records"
            (click)="view(record.id)"
            class="clickable-row"
          >
            <td>{{ record.name }}</td>
            <td>{{ record.displayName }}</td>
            <td>{{ record.description }}</td>
            <td><fa-icon [icon]="faArrowRight" class="me-1"></fa-icon></td>
          </tr>
        </tbody>
      </table>
      <app-list-pagination
        [pageSearch]="pageSearch"
        [paginatatedRecord]="paginatatedRecord"
        (turnPage)="fetchRecords($event)"
      >
      </app-list-pagination>
    </div>
    } @else {
    <div class="alert alert-warning my-3" role="alert">No records found.</div>
    }
  </div>
</div>

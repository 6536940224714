import { Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { authGuard, loggedInGuard } from './guards/logged-in.guard';
import { DefaultDashboardComponent } from './dashboard/default-dashboard/default-dashboard.component';
import { SettingsComponent } from './settings/settings.component';
import { DefaultSettingsComponent } from './settings/default-settings/default-settings.component';
import { RbacSettingsComponent } from './settings/rbac-settings/rbac-settings.component';
import { RbacListComponent } from './settings/rbac-settings/rbac-list/rbac-list.component';
import { RbacCreateComponent } from './settings/rbac-settings/rbac-create/rbac-create.component';
import { RbacViewComponent } from './settings/rbac-settings/rbac-view/rbac-view.component';
import { RbacUpdateComponent } from './settings/rbac-settings/rbac-update/rbac-update.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { DefaultOnboardingComponent } from './onboarding/default-onboarding/default-onboarding.component';
import { JoinGroupComponent } from './onboarding/join-group/join-group.component';
import { RegisterGroupComponent } from './onboarding/register-group/register-group.component';
import { OrgSettingsComponent } from './settings/org-settings/org-settings.component';
import { OrgViewComponent } from './settings/org-settings/org-view/org-view.component';
import { OrgUpdateComponent } from './settings/org-settings/org-update/org-update.component';
import { SmsSettingsComponent } from './settings/sms-settings/sms-settings.component';
import { SmsSetupComponent } from './settings/sms-settings/sms-setup/sms-setup.component';
import { CommunicationsComponent } from './communications/communications.component';
import { DefaultCommsComponent } from './communications/default-comms/default-comms.component';
import { SendSmsComponent } from './communications/send-sms/send-sms.component';
import { GroupingTagsComponent } from './settings/grouping-tags/grouping-tags.component';
import { MembersComponent } from './members/members.component';
import { MembersListComponent } from './members/members-list/members-list.component';
import { MembersCreateComponent } from './members/members-create/members-create.component';
import { MembersUpdateComponent } from './members/members-update/members-update.component';
import { MembersDetailsComponent } from './members/members-details/members-details.component';
import { AttachmentTypesComponent } from './settings/attachment-types/attachment-types.component';
import { PeriodicityComponent } from './settings/periodicity/periodicity.component';
import { LoanTypeComponent } from './settings/loan-type/loan-type.component';
import { LoanTypeListComponent } from './settings/loan-type/loan-type-list/loan-type-list.component';
import { LoanTypeCreateComponent } from './settings/loan-type/loan-type-create/loan-type-create.component';
import { LoanTypeDetailsComponent } from './settings/loan-type/loan-type-details/loan-type-details.component';
import { LoanTypeUpdateComponent } from './settings/loan-type/loan-type-update/loan-type-update.component';
import { LoansComponent } from './loans/loans.component';
import { LoansListComponent } from './loans/loans-list/loans-list.component';
import { LoansCreateComponent } from './loans/loans-create/loans-create.component';
import { LoansViewComponent } from './loans/loans-view/loans-view.component';
import { LoansUpdateComponent } from './loans/loans-update/loans-update.component';
import { AccountsComponent } from './settings/accounts/accounts.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { CollectionsComponent } from './collections/collections.component';
import { CollectionsListComponent } from './collections/collections-list/collections-list.component';
import { CollectionsCreateComponent } from './collections/collections-create/collections-create.component';
import { CollectionsDetailsComponent } from './collections/collections-details/collections-details.component';
export const routes: Routes = [
  {
    path: 'auth',
    title: 'Auth',
    component: AuthComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'login',
        title: 'Login',
        component: LoginComponent,
      },
      {
        path: 'change-password',
        title: 'Change Password',
        component: ChangePasswordComponent,
      },
      { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
    ],
  },
  {
    path: 'onboard',
    title: 'Onboarding',
    component: OnboardingComponent,
    children: [
      {
        path: '',
        title: 'Onboarding',
        component: DefaultOnboardingComponent,
      },
      {
        path: 'join-group',
        title: 'Join Group',
        component: JoinGroupComponent,
      },
      {
        path: 'register-group',
        title: 'Register Group',
        component: RegisterGroupComponent,
      },
    ],
  },
  {
    path: 'dashboard',
    title: 'Dashboard',
    component: DashboardComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: '',
        title: 'Dashboard',
        component: DefaultDashboardComponent,
      },
    ],
  },
  {
    path: 'members',
    title: 'Members',
    component: MembersComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: '',
        title: 'Members',
        component: MembersListComponent,
      },
      {
        path: 'add',
        title: 'Add Member',
        component: MembersCreateComponent,
      },
      {
        path: 'edit/:id',
        title: 'Update Member Details',
        component: MembersUpdateComponent,
      },
      {
        path: 'member/:id',
        title: 'Member Details',
        component: MembersDetailsComponent,
      },
    ],
  },
  {
    path: 'collections',
    title: 'Collections',
    component: CollectionsComponent,
    children: [
      {
        path: '',
        title: 'Collections',
        component: CollectionsListComponent,
      },
      {
        path: 'add',
        title: 'Register Collection',
        component: CollectionsCreateComponent,
      },
      {
        path: 'details/:id',
        title: 'Collection  details',
        component: CollectionsDetailsComponent,
      },
    ],
  },
  {
    path: 'loans',
    title: 'Loans',
    component: LoansComponent,
    children: [
      {
        path: '',
        title: 'Loans',
        component: LoansListComponent,
      },
      {
        path: 'add',
        title: 'Create Loan',
        component: LoansCreateComponent,
      },
      {
        path: 'details/:id',
        title: 'Loan  details',
        component: LoansViewComponent,
      },
      {
        path: 'update/:id',
        title: 'Update Loan',
        component: LoansUpdateComponent,
      },
    ],
  },
  {
    path: 'communications',
    title: 'Communications',
    component: CommunicationsComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: '',
        title: 'Communications',
        component: DefaultCommsComponent,
      },
      {
        path: 'send-sms',
        title: 'Send SMS',
        component: SendSmsComponent,
      },
    ],
  },
  {
    path: 'settings',
    title: 'Settings',
    component: SettingsComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: '',
        title: 'Settings',
        component: DefaultSettingsComponent,
      },
      {
        path: 'attachments',
        title: 'Attachment Types',
        component: AttachmentTypesComponent,
      },
      {
        path: 'periodicity',
        title: 'Periodicity',
        component: PeriodicityComponent,
      },
      {
        path: 'accounts',
        title: 'accounts',
        component: AccountsComponent,
      },
      {
        path: 'loan-types',
        title: 'Loan Types',
        component: LoanTypeComponent,
        children: [
          {
            path: '',
            title: 'Loan Types',
            component: LoanTypeListComponent,
          },
          {
            path: 'add',
            title: 'Create Loan Type',
            component: LoanTypeCreateComponent,
          },
          {
            path: 'details/:id',
            title: 'Loan Type Details',
            component: LoanTypeDetailsComponent,
          },
          {
            path: 'update/:id',
            title: 'Update Loan Type',
            component: LoanTypeUpdateComponent,
          },
        ],
      },
      {
        path: 'tags',
        title: 'Grouping Tags',
        component: GroupingTagsComponent,
      },
      {
        path: 'sms-settings',
        title: 'SMS Settings',
        component: SmsSettingsComponent,
        children: [
          {
            path: '',
            title: 'SMS Settings',
            component: SmsSetupComponent,
          },
        ],
      },
      {
        path: 'organization-settings',
        title: 'Organization Profile',
        component: OrgSettingsComponent,
        children: [
          {
            path: '',
            title: 'Organization Profile',
            component: OrgViewComponent,
          },
          {
            path: 'update',
            title: 'Update Organization Profile',
            component: OrgUpdateComponent,
          },
        ],
      },
      {
        path: 'rbac-settings',
        title: 'Roles & Permissions',
        component: RbacSettingsComponent,
        children: [
          {
            path: '',
            title: 'Roles',
            component: RbacListComponent,
          },
          {
            path: 'create',
            title: 'Create Roles',
            component: RbacCreateComponent,
          },
          {
            path: 'view/:id',
            title: 'View Role',
            component: RbacViewComponent,
          },
          {
            path: 'update/:id',
            title: 'Update Role',
            component: RbacUpdateComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'page-not-found',
    title: 'Page not found',
    component: PageNotFoundComponent,
  },
  { path: 'home', title: 'Home', component: HomeComponent },
  { path: '', redirectTo: '/auth', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

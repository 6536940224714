import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AppService } from '../../services/app.service';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../../loader/loader.component';
import { AppEnums } from '../../models/app.enums';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterLink, LoaderComponent],
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css',
})
export class ChangePasswordComponent implements OnInit {
  isLoading: boolean = false;
  changePasswordFormGroup: FormGroup;
  mode: string = '';
  lang: string = '';
  oobCode: string = '';
  constructor(
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.changePasswordFormGroup = new FormGroup({
      password: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(60)])
      ),
      cpassword: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(60)])
      ),
    });
  }
  ngOnInit(): void {
    this.activatedRoute.queryParams?.subscribe((params) => {
      this.mode = params['mode'];
      this.lang = params['lang'];
      this.oobCode = params['oobCode'];
    });
  }

  submit() {
    if (!this.changePasswordFormGroup.valid) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Fill all fields correctly'
      );
      return;
    }

    let password = this.changePasswordFormGroup.get('password')?.value;
    let cpassword = this.changePasswordFormGroup.get('cpassword')?.value;
    if (cpassword !== password) {
      this.appService.showToastMessage(
        AppEnums.ToastTypeWarning,
        '',
        'Your passwords must match'
      );
      return;
    }
    this.isLoading = true;

    this.appService
      .changePassword(this.oobCode, password)
      .then((r) => {
        this.appService.showToastMessage(
          AppEnums.ToastTypeSuccess,
          '',
          'Password changed successfully'
        );
        this.router.navigate(['/']);
      })
      .catch((e) => {
        this.appService.showToastMessage(
          AppEnums.ToastTypeWarning,
          '',
          'Failed to change password'
        );
      });
  }

  get registerFormControl() {
    return this.changePasswordFormGroup.controls;
  }
}

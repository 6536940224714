<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <p class="h4">Roles & Permissions</p>

    <div>
      <button
        [routerLink]="['/settings/rbac-settings']"
        [routerLinkActive]="['active']"
        class="btn btn-sm btn-link"
      >
        <fa-icon [icon]="faList" class="me-1"></fa-icon> All Roles
      </button>
      <button
        [routerLink]="['/settings/rbac-settings/update', this.roleId]"
        [routerLinkActive]="['active']"
        class="btn btn-sm btn-link"
      >
        <fa-icon [icon]="faFileEdit" class="me-1"></fa-icon> Edit Role
      </button>
      <button
        class="btn btn-sm btn-link"
        data-bs-toggle="modal"
        data-bs-target="#deleteRole"
      >
        <fa-icon [icon]="faTrash" class="me-1"></fa-icon> Delete Role
      </button>
    </div>
  </div>

  @if (isLoading) {
  <div class="my-4 d-block">
    <app-loader></app-loader>
  </div>
  } @if (role ) {
  <div class="my-4">
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <span class="text-muted">Role Name</span>
        <div class="fw-bold">{{ role.name }}</div>
      </li>
      <li class="list-group-item">
        <span class="text-muted">Display Name</span>
        <div class="fw-bold">{{ role.displayName }}</div>
      </li>
      <li class="list-group-item">
        <span class="text-muted">Description</span>
        <div class="fw-bold">{{ role.description }}</div>
      </li>

      <li class="list-group-item">
        <span class="text-muted">Associated Priviliges</span>
        <div class="fw-bold" *ngFor="let g of role.rolePermissions">
          {{ g }}
        </div>
      </li>
      <li class="list-group-item">
        <small class="text-muted"
          >Created {{ role.createdAt | date : "medium" }} Last Updated
          {{ role.updatedAt | date : "medium" }}</small
        >
      </li>
    </ul>
  </div>

  <div
    class="modal fade"
    id="deleteRole"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="deleteRoleLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteRoleLabel">Delete Role</h5>
          <button
            type="button"
            class="btn-close"
            id="del-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Are you sure you want to delete the role
          <strong>{{ role.displayName }}</strong> ? @if (isLoading) {
          <app-loader></app-loader>
          }
        </div>
        <div class="modal-footer">
          <button
            id="closedeleteRoleModal"
            type="button"
            class="btn btn-sm btn-outline-dark"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-sm btn-outline-danger"
            (click)="deleteRole()"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  }
</div>

import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class InputValidationService {
  patternValidator(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valid = nameRe.test(control.value);
      return valid ? null : { valueInvalid: true };
    };
  }

  phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valid = /(^\+[0-9]{12}$)|(^0[0-9]{9}$)/.test(control.value);
      return valid ? null : { invalidPhone: true };
    };
  }
}

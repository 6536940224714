<div class="row mt-3">
  <div class="alert alert-dark" role="alert">
    <strong>STATUS : </strong>{{ record.status }}
  </div>

  <p class="h5 text-muted d-block fw-bold text-decoration-underline">
    Loan Details
  </p>
  <div class="col-md-3 mt-1 fw-bold mt-2">
    <small class="text-muted d-block">Application Date</small>
    {{ record.dateApplied | date : "mediumDate" }}
  </div>

  <div class="col-md-3 mt-1 fw-bold">
    <small class="text-muted d-block">Reference Number</small>
    {{ record.referenceNumber }}
  </div>

  <div class="col-md-3 mt-1 fw-bold">
    <small class="text-muted d-block">Type</small>
    {{ record.loanType.name }}
  </div>
  <div class="col-md-3 mt-1 fw-bold">
    <small class="text-muted d-block">Period</small>
    {{ record.loanType.term }} {{ record.loanType.termDesc }}
  </div>
  <div class="col-md-3 mt-1 fw-bold">
    <small class="text-muted d-block">Interest Rate</small>
    {{ record.loanType.interestRate | percent }}
    {{ record.loanType.periodicity.name }}
  </div>
  <div class="col-md-3 mt-1 fw-bold">
    <small class="text-muted d-block">Amount</small>
    {{ record.amount | currency : " " }}
  </div>

  <div class="col-md-3 mt-1 fw-bold">
    <small class="text-muted d-block">Interest</small>
    {{ record.interest | currency : " " }}
  </div>
  <div class="col-md-3 mt-1 fw-bold">
    <small class="text-muted d-block">Installments</small>
    {{ record.installments }} of
    {{ record.installmentAmount | currency : " " }} each
  </div>
  <div class="col-md-3 mt-1 fw-bold">
    <small class="text-muted d-block">Balance</small>
    {{ record.balance | currency : " " }}
  </div>

  <div class="col-md-3 mt-1 fw-bold mt-2">
    <small class="text-muted d-block">Next Payment Date</small>
    {{ record.nextPaymentDate | date : "mediumDate" }}
  </div>

  <div class="col-md-3 mt-1 fw-bold">
    <small class="text-muted d-block">Applicant Name</small>
    {{ record.applicant.name }}
  </div>
  <div class="col-md-3 mt-1 fw-bold">
    <small class="text-muted d-block">Applicant Email</small>
    {{ record.applicant.email }}
  </div>
  <div class="col-md-3 mt-1 fw-bold">
    <small class="text-muted d-block">Applicant Phone</small>
    {{ record.applicant.phone }}
  </div>
</div>
